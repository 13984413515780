import { toast } from 'react-toastify';
import { webAppUrl, tokenKey } from '../../constants';
import getTrainers from '../actions/fetchTrainerOverivew';

export default (userManagers, userId, isTrainerOverview,t) => async (dispatch) => {
    let url = `${webAppUrl}/api/Trainer/RemoveTrainers?role=Trainer`;

    let response = await fetch(url, {
        method: `POST`,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        }
        , body: JSON.stringify(userManagers)
    });

    if (response.ok) {
        toast.success(t('messagessuccess.removetrainers'), {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(getTrainers(isTrainerOverview));
    }
    else {
        toast.success(`${t('messagesfailed.failedremove')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
