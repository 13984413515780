import { fromJS } from 'immutable';
import '../../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component, useState } from 'react';
import fetchPlayerDetails from '../../../store/Players/PlayerDetails/fetchPlayerDetails';
import fetchPlayerDetailsByIdBySeason from '../../../store/Players/PlayerDetails/fetchPlayerDetailsByIdBySeason';
import { bindActionCreators } from 'redux';
import TextInputField from '../../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';
import { routingLocationSelector } from '../../../store/general/selectors/generalSelector';
import ProfilePic from '../../SharedComponents/ProfileThumImage';
import { webAppUrl } from '../../../store/constants';
import { formatDateOverviewPicker } from '../../../helpers/dateHelper';
import { toast } from 'react-toastify';
import uploadPicture from '../../../store/profilePicture/uploadPicture';
import { MdChevronLeft, MdChevronRight, MdExpandMore, MdExpandLess } from "react-icons/md";
import './DetailPlayer.css';
import ReportPlayerItem from './ReportPlayerItem';
import updatePlayerData from '../../../store/Manager/actions/updateManagerData';
import getPositions from '../../../store/MasterData/getPositions';
import DateInputField from '../../SharedComponents/DateInputField';
import "react-datepicker/dist/react-datepicker.css";
import clearImage from '../../../store/profilePicture/clearImage';
import defaultUser from '../../images/default_user.png';

import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import getSeason from '../../../store/MasterData/getSeason';

class DetailPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            fields: fromJS({
                id: '',
                firstname: '',
                lastname: '',
                nationality: '',
                birthplace: '',
                birthdate: '',
                email: '',
                telephone: '',
                address: '',
                role: '',
                datestarted: '',
                lastlogin: '',
                imgTemp: null,
                photoDate: '',
                photo: null,
                clubname: '',
                teamname: '',
                position: '',
                positionName: '',
                averageScore: '',
                completionPercentage: '',
                clubPictureUrl: '',
                completedReport: 0,
                openReport: 0,
                sharedData: false,
            }),
            errors: fromJS({}),
            componentUpdated: false,
            displayed: false,
            showdetailoverall: false,
            userId: '',
            
            seasonDropDownIsOpen: false,
            filteredSeason: '',
            isinitload: true,
            initfilteredSeason: true,
        };
        this.goBack = this.goBack.bind(this);
        this.print = this.print.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.setInvisible = this.setInvisible.bind(this);
        this.setAlert = this.setAlert.bind(this);
        this.clear = this.clear.bind(this);
        this.handleOnSelectSeason = this.handleOnSelectSeason.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
    }

    hideLoading() {
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "hidden";
    }
    
    toggleItem = () => {
        const { seasonDropDownIsOpen } = this.state;

        this.setState({
            seasonDropDownIsOpen: !seasonDropDownIsOpen
        });
    }
    
    handleOnSelectSeason = (e) => {        
        const { userId } = this.state;
        this.props.fetchPlayerDetailsByIdBySeason(userId, e.currentTarget.id)
            .then(t => this.hideLoading()); 
        
        this.setState({
            filteredSeason: e.target.outerText
        });        
    }
    

    toggle = (e) => {

        const { showdetailoverall } = this.state;
        this.setState({
            showdetailoverall: !showdetailoverall,
        });
        e.preventDefault();
    }

    handleChange(field, value) {
        const { fields } = this.state;
        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    handleDropdownChange(field, event) {

        this.setState({
            fields: this.state.fields.set(field, event.target.value),
            errors: this.state.errors.delete(field),
        });
    }

    componentDidMount() {
        const { routingLocation } = this.props;
        
        this.props.getPositions();
        if (routingLocation && routingLocation.query) {
            const playerId = routingLocation.query['userId']
            if (playerId) {
                this.setState({ userId: playerId });                
                this.props.fetchPlayerDetails(playerId),
                this.props.getSeason() 
                    .then(t => this.hideLoading());                
            }
        }       
        
        this.setState({ isRendering: true }, () => {
            this.setState({ isRendering: false });
        });
        
    }

    componentDidUpdate(prevProps) {
        const { playerData, profilePictureUpload, updatedManager } = this.props;

        const { seasons} = this.props;
        const { initfilteredSeason} = this.state;                
        var currentSeason = '';        
        if (seasons && seasons.length > 0 && initfilteredSeason) {
            
            seasons.map((x) => {
                if (x.isCurrent)
                    currentSeason = x.seasonName;
            });
            this.setState({
                filteredSeason: currentSeason,
                initfilteredSeason: false,
            });
        }


        if (updatedManager && updatedManager != prevProps.updatedManager) {
            this.props.getHeaderDetail()
                .then(t => this.hideLoading());
        }

        if (profilePictureUpload && profilePictureUpload !== prevProps.profilePictureUpload) {
            this.saveUploadedPhoto(profilePictureUpload);
        }

        if (playerData && prevProps.playerData != playerData) {
            this.setState({
                fields: fromJS({
                    firstname: playerData.firstName,
                    lastname: playerData.lastName,
                    nationalityid: playerData.nationalityId,
                    nationality: playerData.nationality,
                    birthplace: playerData.birthPlace,
                    birthdate: playerData.birthDate,
                    email: playerData.email,
                    telephone: playerData.phoneNumber,
                    address: playerData.address,
                    role: playerData.role,
                    datestarted: playerData.dateStarted,
                    lastlogin: playerData.lastLogin,
                    clubname: playerData.clubName,
                    teamname: playerData.teamName,
                    position: playerData.position,
                    positionName: playerData.positionName,
                    userpicture: playerData.userPicture,
                    averageScore: playerData.averageScore,
                    completionPercentage: playerData.completionPercentage,
                    clubPictureUrl: playerData.clubPictureUrl,
                    completedReport: playerData.completedReport,
                    openReport: playerData.openReport,
                    sharedData: playerData.sharedData                    
                }),
                componentUpdated: false,
            })
        }
    }

    saveUploadedPhoto(e) {
        this.setState({
            fields: this.state.fields.set('photo', e.id),
            errors: this.state.errors.delete('photo')
        });
    }

    goBack(e) {
        this.props.history.goBack();
        e.preventDefault();
    }
    
    print(e) {
        if (typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        if (this.state.fields.get('birthdate') instanceof Date) {
            toast.error("Please update unsaved birthdate before printing", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            window.print();    
        }   
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { userId } = this.state;
        const { t, uploadPicture } = this.props;
        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '6000000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('imgTemp', URL.createObjectURL(files[0]));

        newFields = newFields.set('photoDate', dateTag);

        this.setState({
            fields: newFields
        });

        uploadPicture(formData, dateTag, userId, t)
            .then(t => this.hideLoading());
    }

    clear() {
        const i = document.querySelector('.upload-detail');
        const { t, clearImage, fetchPlayerDetails } = this.props;
        const { userId } = this.state;

        clearImage(userId, t)
            .then(t => {
                i.value = '';
                fetchPlayerDetails(userId);
                this.hideLoading();
            });
    }

    handleUpdate() {
        const { fields } = this.state;

        const { routingLocation } = this.props;

        const entity = fields.toJS();

        entity.id = routingLocation.query['userId'];

        //validation

        const simpleEmailRegexp = new RegExp('[^@]+@[^\.]+\..+');

        const hasValidEmail = simpleEmailRegexp.test(fields.get('email'));
        if (!hasValidEmail) {
            toast.success("invalid email format", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        //end of validation

        this.props.updatePlayerData(entity)
            .then(t => this.hideLoading());
    }

    setInvisible(e) {
        const { displayed } = this.state;

        this.setState({ displayed: !displayed });
    }

    setAlert(e) {
        alert('throw');
    }

    handleDateChange = (field, d) => {

        this.setState({
            fields: this.state.fields.set(field, d)
        });
    }

    
    render() {
        const { seasonDropDownIsOpen, filteredSeason } = this.state;        
        const { seasons} = this.props;
        const { playerData, allPositions, t } = this.props;
        const { fields, showdetailoverall } = this.state;
        const hasPicture = fields.get('imgTemp') == null || fields.get('imgTemp') === undefined ? false : true;
        const skillsOverview = playerData && playerData.playerReportDetailsSkill ? playerData.playerReportDetailsSkill : fromJS({});
        const playerReportResponse = playerData && playerData.playerReportResponse ? playerData.playerReportResponse : fromJS({});        
        const sharedData = fields.get('sharedData');
        let pos = '';
        pos = allPositions.map((x, i) => (<option key={i} value={x.id}> {x.position} </option>));
        return (
            <div>
                <div className="backto">
                    <a href="#" onClick={this.goBack}><MdChevronLeft className="backbtn" /></a>
                </div>                
                
                <div className="shadow_container">                    
                    <div className="details_top">
                        <div className="remove_details_pg">
                            <a href="#" className="btn_remove btn_print" onClick={this.print} >  <img alt="Print report" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjQgNy4xMTExMUgzLjZDMS42MDggNy4xMTExMSAwIDguNzQ4ODkgMCAxMC43Nzc4VjE1LjY2NjdDMCAxNy4wMTExIDEuMDggMTguMTExMSAyLjQgMTguMTExMUg0LjhWMjAuNTU1NkM0LjggMjEuOSA1Ljg4IDIzIDcuMiAyM0gxNi44QzE4LjEyIDIzIDE5LjIgMjEuOSAxOS4yIDIwLjU1NTZWMTguMTExMUgyMS42QzIyLjkyIDE4LjExMTEgMjQgMTcuMDExMSAyNCAxNS42NjY3VjEwLjc3NzhDMjQgOC43NDg4OSAyMi4zOTIgNy4xMTExMSAyMC40IDcuMTExMTFaTTE1LjYgMjAuNTU1Nkg4LjRDNy43NCAyMC41NTU2IDcuMiAyMC4wMDU2IDcuMiAxOS4zMzMzVjE0LjQ0NDRIMTYuOFYxOS4zMzMzQzE2LjggMjAuMDA1NiAxNi4yNiAyMC41NTU2IDE1LjYgMjAuNTU1NlpNMjAuNCAxMkMxOS43NCAxMiAxOS4yIDExLjQ1IDE5LjIgMTAuNzc3OEMxOS4yIDEwLjEwNTYgMTkuNzQgOS41NTU1NiAyMC40IDkuNTU1NTZDMjEuMDYgOS41NTU1NiAyMS42IDEwLjEwNTYgMjEuNiAxMC43Nzc4QzIxLjYgMTEuNDUgMjEuMDYgMTIgMjAuNCAxMlpNMTggMUg2QzUuMzQgMSA0LjggMS41NSA0LjggMi4yMjIyMlY0LjY2NjY3QzQuOCA1LjMzODg5IDUuMzQgNS44ODg4OSA2IDUuODg4ODlIMThDMTguNjYgNS44ODg4OSAxOS4yIDUuMzM4ODkgMTkuMiA0LjY2NjY3VjIuMjIyMjJDMTkuMiAxLjU1IDE4LjY2IDEgMTggMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=" /></a>
                            <Dropdown isOpen={seasonDropDownIsOpen} toggle={this.toggleItem} className="dropdown-caret season-btn season-btn-right">
                                <DropdownToggle caret>
                                    {filteredSeason}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {seasons.map((row, index) => {
                                        //let current = '';
                                        //current = row.isCurrent ? (<img src={checklistCompleted} style={{ marginLeft: '10px' }} class="report-skill-small-thumb" />) : '';
                                        return (
                                            <DropdownItem>
                                                <div className="season-inline" id={row.id} onClick={this.handleOnSelectSeason}>
                                                    <span>{row.seasonName}</span>
                                                </div>
                                            </DropdownItem>
                                        )
                                    })}

                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        
                        <div className="add_pic_section">
                            <img src={fields.get('userpicture') ? fields.get('userpicture') : defaultUser} alt="" className="img-user_detail-print" />
                            <div className="upload_btn">
                                <input type="file" onChange={this.uploadFileChange} className="upload-detail" />
                                {hasPicture && <img src={fields.get('imgTemp')} alt="" className="img-user-detail rounded-circle" />}
                                {!hasPicture && <ProfilePic cls='img-user-detail' imgUrl={fields.get('userpicture') ?? null} />}
                                <img src={`${webAppUrl}/hover_change_picture.png`} alt="" className="rounded-circle img-user-detail-hover" />
                                <img src={`${webAppUrl}/close_round.png`} alt="" className="remove-image" onClick={this.clear} />
                            </div>
                        </div>
                        <div className="details_box">
                            <div className="details_name">{fields.get('firstname') + ' ' + fields.get('lastname')} </div>
                            
                            <div className="details_bottom">
                                <div className="row">
                                    <div className="col-md-5">
                                        <table className="trains_detab">
                                            <tbody>
                                                <tr className="birthdate">
                                                    <td>{t('generaldetail.birthdate')}</td>
                                                    <td className="dt_gap">:</td>                                                                                                                                                            
                                                    <td className="right_cntb">{fields.get('birthdate') && !(fields.get('birthdate') instanceof Date) ? fields.get('birthdate').split('T')[0] : ' '}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t('general.open') + ' ' + t('general.lowerreports')}</td>
                                                    <td className="dt_gap">:</td>
                                                    <td className="right_cntb">{fields.get('openReport')}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t('overview.completed') + ' ' + t('general.lowerreports')}</td>
                                                    <td className="dt_gap">:</td>
                                                    <td className="right_cntb">{fields.get('completedReport')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="bottom_details_holder">
                    <div className="row">
                        { /* left page */}
                        <div className="col-lg-6">
                            <div className="container-left">
                                <table className="details_table_left">
                                    <tbody>
                                        <tr>
                                            <td>{t('teams.singletitle')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField
                                                    disabled={true} field={'positionName'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('teamname')}
                                                    extraClassName={'disabled-field-bg'}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('general.role')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField
                                                    disabled={true} field={'positionName'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('role')}
                                                    extraClassName={'disabled-field-bg'}
                                                />
                                            </td>
                                        </tr>
                                            <tr>
                                            <td>{t('general.firstname')}</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={false} field={'firstname'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('firstname')} /></td>
                                        </tr>
                                        <tr>
                                            <td>{t('general.lastname')}</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={false} field={'lastname'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('lastname')} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Nationality</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={true} field={'nationality'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('nationality')} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Birth place</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={false} field={'birthplace'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('birthplace')} /></td>
                                        </tr>
                                        <tr className="birthdate">
                                            <td>{t('generaldetail.birthdate')}</td>
                                            <td className="dt_gap"></td>

                                            <td>
                                                <DateInputField
                                                    field="birthdate"
                                                    value={formatDateOverviewPicker(fields.get('birthdate')) ? new Date(formatDateOverviewPicker(fields.get('birthdate'))) : ''}

                                                    onChange={(field, date) => this.handleDateChange(field, new Date(date))}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('general.e-mail')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField disabled={true} field={'email'} onChange={(field, value) => this.handleChange(field, value)} 
                                                    value={fields.get('email')}
                                                    extraClassName={'disabled-field-bg'}
                                                />
                                            </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Telephone</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={false} field={'telephone'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('telephone')} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Address</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={true} field={'address'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('address')} /></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                {sharedData ?
                                                    <div className="checkbox">
                                                        <input id="sharing_data" type="checkbox" checked={true} disabled="true" />
                                                        <label for="sharing_data" />
                                                        <div className="vertical-center left50" style={{ fontFamily: "Avenir LT Std" }}>
                                                            Share my data for scouting purposes
                                                    </div>
                                                    </div> :
                                                    <div className="checkbox">
                                                        <input id="sharing_data" type="checkbox" checked={false} disabled="true" />
                                                        <label for="sharing_data" />
                                                        <div className="vertical-center left50" style={{ fontFamily: "Avenir LT Std" }}>
                                                            Share my data for scouting purposes
                                                        </div>
                                                    </div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div style={{margin:"auto",width:"140px"}}>
                                                    <a className="btn_update" href="#" onClick={(e) => { e.preventDefault(); this.handleUpdate(); }} style={{float:"none"}}>{t('general.update')}</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        { /* right page */}
                        <div className="col-md-6">
                            { /* overall section */}
                            <div className="container-right">
                                <div className="overall_section">
                                    <a href="#" id="bn_overall" onClick={this.toggle}>
                                        <div className="datatable_top_left">{t('overview.completed') + ' ' + t('general.lowerreports')}
                                            <div className="badge badge-danger badge-danger-extend">{fields.get('completedReport')}</div>
                                        </div>
                                        <div className="datatable_top noborder">
                                            <div className="datatable_top_left">{t('generaldetail.overallscore')}</div>
                                            <div className="over_score">{fields.get('averageScore') && fields.get('averageScore') != "0" ? parseFloat(fields.get('averageScore')).toFixed(1) : ' '}</div>
                                            <div className="skill-expand-arrow">{showdetailoverall ? <div><MdExpandMore className="backbtn" /></div> : <MdChevronRight className="backbtn" />}</div>
                                        </div>
                                    </a>
                                    <div className={showdetailoverall ? '' : 'overall_toggle'} style={{ marginRight: "10px" }}>
                                        <div className="overall_progress" style={{ paddingRight: "10px" }}>
                                            <div className="over_t">{t('general.progress')}</div>
                                            <div className="over_pro">
                                                <div className="progress_bar">
                                                    <div className="progress">
                                                        <div className="progress-bar"
                                                            role="progressbar"
                                                            style={{ width: fields.get('completionPercentage') + '%' }}
                                                            aria-valuenow="25"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100">
                                                            <span>{fields.get('completionPercentage') + '%'}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scor_ocver">
                                            <div className="row justify-content-between">
                                                {skillsOverview &&
                                                    skillsOverview.map((data) => {
                                                        return (
                                                            <div className="col-lg-5">
                                                                <div className="accro_de">
                                                                    <div className="rat_name">{data.skillName}</div>
                                                                    <div className="rat_number"><span>{data.score}</span></div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReportPlayerItem playerReportResponse={playerReportResponse} />
                                {/*<HistoryReportPlayerItem playerReportResponse={playerReportResponse} />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => {
    return {
        playerData: state.player.player,
        routingLocation: routingLocationSelector(state),
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        updatedManager: state.manager.updatedManager,
        seasons: state.masterData.seasons,
        allPositions: state.masterData.positions,
    }
},
    dispatch => bindActionCreators({
        fetchPlayerDetails,
        fetchPlayerDetailsByIdBySeason,
        uploadPicture,
        clearImage,
        updatePlayerData,
        getPositions,
        getSeason,
    }, dispatch))(withTranslation()(DetailPlayer));