import { toast } from 'react-toastify';
import { authUrl } from '../../constants';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import getInvitedPlayers from '../InvitedPlayers/getInvitedPlayers';

export default (userId, t) => async (dispatch) => {

    const body = JSON.stringify({
        "id": userId,
        "role": "Player"
    });

    const result = await fetchWithBearerToken(`${authUrl}/Account/ReInviteUser`, 'POST', body);
    if (result.ok) {
        toast.success(t('messagessuccess.reinvitedplayer'), {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(getInvitedPlayers(false));
    }
    else {
        toast.error(`t('messagesfailed.reinvitedplayer') : ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};