import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { routingLocationSelector } from '../../store/general/selectors/generalSelector';
import { loggedInUserSelector } from '../../store/users/selectors/authSelector';
import NavMenuItem from './NavMenuItem/NavMenuItem';
import { Map } from 'immutable';
import EmptyTeamsImage from '../images/Empty_logo_Big.png';
import { fromJS } from 'immutable';

class SideNavMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: fromJS({
                clubid: "",
                clubname: "",
                country: "",
                logourl: "",
                logotemp: "",
                clubSeasons: fromJS([]),
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { clubDetails } = this.props;
        if (clubDetails && prevProps.clubDetails !== clubDetails) {
            this.setState({
                fields: fromJS({
                    clubid: clubDetails.id,
                    clubname: clubDetails.clubName,
                    country: clubDetails.country,
                    logourl: clubDetails.logoUrl,
                    clubSeasons: clubDetails.clubSeasons,
                })
            });
            sessionStorage.setItem('clubImage', clubDetails.logoUrl === '' ? EmptyTeamsImage : clubDetails.logoUrl);
        }
    }

    render() {
        const {
            routingLocation,
            urls = Map(),
            isOpen,
            toggleOpen,
            loggedInUser,
            clubDetails
        } = this.props;
        const { fields } = this.state;
        const roles = loggedInUser ? loggedInUser.role : ['GUEST'];
        
        const allowedUrls = (urlsToFilter) => {
            return urlsToFilter.filter(url => {
                if (!url.get('allowedRoles')) {
                    return true;
                } else {
                    const roleAllowed =
                        !url.get('allowedRoles')
                        || url.get('allowedRoles').some(role => role.includes(role));
                    
                    return roleAllowed;
                }
            });
        };
        const filteredUrls = allowedUrls(urls);

        return (
            <div className="dashboard_left" id="mySidenav" style={{ width: isOpen ? '220px' : '0px' }} onClick={this.props.toogleProfileBoxOutside}>
                <a href="#" className="closebtn" onClick={(e) => { e.preventDefault(); toggleOpen(false) }}>&#xD7;</a>
                <div className="team_logo">
                    <a href="/" style={{ color: "white" }}>
                        <img src={sessionStorage.getItem('clubImage')} />
                        {fields.get('clubname')}
                    </a>
                </div>
                <div className="sidenav">
                    <nav>
                        <ul>
                            {isOpen && filteredUrls.reduce((navItems, navItem) => {
                                return navItems.push(
                                    <NavMenuItem
                                        key={`navmenuItems-${navItem.get('to')}`}
                                        navItem={navItem}
                                        pathname={routingLocation.pathname}
                                    />
                                );
                            }, List())}
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            routingLocation: routingLocationSelector(state),
            loggedInUser: loggedInUserSelector(state),
            clubDetails: state.users.clubDetails
        }
    },
    null,
)(SideNavMenu);