import { toast } from 'react-toastify';
import { tokenKey, updateLoggedInUser, userId, userRole, userEmail, authUrl, updatePasswordToken } from '../../constants';
import { encryptPassword } from '../../../helpers/encryptionHelper';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default (passwordData) => async (dispatch) =>{

    let url = `${authUrl}/Account/`;//'SetupResetPassword ;
    if (passwordData.reset) url = `${url}SetupResetPassword`;
    if (passwordData.activate) url = `${url}SetupInitialPassword`;

    var reqBody = {
        'password': passwordData.password,
        'token':passwordData.token
    }

    const response = await fetchWithBearerToken(url, 'POST', JSON.stringify(reqBody));

    if (response.ok) {
        var result = await response.json();

        toast.success("Setup Password Succeeded", {
            position: toast.POSITION.TOP_RIGHT
        });

        if (passwordData.activate && !result.item2.includes("Manager"))
            window.location.href = "/congratulation";

        if (passwordData.reset && !result.item2.includes("Manager"))
            window.location.href = "/passwordchanged";

        dispatch({
            type: updatePasswordToken,
            resetPassword: true
        });
    }
    else {
        toast.error(`setup password failed.${result.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}