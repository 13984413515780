import { getClubDetails, webAppUrl, } from '../../constants';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default (userid) => async (dispatch) => {
    const url = `${webAppUrl}/api/user/GetClubDetails`;

    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const result = await response.json();
        dispatch({ type: getClubDetails, clubDetails: result });
    }
    else
        dispatch({ type: getClubDetails, clubDetails: null });
};