import { uploadProfilePicture, tokenKey, authUrl } from '../constants';
import { toast } from 'react-toastify';


export default (userId, t) => async (dispatch) => {
    var url = `${authUrl}/Account/ClearPhoto?userId=${userId}`;

    const tokenVar = sessionStorage.getItem(tokenKey);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + tokenVar
        }
    }).catch(error => { toast.error(error); });

    if (response.ok) {
        const resultContent = await response.json();

        //if (resultContent == null) {
        //    toast.error(`${t('messagesfailed.uploadpic')} ${response.errorMessage}`, {
        //        position: toast.POSITION.TOP_RIGHT
        //    });
        //    return;
        //}

        //toast.success(t('messagessuccess.uploadpic'), {
        //    position: toast.POSITION.TOP_RIGHT
        //});

        dispatch({ type: uploadProfilePicture, userProfilePicture: resultContent })
    }
};