import { toast } from 'react-toastify';
import { webAppUrl, tokenKey, addPlayerTrainers } from '../../constants';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import fetchPlayerOverview from '../../../store/TeamDetail/Action/fetchPlayerOverview';
import fetchTrainerOverview from '../../../store/TeamDetail/Action/fetchTrainerOverview';

export default (teamPlayers, teamId, type, t) => async (dispatch) => {

    let url = `${webAppUrl}/api/Team/${type == 'player' ? 'AddPlayerToTeam' : 'AddTrainerToTeam'}`;

    const requestBody = JSON.stringify({ user: teamPlayers, newTeamId: teamId });

    const response = await fetchWithBearerToken(url, 'POST', requestBody);

    if (response.ok) {
        toast.success(t('messagessuccess.addresult'), {
            position: toast.POSITION.TOP_RIGHT
        });

        dispatch(fetchPlayerOverview(teamId));
        dispatch(fetchTrainerOverview(teamId));
    }
    else {
        toast.error(`${t('messagesfailed.addresult')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
