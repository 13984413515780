import React from 'react';
import trashBig from '../images/Trash_Big.png';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class ModalDelete extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { modal, closeModal, okRemove, toggle, t } = this.props;
        return (<Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
            toggle={() => { toggle(false) }} className="trainermodal">
            <div className="modal-body text-center">
                <div className="trash_icon">
                    <img src={trashBig} alt="" />
                </div>
                <h3>{t('general.deleteconfirmation')}</h3>
                <p>{t('general.deleteconfirmationdesc')}</p>
                <div className="delete_btn_holder">
                    <a href="#" onClick={(e) => { e.preventDefault(); closeModal(false); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                    <a href="#" onClick={(e) => { e.preventDefault(); okRemove(); }} className="btn_remove_modal" data-dismiss="modal" aria-label="Close">{t('general.remove')}</a>
                </div>
            </div>
        </Modal>);
    }
}
export default withTranslation()(ModalDelete);