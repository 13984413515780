import { addPlayer, getPlayerOverview, getPlayerOverviewReport, clearAddPlayerData, fetchPlayerDetails } from '../constants';

const initialState = {
    addPlayerResult: null,
    playerOverview: null
}
export default (state, action) => {
    state = state || initialState;
    if (action.type === addPlayer) {
        return { ...state, addPlayerResult: action.result };
    }

    if (action.type === getPlayerOverview) {
        return { ...state, playerOverview: action.players };
    }

    if (action.type === getPlayerOverviewReport) {
        return { ...state, playerOverview: action.players };
    }

    if (action.type === clearAddPlayerData) {
        return { ...state, addPlayerResult: undefined };
    }

    if (action.type === fetchPlayerDetails) {
        return { ...state, player: action.player };
    }
    return state;
}