import { webAppUrl, getPlayerDevelopmentDashboard } from '../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (seasonId, teamIds) => async (dispatch) => {
    //player development
    
    let body ='';
    
    if (teamIds && teamIds.length > 0) {        
        body = JSON.stringify(teamIds.map(x => x.value).join(','));
    }
    const playerDevelopmentResult = await fetchWithBearerToken(`${webAppUrl}/api/Dashboard/GetPlayerDevelopment?clubSeasonId=${seasonId}`
    , 'POST',body);

    if (playerDevelopmentResult.ok) {
        const playerResult = await playerDevelopmentResult.json();        
        dispatch({ type: getPlayerDevelopmentDashboard, result: playerResult });
    }
}