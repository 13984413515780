import { webAppUrl, addSeason } from '../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (clubSeasons) => async (dispatch) => {
    var data = [];
    clubSeasons.forEach(function (row) {
        var rowdata = {            
            "Id": row.id,
            "SeasonName": row.seasonName,
            "ReportFrequency": parseInt(row.reportFrequency),
        };
        data.push(rowdata);
    });    
    
    const result = await fetchWithBearerToken(`${webAppUrl}/api/MasterData/UpdateSeasons`, 'POST', JSON.stringify(data));

    if (result.ok) {
        var response = await result.json();
        toast.success(`Seasons updated succesfully.`, {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({ type: addSeason, result: new Date().toString() });
    }
    else {
        var response = await result.json();
        toast.error(`Failed update seasons. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });

    }
};