import { toast } from 'react-toastify';
import { webAppUrl, tokenKey } from '../../constants';
import getManagers from '../actions/fetchManagerOverview';

export default (userManagers,userId,isManagerOverview,t) => async (dispatch) => {
    let url = `${webAppUrl}/api/Manager/RemoveManagers?role=Manager`;

    let response = await fetch(url, {
        method: `POST`,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        }
        , body: JSON.stringify(userManagers)
    });
    
    if (response.ok) {
        toast.success(t('messagessuccess.successdeletemanager'), {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(getManagers(isManagerOverview));
    }
    else {
        toast.success(`${t('messagesfailed.failedremove')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
