import { tokenKey } from '../constants';
import { toast } from 'react-toastify';

export default async (url, method = 'GET', body = {}) => {
    const token = sessionStorage.getItem(tokenKey);
    const loading = document.querySelector('.loading');
    if (loading)
        loading.style.visibility = "visible";

    let fetchArguments = {
        method: `${method}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };

    if (method === 'POST' || method === 'PUT') {
        fetchArguments.body = body;
    }

    const result = await fetch(url, fetchArguments);

    //if (!result.ok) {
    //    var doc = await result.json();
    //    if (doc) {
    //        doc.map(x=>console.log(x.errorMessage))
    //    }
    //}

    return result;
};