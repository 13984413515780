import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { webAppUrl, getManagerOverview } from '../../constants';

export default (isActive) => async (dispatch) => {
    const url = `${webAppUrl}/api/manager/GetUserManagerOverviewByClubId?isactive=${isActive}`;

    const response = await fetchWithBearerToken(url);

    if (response.ok) {      
        const managers = await response.json();
        dispatch({
            type: getManagerOverview,
            manager: managers
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
