import React, { Component } from 'react';
import './Congratulation.css';
import LoginLogo from '../images/logo.svg';

class PasswordChanged extends Component {
    render() {
        return (
            <div className="login_container">
                <div className="login_left login_background_image">
                    <img src={LoginLogo} />
                </div>
                <div className="login_right">
                    <div className="login_box">
                        <h1>Je wachtwoord is aangepast!</h1>
                        <p>Open de Pro Season app op je mobiel om in te loggen.</p>
                    </div>
                </div>
                <div style={{ backgroundColor: "#000000", padding: "20px 0", textAlign: "center", bottom: "0", position: "fixed", width: "100%" }}>
                    <a style={{ fontSize: "14px", color: "lightgrey" }}>© 2021 Pro Season b.v All Rights Reserved</a>
                </div>
            </div>);
    }
}

export default PasswordChanged;