import { fromJS } from 'immutable';
import '../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import MaterialTable from './MaterialTable';
import { Modal } from 'reactstrap';
import imageUpload from '../images/upload.jpg';
import { bindActionCreators } from 'redux';
import TextInputField from '../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';
import getTeams from '../../store/MasterData/getTeams';
import getSeason from '../../store/MasterData/getSeason';
import addPlayer from '../../store/Players/InvitedPlayers/addPlayer';
import getInvitedPlayers from '../../store/Players/InvitedPlayers/getInvitedPlayers';
import getPlayerOverview from '../../store/Players/PlayerOverview/GetPlayerOverview';
import { toast } from 'react-toastify';
import uploadPicture from '../../store/profilePicture/uploadPicture';
import clearAddPlayer from '../../store/Players/InvitedPlayers/clearAddPlayer';
import reInvitePlayer from '../../store/Players/actions/reInvitePlayer';
import ModalDelete from '../SharedComponents/ModalDelete';
import removePlayers from '../../store/Players/actions/removePlayers';

class Players extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRendering: true,
            modal: false,
            seasonDropdownIsOpen: false,
            playerOverview: true,
            playerstodelete: null,
            deletemodal: false,
            fields: fromJS({
                firstName: "",
                lastName: "",
                email: "",
                role: "Manager",
                phoneNumber: "",
                profilePicture: "file.png",
                teamId: "",
                imgTemp: null,
                photoDate: null,
                photo: null
            }),
            errors: fromJS({}),
            filteredSeason: '',
            isinitload: true,
            isVisible: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent() {
        this.setState({ isVisible: true });
    }

    toggle = (e) => {

        const { modal, fields } = this.state;

        let newFieldsState = fields.set('firstName', '');
        newFieldsState = newFieldsState.set('lastName', '');
        newFieldsState = newFieldsState.set('email', '');
        newFieldsState = newFieldsState.set('phoneNumber', '');
        newFieldsState = newFieldsState.set('imgTemp', null);
        newFieldsState = newFieldsState.set('photoDate', null);
        newFieldsState = newFieldsState.set('photo', null);

        this.setState({
            modal: !modal,
            fields: newFieldsState,
            errors: fromJS({})
        });
    }

    toggledelete = (e) => {
        const { deletemodal } = this.state;
        this.setState({
            deletemodal: !deletemodal,
        });
    }

    saveUploadedPhoto(e) {

        this.setState({
            fields: this.state.fields.set('photo', e.id),
            errors: this.state.errors.delete('photo')
        });
    }

    handleChange(field, value) {

        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    handleSubmit() {
        const { fields, modal } = this.state;
        const { t } = this.props;
        let errorList = fromJS({});
        const requiredField = ['firstName', 'lastName','email','teamId']
        const allField = ['firstName', 'lastName', 'email', 'phoneNumber'];

        const simpleEmailRegexp = new RegExp('[^@]+@[^\.]+\..+');

        requiredField.map((x) => {
            if (fields.get(x) == undefined || fields.get(x) == '') errorList = errorList.set(x, true)
        });

        allField.map((x) => {
            if (fields.get(x).length > 50) errorList = errorList.set(x, true)
        });

        if (!simpleEmailRegexp.test(fields.get('email'))) {
            errorList = errorList.set('email', true);
        }

        if (errorList.size > 0) {
            this.setState({
                errors: errorList
            });

            return;
        }
        this.props.addPlayer(fields.toJS(), t)
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });

        this.setState({
            modal: !modal,
            errors: fromJS({})
        });
    }

    playerOverviewstate = (e) => {
        const { playerOverview } = this.state;
        this.setState({
            playerOverview: !playerOverview,
            isRendering: true
        });
        this.props.getInvitedPlayers(!playerOverview)
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });
    }

    closeModal() {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    }

    closeDeleteModal = () => {
        const { deletemodal } = this.state;
        this.setState({
            deletemodal: !deletemodal
        });
    }

    handleTeamChange(e) {

        this.setState({
            fields: this.state.fields.set('teamId', e.target.value),
            errors: this.state.errors.delete('teamId'),
        });
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { t } = this.props;
        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '6000000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('imgTemp', URL.createObjectURL(files[0]));

        newFields = newFields.set('photoDate', dateTag);

        this.setState({
            fields: newFields
        });

        this.props.uploadPicture(formData, dateTag, null, t)
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });
    }

    getResultFromReader(r) {
        this.setState({
            imgTemp: r.target.result
        });
    }

    AddPlayerModal = (modal) => {

        const { fields, errors } = this.state;
        const { allTeams, t } = this.props;

        const selectedPict = fields.get('imgTemp') !== null ? fields.get('imgTemp') : imageUpload;

        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={this.toggle} className="trainermodal">
                <div className="modal-body modal-user">
                    <h2>{t('general.invite') + ' ' + t('players.singlelowerplayer')}</h2>
                    <div className="add_pay_section">
                        <div className="add_pic_section">
                            <div className="upload_btn">
                                <label>
                                    <input type="file" onChange={this.uploadFileChange} style={{ display: "none" }} />
                                    {selectedPict != imageUpload ? (<img src={selectedPict} alt="" className="upload-picture-box" />) : (<img src={selectedPict} alt="" />)}
                                </label>
                            </div>
                            <p className="photo-upload-caption"><small>{t('general.photonote')}</small></p>
                        </div>
                        <div className="add_details">
                            <table className="details_table_add">
                                <tbody>
                                    <tr>
                                        <td>{t('general.role')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <select className="position_select">
                                                <option>{t('players.singletitle')}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('teams.singletitle')}</td>
                                        <td className="dt_gap"></td>
                                        <td>                                            
                                            <select onChange={(value) => this.handleTeamChange(value)} className={`position_select  ${errors.has('teamId') ? 'is-invalid' : ''} `}>                                                
                                                <option key="0" value="">{t('general.select') + ' ' + t('teams.singlelowerteams')}</option>
                                                {allTeams && allTeams.map(x => <option key={x.teamName} value={x.id}>{x.teamName}</option>)}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.firstname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'firstName'}
                                                value={fields.get('firstName')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('firstName')} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.lastname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'lastName'}
                                                value={fields.get('lastName')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('lastName')} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.e-mail')}</td>
                                        <td className="dt_gap"></td>
                                        <td><TextInputField
                                            disabled={false}
                                            field={'email'}
                                            value={fields.get('email')}
                                            onChange={(field, value) => this.handleChange(field, value)}
                                            hasError={errors.has('email')} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="delete_btn_holder">
                                <a href="#" onClick={(e) => { e.preventDefault(); this.closeModal(); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleSubmit(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">{t('general.add')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    createData(id, name, emailAddress, invitedby, invitationdate, status, action, multipletrainer, invitedById, isTokenExpired, invitorPicture, userPicture) {
        return { id, name, emailAddress, invitedby, invitationdate, status, action, multipletrainer, invitedById, isTokenExpired, invitorPicture, userPicture };
    }

    createPlayerOverviewData(id, name, teamId, teamName, trainerId, trainerName, overallProgress, averageScore, overallDiff, multipletrainer,
        totalTrainer, trainerPicture, userPicture, userId, clubPictureURL, trainerUserId) {

        return {
            id, name, teamId, teamName, trainerId, trainerName, overallProgress, averageScore, overallDiff, multipletrainer, overallStatus: overallDiff > 0, totalTrainer: totalTrainer == 0 ? 0 : totalTrainer,
            trainerPicture, userPicture, userId, clubPictureURL, trainerUserId
        };
    }

    handleReinvite = (userId) => {
        const { t } = this.props;
        if (userId)
            this.props.reInvitePlayer(userId, t)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
    }

    componentDidMount() {
        const { playerOverview } = this.state;
        Promise.all([
            this.props.getTeams(),
            this.props.getSeason()])
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
                this.hideComponent();
            });
        //this.props.getInvitedPlayers(playerOverview);

        this.setState({ isRendering: true }, () => {
            this.setState({ isRendering: false });
        });
    }

    componentDidUpdate(prevProps) {
        const { fields, playerOverview, isinitload } = this.state;
        const { addPlayerResult, profilePictureUpload, seasons } = this.props;        
        if (profilePictureUpload && profilePictureUpload.dateTimeTag == fields.get('photoDate')) {
            if (prevProps.profilePictureUpload && profilePictureUpload.id !== prevProps.profilePictureUpload.id)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => {
                        const loading = document.querySelector('.loading');
                        if (loading)
                            loading.style.visibility = "hidden";
                    });

            //if prev profilepic is undefined
            if (profilePictureUpload && prevProps.profilePictureUpload === undefined)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => {
                        const loading = document.querySelector('.loading');
                        if (loading)
                            loading.style.visibility = "hidden";
                    });
        }
        if (addPlayerResult && prevProps.addPlayerResult !== addPlayerResult) {
            this.props.getInvitedPlayers(playerOverview)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
        }

        var initfilteredSeason = '';
        
        if (isinitload && seasons.length > 0) {            
            seasons.map((row, index) => {
                if (row.isCurrent) {
                    initfilteredSeason = row.seasonName;
                    this.props.getInvitedPlayers(row.id)
                        .then(t => {
                            const loading = document.querySelector('.loading');
                            if (loading)
                                loading.style.visibility = "hidden";
                        });
                }
            });
            this.setState({
                isinitload: false,
                filteredSeason: initfilteredSeason
            });
        }
        
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps))
            this.setState({ isRendering: false });
    }

    handleRemovePlayers = (players) => {
        const { deletemodal } = this.state;
        const { t } = this.props;
        if (players.size == 0) {
            toast.success(t('validationerror.noselectedplayers'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            this.setState({
                deletemodal: !deletemodal,
                playerstodelete: players
            });
        }
    }

    okRemove = () => {
        const { playerstodelete, playerOverview, deletemodal } = this.state;
        const { t } = this.props;
        this.props.removePlayers(playerstodelete.toJS(), playerOverview, t);
        this.setState({
            deletemodal: !deletemodal,
            playerstodelete: null,
        });
    }

    toggleItem = () => {
        const { seasonDropdownIsOpen } = this.state;

        this.setState({
            seasonDropdownIsOpen: !seasonDropdownIsOpen
        });
    }

    handleOnSelectSeason = (seasonId, seasonName) => {
        const { playerOverview } = this.state;
        if (seasonId)
            this.props.getInvitedPlayers(true, seasonId)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
        else
            this.props.getInvitedPlayers(playerOverview)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });

        this.setState({
            filteredSeason: seasonName
        });
    }

    render() {
        const { modal, playerOverview, seasonDropdownIsOpen, deletemodal, filteredSeason, isVisible } = this.state;
        const { players, seasons, t } = this.props;

        const invitedManagerHeadCells = [
            { id: 'name', numeric: false, disablePadding: true, label: t('players.title') },
            { id: 'emailAddress', numeric: false, disablePadding: true, label: t('general.e-mail') },
            { id: 'invitedby', numeric: true, disablePadding: false, label: t('general.invitedby') },
            { id: 'invitationdate', numeric: true, disablePadding: false, label: t('general.invitedate') },
            { id: 'status', numeric: true, disablePadding: false, label: t('general.status') },
            { id: 'action', numeric: true, disablePadding: false, align: 'center', label: t('general.action') },
        ];
        const overviewManagerHeadCells = [
            { id: 'name', numeric: false, disablePadding: true, label: t('players.title') },
            { id: 'teamName', numeric: false, disablePadding: true, label: t('teams.title') },
            { id: 'trainerName', numeric: false, disablePadding: true, label: t('trainers.title') },
            { id: 'overallProgress', numeric: false, disablePadding: true, label: t('general.progress') },
            { id: 'averageScore', numeric: true, disablePadding: true, label: t('overview.overall') },
            { id: 'action', numeric: true, disablePadding: false, align: 'center', label: t('general.view') },
        ];
        let rows = [];
        if (!playerOverview && players) {
            rows = players.map(x =>
                this.createData(x.id, x.name, x.emailAddress, x.invitedBy, x.invitationDate, x.status, 0, false, x.invitedById, x.isTokenExpired, x.invitorPicture, x.userPicture))
        }
        if (playerOverview && players) {

            rows = players.map(x =>
                this.createPlayerOverviewData(x.id, x.name, x.teamId, x.teamName, x.trainerId, x.trainerName, x.overallProgress, x.averageScore, x.overallDiff,
                    x.multipleTrainer, x.totalTrainer, x.trainerPicture, x.userPicture, x.userId, x.clubPictureURL, x.trainerUserId))
        }
        return (<div>
            {isVisible && (
                <MaterialTable
                    isRendering={this.state.isRendering}
                    toggle={this.toggle}
                    playerOverviewState={this.playerOverviewstate}
                    isPlayerOverview={playerOverview}
                    rows={rows}
                    headCells={playerOverview ? overviewManagerHeadCells : invitedManagerHeadCells}
                    handleReinvite={this.handleReinvite}
                    seasonDropDownIsOpen={seasonDropdownIsOpen}
                    toggleItem={this.toggleItem}
                    seasons={seasons}
                    handleOnSelectSeasonFilter={this.handleOnSelectSeason}
                    handleRemovePlayers={this.handleRemovePlayers}
                    filteredSeason={filteredSeason}
                    t={t}
                />)}
            {this.AddPlayerModal(modal)}
            <ModalDelete modal={deletemodal} closeModal={this.closeDeleteModal} okRemove={this.okRemove} toggle={this.toggledelete} />
        </div>);
    }

}

export default connect((state) => {
    return {
        allTeams: state.masterData.allTeams,
        players: state.player.playerOverview,
        addPlayerResult: state.player.addPlayerResult,
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        seasons: state.masterData.seasons        
    }
},
    dispatch => bindActionCreators({
        getTeams,
        addPlayer,
        getInvitedPlayers,
        getPlayerOverview,
        uploadPicture,
        clearAddPlayer,
        reInvitePlayer,
        getSeason,
        removePlayers
    }, dispatch))(withTranslation()(Players));