import { fetchTeamTrainerOverview, getTeamDetail } from '../constants';
import { Map } from 'immutable';

const initialState = {
    teams: Map(),
    teamHeader: null
}
export default (state, action) => {
    state = state || initialState;

    if (action.type === fetchTeamTrainerOverview) {
        return { ...state, teamDetails: action.teamDetails };
    }

    if (action.type === getTeamDetail) {
        return { ...state, teamHeader: action.result };
    }

    return state;
}