import React, { useEffect, useState } from 'react'
import Select from 'react-select'

export function AdminHome() {
    const [getFile, setFile] = useState(null)
    const [getClub, setClub] = useState([])
    const [getSeason, setSeason] = useState([])
    const [getTeam, setTeam] = useState([])

    const handleFile = (obj) => {
        setFile(obj.target.files[0])
    }

    const handleUp = () => {
        let dBody = new FormData()
        dBody.append('batchData', getFile)
        fetch('/HomeAdmin', {
            method: 'POST',
            body: dBody
        })
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log(error))
    }

    const handleClub = (event) => {
        FSeason({setSeason:setSeason,clubId:event.value})
    }

    const handleSeason = (event) => {
        FTeam({setTeam:setTeam,seasonId:event.value})
    }

    useEffect(() => {
        FClub({setClub:setClub})
    },[])

    return <div style={{ margin: 'auto', width: '25%' }}>
        <Select options={getClub} styles={dStyle} placeholder={'Clubs'} onChange={handleClub}/>
        <Select options={getSeason} styles={dStyle} placeholder={'Season'} onChange={handleSeason}/>
        <Select options={getTeam} styles={dStyle} placeholder={'Team'}/>
        <input type='file' name='dataBatch' onChange={handleFile}/>
        <button onClick={handleUp}>Upload</button>
    </div>
}

async function FClub(props) {
    let clubs = await fetch('/Admin/Clubs')
    if (clubs.ok) {
        let result = await clubs.json()
        let dOption = result.map((valObj, valInd) => {
            return {
                label: `${valInd + 1}. ${valObj.clubName}`,
                value: valObj.id
            }
        })
        props.setClub(dOption)
    } else {
        throw new Error(clubs.status)
    }
}

function DFormat (val) {
    var date = new Date(val)
    var formatter = new Intl.DateTimeFormat('en-EN', {year:'numeric',month:'short',day:'numeric'})
    var formatted = formatter.formatToParts(date)
    var day = formatted.find(obj=>{return obj.type === 'day'})
    var month = formatted.find(obj=>{return obj.type === 'month'})
    var year = formatted.find(obj=>{return obj.type === 'year'})
    return `${day.value} ${month.value} ${year.value}`
}

async function FSeason(props) {
    let clubs = await fetch('/Admin/Seasons?clubId=' + props.clubId)
    if (clubs.ok) {
        let result = await clubs.json()
        let dOption = result.map((valObj, valInd) => {
            return {
                label: `${valInd + 1}. [${DFormat(valObj.startDate)} - ${DFormat(valObj.endDate)}] ${valObj.seasonName}`,
                value: valObj.id
            }
        })
        props.setSeason(dOption)
    } else {
        throw new Error(clubs.status)
    }
}

async function FTeam(props) {
    let clubs = await fetch('/Admin/Teams?seasonId=' + props.seasonId)
    if (clubs.ok) {
        let result = await clubs.json()
        let dOption = result.map((valObj, valInd) => {
            return {
                label: `${valInd + 1}. ${valObj.teamName}`,
                value: valObj.id
            }
        })
        props.setTeam(dOption)
    } else {
        throw new Error(clubs.status)
    }
}

const dInit = { headers: { 'Content-Type': 'application/json' }, method: 'GET' }

const dStyle = {
    container: (provided) => ({
        ...provided,
        margin:'20px 0'
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: '#616161',
        fontFamily: '"Segoe UI-Regular", Helvetica',
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal'
    }),
    multiValueRemove: () => ({ display: 'none' }),
    option: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        "&:hover": {
            backgroundColor: '#f5f5f5',
        },
        color: '#616161',
        fontFamily: '"Segoe UI-Regular", Helvetica',
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#686868',
        fontFamily: '"Segoe UI-Bold", Helvetica',
        fontSize: '12px',
        fontWeight: 700,
        fontStyle: 'normal'
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#616161',
        fontFamily: '"Segoe UI-Regular", Helvetica',
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal'
    })
}