import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './ChangePassword.css';
import LoginLogo from '../../images/logo.svg';
import { withTranslation } from 'react-i18next';
import { routingLocationSelector } from '../../../store/general/selectors/generalSelector';
import { resetPasswordSelector } from '../../../store/users/selectors/authSelector';
import changePasswordAction from '../../../store/users/actions/changePasswordAction';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            repeatPassword: "",
            isActivation: false,
            passDisplay: false,
            repeatDisplay: false,
            passwordValidationText: "",
            passwordValidationLength: 'Have at least 6 characters',
            passwordValidationSpecial: 'Include special characters',
        };
        this.handleRepeatPasswordChange = this.handleRepeatPasswordChange.bind(this);
        this.checkPasswordValidation = this.checkPasswordValidation.bind(this);
    }

    handleRepeatPasswordChange(e) {
        this.setState({
            repeatPassword: e.target.value
        });
    }

    handleSubmit = () => {
        const { password, repeatPassword, passwordValidationLength, passwordValidationSpecial, passwordValidationLetter } = this.state;

        const { routingLocation, resetPassword } = this.props;

        if (password !== repeatPassword) {
            toast.success("The passwords do not match.", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        const { activatetoken, recoverytoken } = routingLocation.query;

        if (activatetoken)
            
            this.props.changePasswordAction({ password, token: activatetoken, activate: true })
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });

        if (recoverytoken)
            this.props.changePasswordAction({ password, token: recoverytoken, reset: true })
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
    }

    componentDidMount() {
        const { routingLocation } = this.props;

    }

    checkPasswordValidation = (e) => {
        const value = e.target.value;
        let passwordLength = '';
        let passwordSpecial = '';
        if (!value.match('^.{8,15}$')) {
            passwordLength = 'Have at least 8 characters';
        }
        if (!value.match('^(?=.*[!@#$%^&*(),.?":{}|<>])|(?=.*[0-9])')) {
            passwordSpecial = 'Include special characters';
        }
        this.setState({
            passwordValidationLength: passwordLength,
            passwordValidationSpecial: passwordSpecial,
            password: value
        });
    }

    togglePass = () => {
        const { passDisplay } = this.state;
        this.setState({
            passDisplay: !passDisplay
        });
    }

    togglePassRepeat = () => {
        const { repeatDisplay } = this.state;
        this.setState({
            repeatDisplay: !repeatDisplay
        });
    }

    render() {
        const { password, repeatPassword, isActivation, repeatDisplay, passDisplay, passwordValidationLength, passwordValidationSpecial } = this.state;
        const { routingLocation, t, resetPassword } = this.props;
        const { activatetoken, recoverytoken } = routingLocation.query;
        const disableButton = passwordValidationLength == '' && passwordValidationSpecial == '' && repeatPassword == password;
        if (resetPassword)
            return (<Redirect to='/login' />);

        if (activatetoken || recoverytoken)
            return (
                <div className="login_container">
                    <div className="login_left login_background_image">
                        <img src={LoginLogo} />
                    </div>
                    <div className="login_right">
                        <div className="login_box">
                            <h1 className="set-up-new-password">{t('tokenactivation.passwordreset')}</h1>
                            <div className="change-password-caption">
                                <label>{t('changepassword.criteriatitle')}</label>
                                <ul>
                                    {passwordValidationLength !== '' ?
                                        (<li>{t('changepassword.criteria1')}</li>) :
                                        (<li className='validate-ok'>{t('changepassword.criteria1')}</li>)}
                                    {passwordValidationSpecial !== '' ?
                                        (<li>{passwordValidationSpecial}</li>) :
                                        (<li className='validate-ok'>{t('changepassword.criteria2')}</li>)}
                                </ul>
                            </div>
                            <div className="loginform_holder">
                                <form>
                                    <div className="form_field">
                                        <input type={passDisplay ? "text" : "password"} placeholder={t('general.password')} value={password} onChange={this.checkPasswordValidation}
                                            className="password_fld" className="password_fld pwd_border" />
                                        <span onClick={this.togglePass}><FontAwesomeIcon icon={passDisplay ? faEyeSlash : faEye} className="custom-input-icon" /> </span>
                                    </div>
                                    <div className="form_field">
                                        <input type={repeatDisplay ? "text" : "password"} placeholder={t('general.repeatpassword')}
                                            value={repeatPassword} onChange={this.handleRepeatPasswordChange}
                                            id="repeatpassword-field" className="password_fld" />
                                        <span onClick={this.togglePassRepeat}><FontAwesomeIcon icon={repeatDisplay ? faEyeSlash : faEye} className="custom-input-icon" /> </span>
                                    </div>
                                    <input type="button" onClick={this.handleSubmit} value={t('general.submit')} />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor:"#000000", padding:"20px 0", textAlign:"center", bottom:"0", position:"fixed", width:"100%" }}>
                        <a style={{ fontSize:"14px", color:"lightgrey" }}>© 2021 Pro Season b.v All Rights Reserved</a>
                    </div>
                </div>);
        return (<Redirect to='/login' />);
    }
}

export default connect((state) => {
    return {
        routingLocation: routingLocationSelector(state),
        resetPassword: resetPasswordSelector(state)
    }
}, dispatch => bindActionCreators({ changePasswordAction }, dispatch))(withTranslation()(ChangePassword));