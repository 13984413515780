import packageJson from '../../package.json';
import React, { Component } from 'react';

class Version extends Component {
    render() {
        return (
            <span>
                <b>Proseason:</b> <br />
                Version: {packageJson.version}
            </span>
        );
    }
}

export default (Version);