import { toast } from 'react-toastify';
import { tokenKey, updateLoggedInUser, userId, userRole, userEmail, authUrl } from '../../constants';

export default (loginData) => async (dispatch) => {
    const loading = document.querySelector('.loading');
    var url = `${authUrl}/Account/Login`;
    var reqBody = {
        'userName': loginData.email,
        'password': loginData.password,
        'rememberLogin': true
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody)
        });
        const result = await response.json();

        if (!response.ok || result.errorMessage !== null) {
            toast.error(`login or password incorrect`, {
                position: toast.POSITION.TOP_RIGHT
            });
            sessionStorage.removeItem(tokenKey);
            sessionStorage.removeItem(userId);
            sessionStorage.removeItem(userEmail);
            if (loading)
                loading.style.visibility = "hidden";
        } else {
            //toast.success("Login Succeeded", {
            //    position: toast.POSITION.TOP_RIGHT
            //});
            sessionStorage.setItem(tokenKey, result.accessToken);
            sessionStorage.setItem(userId, result.id);
            sessionStorage.setItem(userRole, result.role);
            dispatch({ type: updateLoggedInUser, loggedInUser: result });
        }
    } catch (e) {
        console.log(e);
        if (loading)
            loading.style.visibility = "hidden";
    }
};