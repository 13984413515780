import { webAppUrl, fetchTeamOverviewDashboard } from '../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (seasonId, teamIds) => async (dispatch) => {

    let body = '';
    
    if (teamIds && teamIds.length > 0) {
        body = JSON.stringify(teamIds.map(x => x.value).join(','));
    }
    const teamOverviewResponse = await fetchWithBearerToken(`${webAppUrl}/api/Dashboard/GetDashboardTeamOverview?clubSeasonId=${seasonId}`,
    'POST',body);

    if (teamOverviewResponse.ok) {
        const teamOverviewResult = await teamOverviewResponse.json();

        dispatch({ type: fetchTeamOverviewDashboard, result: teamOverviewResult });
    }
}