    import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button
} from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import trashwhite from '../images/Trash.svg';
import roundplus from '../images/round_plus_icon.svg';
import { MdChevronRight } from "react-icons/md";
import { FaCaretUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { timeSince } from '../../helpers/dateHelper';
import { fromJS } from 'immutable';
import ProfilePic from '../SharedComponents/ProfileThumImage';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <label className="chkholder">
                        <Input type="checkbox"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                        <span className="checkmark"></span>
                    </label>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align ?? 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label != 'View' &&
                            < TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={FaCaretUp}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                        {
                            headCell.label == 'View' &&
                            <span>{headCell.label}</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, ismanageroverview, rows, seasonDropDownIsOpen, toggleItem,
        seasons, handleOnSelectSeason, filterManagerSeason, isActiveRemove, translation } = props;

    return (
        <div className="datatable_top">
            <div className="datatable_top_left">{translation('general.all') + ' ' + translation('managers.lowermanager')}
                <div className="badge badge-danger badge-danger-extend">{rows.length}</div> </div>
            <div className="toggle_sw">
                <span>{translation('general.invited')}</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        defaultChecked={props.ismanageroverview}
                        onChange={(e) => { props.manageroverviewstate(); }}
                    />
                    <span className="slider round"></span>
                </label>
                <span className="active_tg">{translation('general.active')}</span>
            </div>
            <div className="datatable_top_right">
                {/*<a href="#" className={isActiveRemove ? "btn_remove_active" : "btn_remove"} data-toggle="modal"*/}
                {/*    onClick={(e) => { e.preventDefault(); props.handleRemove(); }}*/}
                {/*    data-target="#delete_modal"><img src={trashwhite} alt="" /></a>*/}
                {ismanageroverview &&
                    (<Dropdown isOpen={seasonDropDownIsOpen} toggle={toggleItem} className="dropdown-caret" value='test' className='season-btn'>
                        <DropdownToggle caret>
                            {filterManagerSeason}
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                //<DropdownItem>
                                //    <div class="season-inline" id="" onClick={handleOnSelectSeason}><span>All manager</span></div>
                                //</DropdownItem>
                            }
                            {seasons.map((row, index) => {
                                //let current = '';
                                //current = row.isCurrent ? (<img src={checklistCompleted} style={{ marginLeft: '10px' }} class="report-skill-small-thumb" />) : '';
                                return (
                                    <DropdownItem>
                                        <div className="season-inline" id={row.id} onClick={handleOnSelectSeason}>
                                            <span>{row.seasonName}{/*current*/}</span>
                                        </div>
                                    </DropdownItem>
                                )
                            })}
                            {/*<DropdownItem divider /><DropdownItem><div class="season-drop-line"><span>+ Add new season</span></div></DropdownItem>*/}
                        </DropdownMenu>
                    </Dropdown>)
                }
                <a href="#" onClick={(e) => { e.preventDefault(); props.toggle(true); }} className="btn_adnew" data-toggle="modal" data-target="#trainer_modal"><img src={roundplus} alt="" /></a>
            </div>
        </div >
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    buttonInvitationDisable: {
        background: 'grey',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 3px 5px 2px ',
        color: 'white',
        height: 40,
        padding: '0 15px',
        textTransform: 'none',
        pointerEvents: 'none',
        outline: 'none',
    },
    buttonInvitationEnable: {
        background: 'black',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 3px 5px 2px ',
        color: 'white',
        height: 40,
        padding: '0 5px',
        textTransform: 'none',
        outline: 'none',
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const { rows, ismanageroverview, handleRemoveManagers, seasonDropDownIsOpen, toggleItem, seasons,
        handleReinvite, filterManagerSeason, filterHandleOnSelectSeason, translation } = props;
    const [activeRemove, setActiveRemove] = React.useState(false);

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            setActiveRemove(true);
            return;
        }
        setSelected([]);
        setActiveRemove(false);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setActiveRemove(newSelected.length > 0 ? true : false);
    };

    const handleInvite = (e) => {
        handleReinvite(e.currentTarget.id);
    };

    const handleOnSelectSeason = (e) => {
        filterHandleOnSelectSeason(e.currentTarget.id, e.target.outerText)
    };

    const handleChangePage = (event, newPage) => {

        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleRemove = (e) => {
        var users = fromJS([]);
        rows.map((row, index) => {
            if (isSelected(row.id))
                users = users.push(fromJS({ "Id": row.id }));
        });
        handleRemoveManagers(users);
    }

    return (
        <div className="shadow_container">
            <EnhancedTableToolbar
                numSelected={selected.length}
                toggle={props.toggle}
                manageroverviewstate={props.manageroverviewstate}
                ismanageroverview={ismanageroverview}
                rows={props.rows}
                handleRemove={handleRemove}
                seasonDropDownIsOpen={seasonDropDownIsOpen}
                toggleItem={toggleItem}
                seasons={seasons}
                handleOnSelectSeason={handleOnSelectSeason}
                filterManagerSeason={filterManagerSeason}
                isActiveRemove={activeRemove}
                translation={translation}
            />
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={props.headCells}
                    />
                    {(props.isRendering) ? null : <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={index}
                                        key={index}
                                    >
                                        <TableCell padding="checkbox">
                                            <label className="chkholder">
                                                <Input type="checkbox"
                                                    onChange={(event) => handleClick(event, row.id)}
                                                    checked={isItemSelected}
                                                />
                                                <span className="checkmark"></span>
                                            </label>

                                        </TableCell>
                                        <TableCell component="th" id={`enhanced-table-checkbox-${index}`} scope="row" padding="none">
                                            <div className="player_table_row">
                                                <div className="player_image manager_pic">
                                                    <ProfilePic
                                                        imgUrl={row.userPicture}
                                                        urlDetail={'/manager/detail-manager?userId=' + row.id}
                                                        cls="" />
                                                </div>
                                                <div className="player_name">{row.name}</div>
                                            </div>
                                        </TableCell>
                                        {!ismanageroverview && (<TableCell align="left">{row.emailAddress}</TableCell>)}
                                        <TableCell align="left"><div className="player_table_row">
                                            {!ismanageroverview &&
                                                (
                                                    <div className="player_image manager_pic">
                                                    <ProfilePic
                                                        urlDetail={'/manager/detail-manager?userId=' + row.invitorId}
                                                        imgUrl={row.invitorPicture} cls="mCS_img_loaded"
                                                    />
                                                    </div>
                                                )
                                            }
                                            <div className="player_name">{ismanageroverview ? row.dateStarted : row.invitedBy}</div>
                                        </div>
                                        </TableCell>
                                        <TableCell align="left">{ismanageroverview ? row.lastLogin : row.invitationDate}</TableCell>
                                        {!ismanageroverview && (<TableCell align="left">{row.status}</TableCell>)}
                                        {!ismanageroverview && (<TableCell align="center">
                                            <Button className={classes.buttonInvitationEnable} id={row.id} onClick={handleInvite}>Invite again</Button></TableCell>)}
                                        {ismanageroverview && (<TableCell align="center">
                                            <Link to={'/manager/detail-manager?userId=' + row.id} className="detail-manager-link">
                                                <MdChevronRight className="detail-manager" />
                                            </Link>
                                        </TableCell>)}
                                    </TableRow>
                                );
                            })}
                        {
                            //emptyRows > 0 && (
                            //<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                            //    <TableCell colSpan={6} />
                            //</TableRow>
                            //)
                        }
                    </TableBody>}
                </Table>
            </TableContainer>
            {
                //<TablePagination
                //rowsPerPageOptions={[5, 10, 25]}
                //component="div"
                //count={rows.length}
                //rowsPerPage={rowsPerPage}
                //page={page}
                //onChangePage={handleChangePage}
                //onChangeRowsPerPage={handleChangeRowsPerPage}
                ///>
            }
        </div>
    );
}
