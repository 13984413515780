import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button
} from '@material-ui/core';
import { MdChevronRight } from "react-icons/md";
import { FaCaretUp } from 'react-icons/fa';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import trashwhite from '../images/Trash.svg';
import roundplus from '../images/round_plus_icon.svg';
import ProfilePic from '../SharedComponents/ProfileThumImage';
import { fromJS } from 'immutable';

function createData(id, name, invitedby, invitationdate, status) {
    return { id, name, invitedby, invitationdate, status };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, isPlayerOverview } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <label className="chkholder">
                        <Input type="checkbox"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                        <span className="checkmark"></span>
                    </label>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align ?? 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >

                        {headCell.label != 'View' &&
                            < TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={FaCaretUp}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                        {
                            headCell.label == 'View' &&
                            <span>{headCell.label}</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, rows, isPlayerOverview, seasonDropDownIsOpen, toggleItem, seasons, handleOnSelectSeason, filteredSeason, isActiveRemove, t } = props;
    let totalCount = 0;
    if (rows)
        totalCount = rows.length;

    return (
        <div className="datatable_top">
            <div className="datatable_top_left">{t('general.all') + ' ' + t('players.lowerplayers')} <div className="badge badge-danger badge-danger-extend">{totalCount}</div> </div>
            <div className="toggle_sw">
                <span>{t('general.invited')}</span>
                <label className="switch">
                    <input type="checkbox" defaultChecked={props.isPlayerOverview} onChange={(e) => props.playerOverviewState()} />
                    <span className="slider round"></span>
                </label>
                <span className="active_tg">{t('general.active')}</span>
            </div>
            <div className="datatable_top_right">
                {/*<a href="#" className={isActiveRemove ? "btn_remove_active" : "btn_remove"} data-toggle="modal"*/}
                {/*    onClick={(e) => { e.preventDefault(); props.handleRemove(); }}*/}
                {/*    data-target="#delete_modal"><img src={trashwhite} alt="" /></a>*/}
                <div className="season-btn">
                    {isPlayerOverview && (
                        <Dropdown isOpen={seasonDropDownIsOpen} toggle={toggleItem} className="dropdown-caret">
                            <DropdownToggle caret>
                                {filteredSeason}
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    seasons.map((row, index) => {
                                        return (<DropdownItem >
                                            <div className="season-inline" id={row.id} onClick={handleOnSelectSeason}>
                                                <span>{row.seasonName}{/*current*/}</span>
                                            </div>
                                        </DropdownItem>)
                                    })
                                }
                            </DropdownMenu>
                        </Dropdown>)}
                </div>
                <a href="#" onClick={(e) => { e.preventDefault(); props.toggle(); }} className="btn_adnew" data-toggle="modal" data-target="#trainer_modal"><img src={roundplus} alt="" /></a>
            </div>
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    buttonInvitationDisable: {
        background: 'grey',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 3px 5px 2px ',
        color: 'white',
        height: 40,
        padding: '0 5px',
        textTransform: 'none',
        pointerEvents: 'none',
        outline: 'none',
    },
    buttonInvitationEnable: {
        background: 'black',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 3px 5px 2px ',
        color: 'white',
        height: 40,
        padding: '0 5px',
        textTransform: 'none',
        outline: 'none',
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const [order, setOrder] = React.useState('asc');

    const [orderBy, setOrderBy] = React.useState('teamName');

    const [selected, setSelected] = React.useState([]);

    const [page, setPage] = React.useState(0);

    const [dense, setDense] = React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const { rows, headCells, isPlayerOverview, playerOverviewState, handleRemovePlayers, handleReinvite,
        seasonDropDownIsOpen, toggleItem, seasons, handleOnSelectSeasonFilter, filteredSeason, t } = props;

    const [activeRemove, setActiveRemove] = React.useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            setActiveRemove(true);
            return;
        }
        setSelected([]);
        setActiveRemove(false);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setActiveRemove(newSelected.length > 0 ? true : false);
    };

    const handleInvite = (e) => {
        handleReinvite(e.currentTarget.id);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleRemove = (e) => {
        var users = fromJS([]);
        rows.map((row, index) => {
            if (isSelected(row.id))
                users = users.push(fromJS({
                    "Id": row.id
                }));
        });
        handleRemovePlayers(users);
    }

    const handleOnSelectSeason = (e) => {
        handleOnSelectSeasonFilter(e.currentTarget.id, e.target.outerText)        
    };

    return (
        <div className="shadow_container managertable_holder">
            <EnhancedTableToolbar
                playerOverviewState={playerOverviewState}
                isPlayerOverview={isPlayerOverview}
                rows={rows}
                numSelected={selected.length}
                toggle={props.toggle}
                seasonDropDownIsOpen={seasonDropDownIsOpen}
                toggleItem={toggleItem}
                seasons={seasons}
                handleRemove={handleRemove}
                handleOnSelectSeason={handleOnSelectSeason}
                filteredSeason={filteredSeason}
                isActiveRemove={activeRemove}
                t={t}
            />
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead headCells={headCells}
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        isPlayerOverview={isPlayerOverview}
                    />
                    {(props.isRendering) ? null : <TableBody>
                        {!isPlayerOverview && stableSort(rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name} >
                                        <TableCell padding="checkbox">
                                            <label className="chkholder">
                                                <Input type="checkbox"
                                                    onChange={(event) => handleClick(event, row.id)}
                                                    checked={isItemSelected}
                                                />
                                                <span className="checkmark"></span>
                                            </label>

                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                            <div className="player_table_row"><div className="player_image manager_pic">
                                                <ProfilePic imgUrl={row.userPicture}
                                                    urlDetail={'/players/detail-player?userId=' + row.id}
                                                    cls="mCS_img_loaded" />
                                            </div>
                                                <div className="player_name">{row.name}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">{row.emailAddress}</TableCell>
                                        <TableCell align="right"><div className="player_table_row"><div className="trainer_lg">
                                            <ProfilePic
                                                urlDetail={'/manager/detail-manager?userId=' + row.invitedById}
                                                imgUrl={row.invitorPicture} cls="mCS_img_loaded" /></div>
                                            <div className="player_name text-left">{row.invitedby}</div>
                                        </div></TableCell>
                                        <TableCell align="left">{row.invitationdate}</TableCell>
                                        <TableCell align="left">{row.status}</TableCell>
                                        <TableCell align="center">
                                            <Button className={classes.buttonInvitationEnable} id={row.id} onClick={handleInvite}>Invite again</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {isPlayerOverview && stableSort(rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);

                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                    //selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <label className="chkholder">
                                                <Input type="checkbox"
                                                    onChange={(event) => handleClick(event, row.id)}
                                                    checked={isItemSelected}
                                                />
                                                <span className="checkmark"></span>
                                            </label>

                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                            <div className="player_table_row"><div className="player_image manager_pic">
                                                <ProfilePic
                                                    imgUrl={row.userPicture}
                                                    urlDetail={'/players/detail-player?userId=' + row.userId}
                                                    cls="mCS_img_loaded" />
                                            </div>
                                                <div className="player_name">{row.name}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell scope="row" padding="none" align="left"><div className="player_table_row">
                                            <div className="player_image">
                                                <img src={row.clubPictureURL} alt="" className="mCS_img_loaded" /></div>
                                            <div className="traincode">{row.teamName}</div>
                                        </div>
                                        </TableCell>
                                        {row.totalTrainer > 1 &&
                                            (<TableCell align="left"><div className="player_table_row left-row"><div className="multiple-trainer_lg">
                                                <ProfilePic
                                                    urlDetail={'/trainers/detail-trainer?userId=' + row.trainerUserId}
                                                    imgUrl={row.trainerPicture}
                                                    cls="mCS_img_loaded trainer-trailing-image"
                                                />
                                                <div className="number-circle"><label className="label-circle-round">+{row.totalTrainer}</label></div>
                                            </div>
                                                <div>{row.totalTrainer} Trainers</div>
                                            </div></TableCell>)
                                        }
                                        {row.totalTrainer <= 1 && (
                                            <TableCell align="left" >
                                                <div className="player_table_row left-row">
                                                    <div className="trainer_lg">
                                                        <ProfilePic
                                                            urlDetail={'/trainers/detail-trainer?userId=' + row.trainerUserId}
                                                            imgUrl={row.trainerPicture}
                                                            cls="mCS_img_loaded" />
                                                    </div>
                                                    <div className="player_name text-left">{row.trainerName}</div>
                                                </div>
                                            </TableCell>)}
                                        <TableCell align="left" className="left-row">
                                            <div className="progress_bar">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{ width: row.overallProgress + "%" }} aria-valuenow={row.overallProgress} aria-valuemin="0" aria-valuemax="100"><span>{row.overallProgress}%</span></div>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left" className="left-row">
                                            <div className="cn_top_tx nopadding row-score">
                                                {` ${row.averageScore === '0.0' ? ' ' : row.averageScore }`}
                                            </div>
                                        </TableCell>
                                        <TableCell classNameName="detail-manager-link" align="center">
                                            <Link to={'/players/detail-player?userId=' + row.userId} className="detail-manager-link">
                                                <MdChevronRight className="detail-player" />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>}
                </Table>
            </TableContainer>
        </div>
    );
}
