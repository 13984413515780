import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import DshoreSelect from "./DshoreSelect.js";
import { components } from "react-select";
import arrow_down from '../../images/arrow_down.png';
import { FaCaretDown } from 'react-icons/fa';
import { Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <label className="chkholder-select">
                    <Input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />
                    <span className="checkmark-select"></span>
                </label>
                <div className="label-select">{props.label}</div>
            </components.Option>
        </div>
    );
};

const allOption = {    
    label: "All teams",
    value: "*"
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;

    if (currentValues.length > 0) {
        toBeRendered = [[children[0][0]], children[1]];
    }
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }
    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};



const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaCaretDown fill="#545b62" className="dropdown-arrow-dark" />
        </components.DropdownIndicator>
    );
};

const animatedComponents = makeAnimated();

export default class CheckboxMultiselect extends Component {
    constructor(props) {
        super(props);
        //selectedTeamsFilter
        this.state = {
            optionSelected: null
        };
    }

    handleChange = selected => {
        this.setState({
            optionSelected: selected
        });
    };

    render() {
        const { allTeamMember, selectedTeam, populateSelectedTeam, displayedText, singleTeam, pluralTeams } = this.props;

        const MultiValue = props => {
            const currentValues = props.getValue();           
            let labelToBeDisplayed = `${currentValues.length} ${currentValues.length > 1 ? pluralTeams : singleTeam} `;

            if (props.data.value === allOption.value) {
                labelToBeDisplayed = displayedText;
            }

            return (
                <span>{labelToBeDisplayed}</span>
            );
        };
        return (
            <span
                className="d-inline-block"
                data-toggle="popover"
                data-trigger="focus"
                data-content=""
            >
                <DshoreSelect
                    options={allTeamMember}
                    customoptionsall={displayedText}
                    placeholder={displayedText}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option,
                        MultiValue,
                        ValueContainer,
                        animatedComponents,
                        DropdownIndicator,
                        IndicatorSeparator: () => null
                    }}
                    onChange={(e) => { populateSelectedTeam(e); }}
                    allowSelectAll={true}
                    value={selectedTeam}
                />
            </span>
        );
    }
}
