import { webAppUrl, fetchMainDashboard } from '../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (seasonId, teamIds) => async (dispatch) => {
    let listTeams = '';

    if (teamIds && teamIds.length > 0) {
        listTeams = teamIds.map(x => x.value).join(',');
    }

    //player development
    const maindDashboardResponse = await fetchWithBearerToken(`${webAppUrl}/api/Dashboard/GetMainDashboard?clubSeasonId=${seasonId}&teamIds=${listTeams}`,
        'Get');

    if (maindDashboardResponse.ok) {
        const mainDashboardResult = await maindDashboardResponse.json();

        dispatch({ type: fetchMainDashboard, result: mainDashboardResult });
    }
}