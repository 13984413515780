import React, { Component } from 'react';
import teamLogo from '../images/Team_logo.png'
import { MdChevronLeft } from "react-icons/md";
import TrainerTable from './TrainerTable';
import PlayerTable from './PlayerTable';
import ChangePlayerModalTable from './ChangePlayerModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchTrainerOverview from '../../store/TeamDetail/Action/fetchTrainerOverview';
import soccerIcon from '../../constants/Images/soccer_icon.svg';
import trashwhite from '../images/Trash.svg';
import { Modal, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { fromJS } from 'immutable';
import { toast } from 'react-toastify';
import removeTeamPlayers from '../../store/TeamDetail/Action/removeTeamPlayers';
import removeTeamTrainers from '../../store/TeamDetail/Action/removeTeamTrainers';
import getInvitedPlayers from '../../store/TeamDetail/Action/fetchPlayerOverview';
import { routingLocationSelector } from '../../store/general/selectors/generalSelector';
import getTeams from '../../store/MasterData/getTeams';
import changeTeamPlayers from '../../store/TeamDetail/Action/changeTeamPlayers';
import getSeason from '../../store/MasterData/getSeason';
import getAvailablePlayersOrTrainers from '../../store/Manager/actions/getAvailablePlayersOrTrainers';
import getTeamHeader from '../../store/TeamDetail/Action/getTeamDetail';
import addPlayersTrainers from '../../store/TeamDetail/Action/addTrainersPlayers';
import editicon from '../images/edit_ic.svg';
import TextInputField from '../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';

import addTeam from '../../store/Teams/Action/addTeam';

class TeamDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            teamModal: false,
            seasonDropDownIsOpen: false,
            changeTeam: false,
            addPlayer: false,
            dataplayer: fromJS([]),
            changeModalStatus: '',
            newTeam: '',
            selectedRow: [],
            datatrainer: fromJS([]),
            dataplayerchange: fromJS([]),
            datatrainerchange: fromJS([]),
            trainersGridData: [],
            playersGridData: [],
            changeTeamData: [],
            teamId: '',
            teamName: '',
            division: '',
            filteredSeason: '',
            initfilteredSeason: true,
        }
        this.goBack = this.goBack.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.setSelectedRow = this.setSelectedRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setTeam = this.setTeam.bind(this);
        this.handleTeamChange = this.handleTeamChange.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.handleOnSelectSeason = this.handleOnSelectSeason.bind(this);
    }

    hideLoading() {
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "hidden";
    }

    setSelectedRow(e) {
        const { selectedRow } = this.state;

        var rows = selectedRow;

        rows.push(e);

        this.setState({
            selectedRow: rows
        });
    }

    toggleItem = () => {
        const { seasonDropDownIsOpen } = this.state;

        this.setState({
            seasonDropDownIsOpen: !seasonDropDownIsOpen
        });
    }

    componentDidMount() {

        const { routingLocation } = this.props;

        Promise.all([
            this.props.getTeamHeader(routingLocation.query['teamId']),
            this.props.getTeams(),
            this.props.getSeason(),
            this.props.fetchTrainerOverview(routingLocation.query['teamId']),
            this.props.getInvitedPlayers(routingLocation.query['teamId'])])
            .then(t => this.hideLoading());

        this.setState({
            teamId: routingLocation.query['teamId']
        });
    }

    goBack(e) {
        this.props.history.goBack();
        e.preventDefault();
    }

    toggle = (e) => {
        const { modal, changeTeam, addPlayer } = this.state;
        const { t } = this.props;
        if (e == 'delete') {
            this.setState({
                modal: !modal,
            });
        }
        if (e == 'add') {
            this.props.getAvailablePlayersOrTrainers('player')
                .then(t => this.hideLoading());
            this.setState({
                addPlayer: !addPlayer,
                changeModalStatus: t('players.singlelowerplayer')
            });
        }
        if (e == 'addTrainer') {
            this.props.getAvailablePlayersOrTrainers('trainer')
                .then(t => this.hideLoading());
            this.setState({
                addPlayer: !addPlayer,
                changeModalStatus: t('trainers.singletitlelower')
            });
        }
        if (e == 'change') {
            this.setState({
                changeTeam: !changeTeam,
            });
        }
    }

    prepareToRemove = () => {
        const { dataplayer, datatrainer } = this.state;
        const { t } = this.props;
        if (dataplayer.size == 0 && datatrainer.size == 0) {
            toast.error(t('validationerror.noselecteditems'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else
            this.toggle('delete');
    }

    prepareToChange = () => {
        const { dataplayer, datatrainer } = this.state;
        const { t } = this.props;
        if (dataplayer.size == 0 && datatrainer.size == 0) {
            toast.error(t('validationerror.noselecteditems'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else
            this.toggle('change');

    }

    handleRemove = () => {
        const { dataplayer, datatrainer } = this.state;
        const { routingLocation } = this.props;
        const teamId = routingLocation.query['teamId'];

        if (dataplayer.size > 0)
            this.props.removeTeamPlayers(dataplayer, teamId)
                .then(t => this.hideLoading());

        if (datatrainer.size > 0)
            this.props.removeTeamTrainers(datatrainer, teamId)
                .then(t => this.hideLoading());

        this.toggle('delete');
    }

    handleTeamChange = () => {
        const { dataplayer, datatrainer, newTeam } = this.state;
        const { routingLocation, t } = this.props;
        const teamId = routingLocation.query['teamId'];

        if (dataplayer.size > 0)
            this.props.changeTeamPlayers(dataplayer, newTeam, 'player', 'team', t)
                .then(t => this.hideLoading());

        if (datatrainer.size > 0)
            this.props.changeTeamPlayers(datatrainer, newTeam, 'trainer', 'team', t)
                .then(t => this.hideLoading());

        this.toggle('change');
    }

    handlePopulateUser = (data, isPlayer) => {
        var users = fromJS([]);

        data.map((row) => {
            users = users.push(fromJS({
                "Id": row
            }));
        });
        if (isPlayer) {
            this.setState({
                dataplayer: users,
            });
        }
        else {
            this.setState({
                datatrainer: users,
            });
        }
    }

    handleSelectChangeUser = (data, isPlayer) => {
        var users = fromJS([]);

        data.map((row) => {
            users = users.push(fromJS({
                "Id": row
            }));
        });
        if (isPlayer) {
            this.setState({
                dataplayerchange: users,
            });
        }
        else {
            this.setState({
                datatrainerchange: users,
            });
        }
    }

    setTeam = (e) => {
        this.setState({ newTeam: e.target.value });
    }

    refreshToggleStatus = () => {

        this.setState({
            addPlayer: !this.state.addPlayer,
            dataplayerchange: fromJS([]),
            datatrainerchange: fromJS([])
        })
    }

    handleAddPlayerTrainerSubmit = () => {
        const { routingLocation, t } = this.props;
        const { dataplayerchange, datatrainerchange } = this.state;

        if (this.state.changeModalStatus == 'player') {
            this.props.addPlayersTrainers(dataplayerchange, routingLocation.query['teamId'], this.state.changeModalStatus, t)
                .then(t => this.hideLoading());
        }
        else {
            this.props.addPlayersTrainers(datatrainerchange, routingLocation.query['teamId'], this.state.changeModalStatus, t)
                .then(t => this.hideLoading());
        }

    }

    addPlayerModal = (modal) => {
        const { changeModalStatus, dataplayerchange, datatrainerchange } = this.state;
        const { availableTrainer, availablePlayer, teamHeader, t } = this.props;
        const headCellsChangeTeam = [
            { id: 'firstName', numeric: false, disablePadding: true, label: changeModalStatus == 'player' ? t('players.title') : t('trainers.title') },
            { id: 'teamName', numeric: false, disablePadding: true, label: t('generaldetail.currentteam') }];

        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={this.refreshToggleStatus} className="change-add-modal">
                <div className="modal-body">
                    <div className="row change-add-top">
                        <div className="col-md-8"><h2 className="add-move-trainer">Add/move {changeModalStatus}</h2></div>
                        <div className="col-md-3"><img className="trainer_lg rounded-circle" src={teamHeader && teamHeader.logoClub ? teamHeader.logoClub : teamLogo} alt="" /><span>{teamHeader && teamHeader.teamName}</span></div>

                    </div>
                    <div className="add_pay_section nopadbtm">
                        <div className="dasboad_header_search">
                            <input type="text" name="" className="searchbox" placeholder={`Search ${changeModalStatus}`}></input>
                            <input type="submit" name="" value="" className="searcsubmit"></input>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="change-player-container">
                            <ChangePlayerModalTable
                                headCells={headCellsChangeTeam}
                                rows={changeModalStatus == 'player' ? availablePlayer : availableTrainer}
                                handlePopulateUser={this.handleSelectChangeUser}
                                toggle={this.toggle}
                                status={changeModalStatus}
                                teamHeader={teamHeader && teamHeader.logoClub ? teamHeader.logoClub : teamLogo}
                            />
                        </div>
                    </div>
                    <div className="delete_btn_holder right">
                        <a href="#" onClick={(e) => { e.preventDefault(); this.toggle('add'); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                        <a href="#" onClick={(e) => {
                            e.preventDefault(); this.handleAddPlayerTrainerSubmit();
                            this.state.changeModalStatus == 'player' ? this.toggle('add') : this.toggle('addTrainer');
                        }}
                            className="btn_add_modal"
                            data-dismiss="modal" aria-label="Close">{t('general.submit')} ({changeModalStatus == 'player' ? dataplayerchange.size : datatrainerchange.size})</a>
                    </div>
                </div>
            </Modal>)
    }

    changeTeamModal = (modal) => {
        const { allTeams, teamHeader } = this.props;

        return (<Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
            toggle={() => { this.toggle('change') }} className="trainermodal">
            <div className="modal-body modal-user">
                <h2>Move player/trainer to another team</h2>
                <table className="details_table_add adtezm">
                    <tbody>
                        <tr>
                            <td>Current team</td>
                            <td className="dt_gap">:</td>
                            <td data-children-count="1">
                                <input type="text" name="" value={teamHeader && teamHeader.teamName} />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="dt_gap"></td>
                            <td className="text-center">
                                <img src="assets/images/arrow_down.png" alt="" className="gap_arrow" />
                            </td>
                        </tr>
                        <tr>
                            <td>Change to</td>
                            <td className="dt_gap">:</td>
                            <td data-children-count="1">
                                <select onChange={(e) => this.setTeam(e)} className="position_select">
                                    <option key="0" value="">Select Team</option>
                                    {allTeams && allTeams.map(x => <option key={x.teamName} value={x.id}>{x.teamName}</option>)}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <div className="delete_btn_holder">
                    <a href="#" onClick={(e) => { e.preventDefault(); this.closeModal(false); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">Cancel</a>
                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleTeamChange(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">Submit</a>
                </div>
            </div>
        </Modal>);
    }

    closeModal(addModal) {
        const { modal, deleteModal, changeTeam } = this.state;
        if (addModal)
            this.setState({
                modal: !modal
            });
        else
            this.setState({
                changeTeam: !changeTeam
            });
    }

    removeModalConfirmation = (modal) => {

        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={() => { this.toggle('delete') }} className="trainermodal">
                <div className="modal-body modal-user">
                    <h2>Move player/trainer to another team</h2>
                    <table className="details_table_add adtezm">
                        <tbody>
                            <tr>
                                <td>Current team</td>
                                <td className="dt_gap">:</td>
                                <td data-children-count="1">
                                    <input type="text" name="" value="YU8 - 2" />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td className="dt_gap"></td>
                                <td className="text-center">
                                    <img src="assets/images/arrow_down.png" alt="" className="gap_arrow" />
                                </td>
                            </tr>
                            <tr>
                                <td>Change to</td>
                                <td className="dt_gap">:</td>
                                <td data-children-count="1">
                                    <select>
                                        <option>YU8 - 1</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <div className="delete_btn_holder">
                        <a href="#" onClick={(e) => { e.preventDefault(); this.closeModal(true); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">Cancel</a>
                        <a href="#" onClick={(e) => { e.preventDefault(); this.handleRemove(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">Submit</a>
                    </div>
                </div>
            </Modal>
        );
    }

    editTeamModal = (modal) => {
        const { t } = this.props;
        const { teamName, division } = this.state;

        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={this.toggle} className="trainermodal">
                <div className="modal-body modal-user modal-extra-body">
                    <h2>{t('general.edit') + ' ' + t('teams.singlelowerteams')}</h2>
                    <div className="add_pay_section">
                        <div className="add_details">
                            <table className="details_table_add">
                                <tbody>
                                    <tr>
                                        <td>{t('teams.teamname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'teamName'}
                                                value={teamName}
                                                onChange={(field, value) => this.onChangeEventHandler(field, value)}
                                                //hasError={errors.has('teamname')}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('generaldetail.division')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'division'}
                                                value={division}
                                                onChange={(field, value) => this.onChangeEventHandler(field, value)}
                                                //hasError={errors.has('division')}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="delete_btn_holder">
                                <a href="#" onClick={(e) => { e.preventDefault(); this.editTeam(false); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.saveTeam(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">{t('general.submit')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    editTeam = (isOpen) => {
        this.setState({ teamModal: isOpen });
    }

    saveTeam = () => {
        const { division, teamId, teamName } = this.state;
        const team = fromJS({
            id: teamId,
            teamname: teamName,
            division: division
        }).toJS();

        this.setState({ teamModal: false });

        this.props.addTeam(team, this.props.t)
            .then(t1 => this.props.getTeamHeader(teamId)
                .then(t2 => this.hideLoading()));
    }

    onChangeEventHandler = (field, value) => {
        switch (field) {
            case 'teamName':
                this.setState({ teamName: value });
                break;
            case 'division':
                this.setState({ division: value });
                break;
            default:
                break;
        }        
    }

    componentDidUpdate() {
        const { seasons, teamHeader } = this.props;
        const { initfilteredSeason, teamName, division, teamModal } = this.state;
        var currentSeason = '';

        if (seasons && seasons.length > 0 && initfilteredSeason) {            
            seasons.map((x) => {
                if (x.isCurrent)
                    currentSeason = x.seasonName;
            });
            this.setState({
                filteredSeason: currentSeason,
                initfilteredSeason: false,
            });
        }

        if (teamHeader && teamHeader.teamName != teamName && !teamModal) {
            this.setState({ teamName: teamHeader.teamName });
        }

        if (teamHeader && teamHeader.division != division && !teamModal) {
            this.setState({ division: teamHeader.division });
        }
    }

    handleOnSelectSeason = (e) => {
        const { teamId } = this.state;
        Promise.all([
            this.props.fetchTrainerOverview(teamId, e.currentTarget.id),
            this.props.getInvitedPlayers(teamId, e.currentTarget.id)])
            .then(t => this.hideLoading());
        this.setState({
            filteredSeason: e.target.outerText
        });
    }

    render() {
        const { modal, trainersGridData, playersGridData, changeTeam, seasonDropDownIsOpen,
            addPlayer, teamId, filteredSeason, teamModal } = this.state;
        const { trainerOverviewData, playerOverviewData, seasons, teamHeader, t } = this.props;

        const headCellsTrainerOverview = [
            { id: 'name', numeric: false, disablePadding: true, label: t('trainers.title') },
            { id: 'reports', numeric: true, disablePadding: false, label: t('general.reports') },
            { id: 'lastupdate', numeric: true, disablePadding: false, label: t('general.lastupdate') },
            { id: 'roles', numeric: true, disablePadding: false, label: t('general.roles') },
            { id: 'id', numeric: true, disablePadding: false, align: 'center', label: t('general.view') }];

        const headCellsPlayerOverview = [
            { id: 'name', numeric: false, disablePadding: true, label: t('players.title') },
            { id: 'reports', numeric: true, disablePadding: false, label: t('general.reports') },
            { id: 'trainers', numeric: false, disablePadding: false, label: t('trainers.title') },
            { id: 'overall', numeric: false, disablePadding: false, label: t('overview.overall') },
            { id: 'id', numeric: true, disablePadding: false, align: 'center', label: t('general.view') }];

        return (
            <div>
                <div className="shadow_container">
                    <div className="datatable_top nobordebtm">
                        <div className="datatable_top_left">
                            <div className="player_table_row">
                                <span className="backbtn"><MdChevronLeft className="backbtn" onClick={this.goBack} /></span>
                                <div className="trainer_lg_team"><img src={teamHeader && teamHeader.logoClub ? teamHeader.logoClub : teamLogo} alt="" /></div>
                                <div className="trainercode_name" style={{width:"auto"}}>{teamHeader && teamHeader.teamName}</div>
                                <a className="btn_remove" data-toggle="modal" href="#" onClick={(e) => { e.preventDefault(); this.editTeam(true); }} style={{ marginLeft: "10px" }}>
                                    <img src={editicon} style={{ filter:"none" }} />
                                </a>
                            </div>
                        </div>
                        <div className="datatable_top_right" data-children-count="1">
                            <div className="division">
                                <div className="name_divi" data-children-count="0">{t('generaldetail.division')}</div>
                                <div className="num_divi" data-children-count="0">{teamHeader && teamHeader.division}</div>
                            </div>
                            <a href="#" onClick={(e) => { e.preventDefault(); this.prepareToChange(); }} className="btn_change"><img src={soccerIcon} alt="" data-children-count="0" />{t('generaldetail.change')}</a>
                            {/*<a href="#" className="btn_remove" data-toggle="modal" onClick={(e) => { e.preventDefault(); this.prepareToRemove(); }} data-target="#delete_modal"><img src={trashwhite} alt="" /></a>*/}
                            <Dropdown isOpen={seasonDropDownIsOpen} toggle={this.toggleItem} className="dropdown-caret season-btn">
                                <DropdownToggle caret>
                                    {filteredSeason}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {seasons.map((row, index) => {
                                        //let current = '';
                                        //current = row.isCurrent ? (<img src={checklistCompleted} style={{ marginLeft: '10px' }} class="report-skill-small-thumb" />) : '';
                                        return (
                                            <DropdownItem>
                                                <div className="season-inline" id={row.id} onClick={this.handleOnSelectSeason}>
                                                    <span>{row.seasonName}{/*current*/}</span>
                                                </div>
                                            </DropdownItem>
                                        )
                                    })}
                                    {/*<DropdownItem divider /><DropdownItem><div class="season-drop-line"><span>+ Add new season</span></div></DropdownItem>*/}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <TrainerTable
                    headCells={headCellsTrainerOverview}
                    rows={trainerOverviewData ? trainerOverviewData : trainersGridData}
                    handlePopulateUser={this.handlePopulateUser}
                    toggle={this.toggle}
                    teamId={teamId}
                    t={t}
                />

                <PlayerTable
                    headCells={headCellsPlayerOverview}
                    rows={playerOverviewData ? playerOverviewData : playersGridData}
                    handlePopulateUser={this.handlePopulateUser}
                    toggle={this.toggle}
                    teamId={teamId}
                    t={t}
                />
                {this.removeModalConfirmation(modal)}
                {this.changeTeamModal(changeTeam)}
                {this.addPlayerModal(addPlayer)}
                {this.editTeamModal(teamModal)}
            </div>);
    }
}

export default connect((state) => {
    return {
        teamHeader: state.teamDetail.teamHeader,
        trainerOverviewData: state.teamDetail.teamDetails,
        playerOverviewData: state.player.playerOverview,
        routingLocation: routingLocationSelector(state),
        allTeams: state.masterData.allTeams,
        seasons: state.masterData.seasons,
        availablePlayer: state.manager.availablePlayerResult,
        availableTrainer: state.manager.availableTrainerResult
    }
},
    dispatch => bindActionCreators(
        {
            getTeams, getTeamHeader, fetchTrainerOverview, getInvitedPlayers, removeTeamPlayers, removeTeamTrainers,
            changeTeamPlayers, getSeason, getAvailablePlayersOrTrainers, addPlayersTrainers,
            addTeam
        }
        , dispatch))(withTranslation()(TeamDetail));
