import React, { Component } from 'react';
import Source from './images/loading.gif';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }
    render() {
        if (this.state.loading)
            return (<div
                className="loading"
                style={{
                    visibility: "hidden",
                    backgroundColor: "#9b9b9b0d",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 998
                }}>
                <img
                    src={Source}
                    style={{
                        position: "absolute",
                        top: "calc(50% - 4em)",
                        left: "calc(50% - 4em)",
                        width: "8em",
                        height: "8em",
                        zIndex: 999
                    }}>
                </img>
            </div>);
        else
            return null;
    }
}

export default Loading;