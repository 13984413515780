import { getAllTeams, webAppUrl, tokenKey } from '../constants';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (clubSeasonId) => async (dispatch) => {

    const result = await fetchWithBearerToken(`${webAppUrl}/api/MasterData/GetTeams?clubSeasonId=${clubSeasonId}`, 'GET');

    if (result.ok) {
        const resultData = await result.json()
        dispatch({ type: getAllTeams, allTeams: resultData });
    }
};