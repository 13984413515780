import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nlResource from './translations/nl.json';
import enResource from './translations/en.json';

//https://github.com/i18next/react-i18next

const allowedLanguages = ['en', 'nl'];

const defaultLng = 'en'; //change this to use a different language!
let lng = defaultLng;

const storageLanguage = localStorage.getItem('language');
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
    lng = storageLanguage;
}

i18n
    .use(initReactI18next)
    .init({
        lng,
        debug: true,
        interpolation: {
            escapeValue: false
        },
        resources: {
            nl: { translation: nlResource },
            en: { translation: enResource },
        },
    });

export default i18n;
