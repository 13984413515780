import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginLogo from '../../images/logo.svg';
import './ForgotPassword.css';
import { useTranslation } from 'react-i18next';
import forgotPasswordAction from '../../../../src/store/users/actions/forgotPassword';
import checkList from '../../../components/images/Checklist_Small.png';
import { toast } from 'react-toastify';

const ForgotPassword = (props) => {
    const { forgotPassword, resetstatus } = props;
    const [email, setEmail] = useState('');
    const [emailHide, setEmailHide] = useState('');
    const { t } = useTranslation();

    const handleEmailChange = e => {
        setEmail(e.target.value);
        var a = e.target.value.split("@");
        var b = a[0];
        var newstr = "";
        for (var i in b) {
            if (i > 0 && i < b.length - 1) newstr += "*";
            else newstr += b[i];
        }
        setEmailHide(newstr + "@" + a[1]);
    }

    const handleSubmit = (e) => {
        const entity = { email };
        if (email == '') {
            alert('no e-mail set');
            return
        }

        if (validEmail(email)) {
            forgotPassword(entity);
        }
        else {
            toast.success("The email-address is not valid", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        e.preventDefault();
    }

    const validEmail = (stringEmail) => {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(stringEmail);
    }

    const handleBackToLogin = (e) => {
        props.history.push('/login')
        e.preventDefault();
    }

    return (
        <span>
            {!resetstatus &&
                <div className="login_container">
                    <div className="login_left login_background_image">
                        <img src={LoginLogo} />
                    </div>
                    <div className="login_right">
                        <div className="login_box">
                            <h1 className="h1-margin-top">{t('forgotpassword.forgotPasswordTitle')}</h1>
                            <p className="p-margin-bottom">{t('forgotpassword.forgotPasswordDesc')}</p>
                            <div className="loginform_holder">
                                <form>
                                    <div className="form_field">
                                        <input type="text" placeholder={t('general.e-mail')} value={email} onChange={handleEmailChange} className="email_fld" />
                                    </div>
                                    <input type="button" onClick={handleSubmit} value={t('general.submit')} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {resetstatus &&
                <div className="login_container">
                    <div className="login_left login_background_image">
                        <img src={LoginLogo} />
                    </div>
                    <div className="login_right">
                        <div className="login_box text-center">
                            <div className="chklist_round">
                                <img src={checkList} alt="" />
                            </div>
                            <h3>{t('forgotpassworddone.title')}</h3>
                            <p>{t('forgotpassworddone.done').replace("{0}", emailHide)}</p>
                            <div className="loginform_holder">
                                <form>
                                    <input type="button" onClick={handleBackToLogin} value={t('forgotpassworddone.backtologin')} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </ span>
    );
}

export default connect(
    (state) => {
        return {
            resetstatus: state.users.resetPasswordResult,
        }
    },
    dispatch => bindActionCreators({
        forgotPassword: forgotPasswordAction
    }, dispatch),
)(ForgotPassword);
