import React, { Component } from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { loggedInUserSelector } from '../store/users/selectors/authSelector';
import { connect } from 'react-redux';
import { tokenKey } from '../store/constants';

class ProtectedRoute extends Component {
    render() {
        const {
            component: Component,
            loggedInUser,
            ...props
        } = this.props;

        const loginPageURL = '/login';
        const bearerToken = sessionStorage.getItem(tokenKey);
        let isAllowed = bearerToken !== null && bearerToken !== undefined;

        if (!isAllowed) window.location.href = `/login`;
        
        return (
            <Route
                {...props}
                render={(props) => {
                    return (
                        isAllowed ?
                            <Component {...props} /> :
                            <Redirect to={loginPageURL} />
                    );
                }}
            />
        )
    }
}

export default connect(
    (state) => {
        return {
            loggedInUser: loggedInUserSelector(state),
        }
    },
    null,
)(ProtectedRoute);