import { tokenKey, webAppUrl, addPlayer, getPlayerOverview } from '../../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default (isActive, clubSeasonId) => async (dispatch) => {
    
    const response = await fetchWithBearerToken(isActive ? `${webAppUrl}/api/Player/GetPlayerOverviewReportByClubId?${clubSeasonId !== null ? (clubSeasonId !== undefined ? "clubSeasonId=" + clubSeasonId : '') : ''}` :
        `${webAppUrl}/api/Player/GetUserManagerOverviewByClubId?isActive=${isActive}`);

    if (response.ok) {
        const players = await response.json();
        
        if (response.status === 204) {
            dispatch({
                type: getPlayerOverview,
                players: []
            });
        }
        else {
            dispatch({
                type: getPlayerOverview,
                players
            });
        }
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};