import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from '@material-ui/core';
import defaultteam from '../images/Empty_logo_Big.png';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import trashwhite from '../images/Trash.svg';
import roundplus from '../images/round_plus_icon.svg';
import { MdChevronRight } from "react-icons/md";
import { FaCaretUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { fromJS } from 'immutable';
import ProfilePic from '../SharedComponents/ProfileThumImage';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <label className="chkholder">
                        <Input type="checkbox"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                        <span className="checkmark"></span>
                    </label>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.id === 'totalPlayer' ? 'center' :headCell.align ?? 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label != 'View' &&
                            < TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={FaCaretUp}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                        {
                            headCell.label == 'View' &&
                            <span>{headCell.label}</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, rows, seasonDropDownIsOpen, toggleItem, seasons, isActiveRemove, filteredSeason, handleOnSelectSeason, t } = props;

    return (
        <div className="datatable_top">
            <div className="datatable_top_left">{t('general.all') + ' ' + t('teams.lowerteams')}<div className="badge badge-danger badge-danger-extend">{rows.length}</div> </div>
            <div className="datatable_top_right  ">
                {/*<a href="#" className={isActiveRemove ? "btn_remove_active" : "btn_remove"} data-toggle="modal"*/}
                {/*    onClick={(e) => { e.preventDefault(); props.handleRemove(); }}*/}
                {/*    data-target="#delete_modal"><img src={trashwhite} alt="" /></a>*/}
                <div className="season-btn">
                    <Dropdown isOpen={seasonDropDownIsOpen} toggle={toggleItem} className="dropdown-caret ">
                        <DropdownToggle caret>
                            {filteredSeason}
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                //<DropdownItem>
                                //    <div class="season-inline" id="" onClick={handleOnSelectSeason}><span>All season</span></div>
                                //</DropdownItem>
                            }
                            {seasons.map((row, index) => {
                                //let current = '';
                                //current = row.isCurrent ? (<img src={checklistCompleted} style={{ marginLeft: '10px' }} class="report-skill-small-thumb" />) : '';
                                return (
                                    <DropdownItem >
                                        <div className="season-inline" id={row.id} onClick={handleOnSelectSeason}>
                                            <span>{row.seasonName}{/*current*/}</span>
                                        </div>
                                    </DropdownItem>)
                            })}
                            {/*<DropdownItem divider /><DropdownItem><div class="season-drop-line"><span>+ Add new season</span></div></DropdownItem>*/}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <a href="#"
                    onClick={(e) => { e.preventDefault(); props.toggle(); }}
                    className="btn_adnew"
                    data-toggle="modal"
                    data-target="#trainer_modal"><img src={roundplus} alt="" /></a>
            </div>
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    buttonInvitation: {

        background: 'grey',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 3px 5px 2px ',
        color: 'white',
        height: 40,
        padding: '0 5px',
        textTransform: 'none'
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('teamName');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const { rows, handleRemoveManagers, seasonDropDownIsOpen, toggleItem, seasons, getTrainers
        , handleOnSelectSeasonFilterd, filteredSeason, handleRemoveTeams, t } = props;
    const [activeRemove, setActiveRemove] = React.useState(false);

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            setActiveRemove(true);
            return;
        }
        setSelected([]);
        setActiveRemove(false);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setActiveRemove(newSelected.length > 0 ? true : false);
    };

    const handleInvite = () => { console.log(selected); alert('inviting') };

    const handleChangePage = (event, newPage) => {

        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleRemove = (e) => {
        var users = fromJS([]);
        rows.map((row, index) => {
            if (isSelected(row.id))
                users = users.push(fromJS({
                    "Id": row.id
                }));
        });
        handleRemoveTeams(users);
    }

    const handleOnSelectSeason = (e) => {
        handleOnSelectSeasonFilterd(e.currentTarget.id, e.target.outerText);
    };

    return (
        <div className="shadow_container">
            <EnhancedTableToolbar numSelected={selected.length} toggle={props.toggle}
                manageroverviewstate={props.manageroverviewstate}
                rows={props.rows}
                handleRemove={handleRemove}
                seasonDropDownIsOpen={seasonDropDownIsOpen}
                toggleItem={toggleItem}
                seasons={seasons}
                getTrainers={getTrainers}
                isActiveRemove={activeRemove}
                filteredSeason={filteredSeason}
                handleOnSelectSeason={handleOnSelectSeason}
                t={t}
            />
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={props.headCells}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (<TableRow hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.name}>
                                <TableCell>
                                    <label className="chkholder">
                                        <Input type="checkbox"
                                            onChange={(event) => handleClick(event, row.id)}
                                            checked={isItemSelected} />
                                        <span className="checkmark"></span>
                                    </label>
                                </TableCell>

                                <TableCell id={labelId}>
                                    <div className="row-left-middle">
                                        <img src={row.logoClub ? row.logoClub : defaultteam} alt="" />
                                        <div>{row.teamName}</div>
                                    </div>
                                </TableCell>

                                {row.totalTrainer > 1 && (<TableCell align="left" className="cell_20">
                                    <div className="row-middle">
                                        <div className="multiple-trainer_lg">
                                            <ProfilePic
                                                urlDetail={'/trainers/detail-trainer?userId=' + row.topTrainerId}
                                                imgUrl={row.topTrainerPicture} />
                                            <div className="number-circle"><label className="label-circle-round">+{row.totalTrainer - 1}</label></div>
                                        </div>
                                        <div>{row.totalTrainer} Trainers</div>
                                    </div>
                                </TableCell>)}

                                {row.totalTrainer <= 1 && (<TableCell align="left" className="cell_20">
                                    <div className="row-middle">
                                        <div className="trainer_lg">
                                            <ProfilePic
                                                urlDetail={'/trainers/detail-trainer?userId=' + row.topTrainerId}
                                                imgUrl={row.topTrainerPicture} />
                                        </div>
                                        <div>{row.topTrainerName}</div>
                                    </div>
                                </TableCell>)}

                                <TableCell align="left" className="cell_20">
                                    <div className="progress_bar">
                                        <div className="progress">
                                            {parseInt(row.overallProgress) <= 100 && (<div className="progress-bar" role="progressbar" style={{ width: row.overallProgress + "%" }} aria-valuenow={row.overallProgress} aria-valuemin="0" aria-valuemax="100"><span>{row.overallProgress}%</span></div>)}
                                            {parseInt(row.overallProgress) > 100 && (<div className="progress-bar" role="progressbar" style={{ width: 100 + "%" }} aria-valuenow={row.overallProgress} aria-valuemin="0" aria-valuemax="100"><span>{100}%</span></div>)}
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell align="center" className="cell_15">{`${row.totalPlayer}`}</TableCell>

                                <TableCell align="center" className="cell_10">
                                    <Link to={'/teams/team-detail?teamId=' + row.id} className="detail-manager-link">
                                        <MdChevronRight className="detail-manager" />
                                    </Link>
                                </TableCell>
                            </TableRow>);
                        })}
                        {
                            //emptyRows > 0 && (
                            //<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                            //    <TableCell colSpan={6} />
                            //</TableRow>
                            //)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                //<TablePagination
                //rowsPerPageOptions={[5, 10, 25]}
                //component="div"
                //count={rows.length}
                //rowsPerPage={rowsPerPage}
                //page={page}
                //onChangePage={handleChangePage}
                //onChangeRowsPerPage={handleChangeRowsPerPage}
                ///>
            }
        </div>
    );
}
