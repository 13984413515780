import { webAppUrl, getTrainerActivity } from '../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (seasonId,teamIds) => async (dispatch) => {
    let body = '';
    
    if (teamIds && teamIds.length > 0) {
        body = JSON.stringify(teamIds.map(x => x.value).join(','));
    }

    const trainerActivityResult = await fetchWithBearerToken(`${webAppUrl}/api/Dashboard/GetDashboardTrainerOverview?clubSeasonId=${seasonId}`,
    'POST',body);

    if (trainerActivityResult.ok) {
        const trainerResult = await trainerActivityResult.json();

        dispatch({ type: getTrainerActivity, result: trainerResult });
    }
}