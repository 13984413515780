import { updateUserActivationState, authUrl } from '../../constants';

export default (token, isrecoverypassword, email) => async (dispatch) => {
    var url;
    if (isrecoverypassword)
        url = `${authUrl}/Account/ResetPasswordTokenValidation?email=${encodeURIComponent(email)}&resetactivatetoken=${token}`
    else
        url = `${authUrl}/Account/TokenConfirmation?email=${encodeURIComponent(email)}&activatetoken=${token}`

    const response = await fetch(url);
    const loading = document.querySelector('.loading');
    if (loading)
        loading.style.visibility = "visible";

    if (response.ok) {
        let responseJson = await response.json();
        if (typeof responseJson === 'boolean') {
            responseJson = {
                invitationValid : responseJson
            };
        }
        dispatch({ type: updateUserActivationState, userActivated: true, tokenValid: responseJson.invitationValid });
        if (responseJson.invitationValid) {
            setTimeout(() => {
                if (isrecoverypassword) {
                    window.location.href = `/changepassword?recoverytoken=` + `${token}`;
                    return;
                }
                
                if (responseJson.checkoutUrl) {
                    window.location.href = responseJson.checkoutUrl;
                    return;
                }
                
                window.location.href = `/changepassword?activatetoken=` + `${token}`;

            }, 1000);
        }
        loading.style.visibility = "hidden";
    }
    else {
        dispatch({ type: updateUserActivationState, userActivated: false, tokenValid: false });
        loading.style.visibility = "hidden";
    }
};