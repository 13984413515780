import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

import { fetchByKeyword, webAppUrl } from '../../constants';

export default (e) => async (dispatch) => {
    const url = `${webAppUrl}/api/Manager/SearchByKeyword?key=${e}`;
    
    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const searchResult = await response.json();

        dispatch({
            type: fetchByKeyword,
            result: searchResult
        });
    }
}
