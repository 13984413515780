import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { webAppUrl, getManagerById, getTrainerById } from '../../constants';

export default (userId) => async (dispatch) => {
    const url = `${webAppUrl}/api/trainer/GetTrainerByUserId?userid=${userId}`;

    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const trainerData = await response.json();
        dispatch({
            type: getTrainerById,
            trainer: trainerData
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
