import {
    addManager, getManagerOverview,
    getManagerById,
    updateManager,
    fetchByKeyword,
    getAvailablePlayers,
    getAvailableTrainers
} from '../constants';
import { Map } from 'immutable';

const initialState = {
    addManagerResult: null,
    managers: Map(),
    updatedManager: null,
    keywordResult: null,
    availablePlayerResult: [],
    availableTrainerResult: []
}
export default (state, action) => {
    state = state || initialState;
    
    if (action.type === addManager) {
        return { ...state, addManagerResult: action.result };
    }

    if (action.type === getManagerOverview) {
        return { ...state, managers: action.manager };
    }

    if (action.type === getManagerById) {
        return { ...state, manager: action.manager };
    }

    if (action.type === updateManager) {
        return { ...state, updatedManager: action.result };
    }

    if (action.type === fetchByKeyword) {
        return { ...state, keywordResult: action.result };
    }

    if (action.type === getAvailablePlayers) {
        return { ...state, availablePlayerResult: action.result };
    }

    if (action.type === getAvailableTrainers) {
        return { ...state, availableTrainerResult: action.result };
    }
    return state;
}