import React from 'react';
import { connect } from 'react-redux';

const PageNotFound = props => (
    <div>
       This page does not exist.
    </div>
);

export default connect()(PageNotFound);
