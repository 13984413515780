import { toast } from 'react-toastify';
import { webAppUrl, tokenKey } from '../../constants';
import fetchTeamOverview from '../Action/fetchTeamOverview';

export default (userManagers, t) => async (dispatch) => {
    let url = `${webAppUrl}/api/Team/RemoveTeams`;

    let response = await fetch(url, {
        method: `POST`,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        }
        , body: JSON.stringify(userManagers)
    });

    if (response.ok) {
        toast.success(t('messagessuccess.removeteam'), {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(fetchTeamOverview());
    }
    else {
        toast.success(`${t('messagesfailed.failedremove')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
