import { fromJS } from 'immutable';
import '../../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import getTrainer from '../../../store/Trainers/actions/fetchTrainerByUserId';
import { bindActionCreators } from 'redux';
import TextInputField from '../../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';
import trashwhite from '../../images/Trash.svg';
import { routingLocationSelector } from '../../../store/general/selectors/generalSelector';
import ProfilePic from '../../SharedComponents/ProfileThumImage';
import { webAppUrl } from '../../../store/constants';
import { formatDateOverviewPicker } from '../../../helpers/dateHelper';
import { toast } from 'react-toastify';
import uploadPicture from '../../../store/profilePicture/uploadPicture';
import updateManagerData from '../../../store/Manager/actions/updateManagerData';
import getHeaderDetail from '../../../store/users/actions/getHeaderDetail';
import { Modal, UncontrolledCollapse } from 'reactstrap';
import checklistCompleted from '../../images/Checklist_Small.png';
import trainerGrey from '../../images/Trainer_Grey.png';
import getTrainerDetailReport from '../../../store/Trainers/TrainerDetail/fetchTrainerDetailReport';
import "react-datepicker/dist/react-datepicker.css";
import DateInputField from '../../SharedComponents/DateInputField';
import defaultUser from '../../images/default_user.png';
import iconSearch from '../../images/ic_search.svg';
import getTeams from '../../../store/MasterData/getTeams';
import changeTeamPlayers from '../../../store/TeamDetail/Action/changeTeamPlayers';
import clearImage from '../../../store/profilePicture/clearImage';
import Linkify from 'react-linkify';
//import getSeason from '../../../store/MasterData/getSeason';

import {
    MdChevronLeft,
    MdChevronRight,
    MdKeyboardArrowDown
} from "react-icons/md";

class DetailTrainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changeTeam: false,
            modal: false,
            newTeam: '',
            fields: fromJS({
                id: '',
                firstname: '',
                lastname: '',
                nationality: '',
                birthplace: '',
                birthdate: '',
                email: '',
                telephone: '',
                address: '',
                role: '',
                datestarted: '',
                lastlogin: '',
                imgTemp: null,
                photoDate: '',
                photo: null,
                userPicture: '',
                teamName: '',
                clubName: '',
                clubLogoUrl: '',
                openReports: '',
                totalReports: '',
                teamId: '',
                sharedData: false
            }),
            expandedRow: [],
            errors: fromJS({}),
            userId: '',
            componentUpdated: false,
            displayed: false,
            filteredreport: 'completed',
            searchvalue: '',
        };
        this.goBack = this.goBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.setInvisible = this.setInvisible.bind(this);
        this.expandClick = this.expandClick.bind(this);
        this.setAlert = this.setAlert.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setTeam = this.setTeam.bind(this);
        this.handleTeamChange = this.handleTeamChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.clear = this.clear.bind(this);
    }

    hideLoading() {
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "hidden";
    }

    //toggleItem = () => {
    //    const { seasonDropDownIsOpen } = this.state;

    //    this.setState({
    //        seasonDropDownIsOpen: !seasonDropDownIsOpen
    //    });
    //}

    //handleOnSelectSeason = (e) => {
    //    const { userId } = this.state;
    //    //this.props.fetchPlayerDetailsByIdBySeason(userId, e.currentTarget.id)
    //    //    .then(t => this.hideLoading());

    //    this.setState({
    //        filteredSeason: e.target.outerText
    //    });
    //}

    handleChange(field, value) {
        const { fields } = this.state;
        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    closeModal() {
        const { changeTeam } = this.state;
        this.setState({
            changeTeam: !changeTeam
        });
    }

    componentDidMount() {
        const { routingLocation } = this.props;
        const { filteredreport } = this.state;
        this.props.getTeams();

        if (routingLocation && routingLocation.query) {

            const userId = routingLocation.query['userId'];

            if (userId) {
                Promise.all([
                    this.props.getTrainer(userId),
                    this.props.getTrainerDetailReport(userId, filteredreport, '')])
                    /*this.props.getSeason()       */             
                        .then(t => this.hideLoading());

                this.setState({
                    userId: userId,
                })
            }
        }
    }

    toggle = (e) => {
        const { changeTeam } = this.state;

        if (e == 'change') {
            this.setState({
                changeTeam: !changeTeam,
            });
        }
    }

    setTeam = (e) => {
        this.setState({ newTeam: e.target.value });
    }

    handleTeamChange = () => {
        const { dataplayer, datatrainer, newTeam } = this.state;
        const { routingLocation } = this.props;
        const teamId = routingLocation.query['teamId'];
        const userId = routingLocation.query['userId'];

        this.props.changeTeamPlayers([userId], newTeam, 'trainer', 'detail')
            .then(t => this.hideLoading());
        this.toggle('change');
    }

    changeTeamModal = (modal) => {
        const { allTeams, teamHeader } = this.props;
        const { fields } = this.state;
        return (<Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
            toggle={() => { this.toggle('change') }} className="trainermodal">
            <div className="modal-body modal-user">
                <h2>Move trainer to another team</h2>
                <table className="details_table_add adtezm">
                    <tbody>
                        <tr>
                            <td>Current Team</td>
                            <td className="dt_gap">:</td>
                            <td data-children-count="1">
                                <input type="text" name="" value={fields && fields.get('teamName')} />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="dt_gap"></td>
                            <td className="text-center">
                                <img src="assets/images/arrow_down.png" alt="" className="gap_arrow" />
                            </td>
                        </tr>
                        <tr>
                            <td>Change to</td>
                            <td className="dt_gap">:</td>
                            <td data-children-count="1">
                                <select onChange={(e) => this.setTeam(e)} className="position_select">
                                    <option key="0" value="">Select Team</option>
                                    {allTeams && allTeams.map(x => <option key={x.teamName} value={x.id}>{x.teamName}</option>)}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <div className="delete_btn_holder">
                    <a href="#" onClick={(e) => { e.preventDefault(); this.closeModal(); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">Cancel</a>
                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleTeamChange(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">Submit</a>
                </div>
            </div>
        </Modal>);
    }

    expandClick(e) {
        let id = e.currentTarget.id;
        let el = document.querySelector(`#${id}.skill-expand-arrow`);

        el.style.transformOrigin = 'center center';
        let tr = el.style.transform;

        if (tr == '')
            el.style.transform = 'rotate(90deg)';
        else
            el.style.transform = '';
    }

    componentDidUpdate(prevProps) {
        const { trainerData, profilePictureUpload, updatedManager } = this.props;
        if (updatedManager && updatedManager != prevProps.updatedManager) {
            this.props.getHeaderDetail()
                .then(t => this.hideLoading());
        }

        if (profilePictureUpload && profilePictureUpload !== prevProps.profilePictureUpload) {
            this.saveUploadedPhoto(profilePictureUpload);
        }

        if (trainerData && prevProps.trainerData != trainerData) {

            this.setState({
                fields: fromJS({
                    firstname: trainerData.firstName,
                    lastname: trainerData.lastName,
                    nationalityid: trainerData.nationalityId,
                    nationality: trainerData.nationality,
                    birthplace: trainerData.birthPlace,
                    birthdate: trainerData.birthDate,
                    email: trainerData.email,
                    telephone: trainerData.phoneNumber,
                    address: trainerData.address,
                    role: trainerData.role,
                    datestarted: trainerData.dateStarted,
                    lastlogin: trainerData.lastLogin,
                    userpicture: trainerData.userPicture,
                    teamName: trainerData.teamName,
                    clubName: trainerData.clubName,
                    openReports: trainerData.openReports,
                    totalReports: trainerData.totalReports,
                    clubLogoUrl: trainerData.clubLogoUrl,
                    teamId: trainerData.teamId,
                    sharedData: trainerData.sharedData
                }),
                componentUpdated: false,
            })
        }
    }

    formatDate = (date) => {
        if (date == null) return null;
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            minute = d.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var newDate = [day, month, year].join('-');
        var newHour = [hour, minute].join(':');
        return newDate;
    }

    saveUploadedPhoto(e) {
        this.setState({
            fields: this.state.fields.set('photo', e.id),
            errors: this.state.errors.delete('photo')
        });
    }

    goBack(e) {
        this.props.history.goBack();
        e.preventDefault();
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { userId } = this.state;
        const { t, uploadPicture } = this.props;
        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '6000000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('imgTemp', URL.createObjectURL(files[0]));

        newFields = newFields.set('photoDate', dateTag);

        this.setState({
            fields: newFields
        });

        uploadPicture(formData, dateTag, userId, t)
            .then(t => this.hideLoading());    }

    clear() {
        const i = document.querySelector('.upload-detail');
        const { t, clearImage, getTrainer } = this.props;
        const { userId } = this.state;

        clearImage(userId, t)
            .then(t => {
                i.value = '';
                getTrainer(userId);
                this.hideLoading();
            });
    }

    handleUpdate() {
        const { fields } = this.state;

        const { routingLocation } = this.props;

        const entity = fields.toJS();

        entity.id = routingLocation.query['userId'];

        //validation

        const simpleEmailRegexp = new RegExp('[^@]+@[^\.]+\..+');

        const hasValidEmail = simpleEmailRegexp.test(fields.get('email'));
        if (!hasValidEmail) {
            toast.success("invalid email format", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        //end of validation

        this.props.updateManagerData(entity)
            .then(t => this.hideLoading());
    }

    setInvisible(e) {
        const { displayed } = this.state;

        this.setState({ displayed: !displayed });
    }

    setAlert(e) {
        alert('throw');
    }

    handleDateChange = (field, d) => {

        this.setState({
            fields: this.state.fields.set(field, d)
        });
    }

    handleFilter(filtered) {
        const { userId } = this.state;

        this.setState({
            filteredreport: filtered,
            searchvalue: '',
        });

        this.props.getTrainerDetailReport(userId, filtered, '')
            .then(t => this.hideLoading());
    }

    handleSearch() {
        const { searchvalue, userId } = this.state;
        this.props.getTrainerDetailReport(userId, '', searchvalue)
            .then(t => this.hideLoading());
    }

    handleSearchChange(e) {
        this.setState({
            searchvalue: e.currentTarget.value
        });
    }

    onKeyUp(event) {
        const { searchvalue, userId } = this.state;
        if (event.charCode === 13)
            this.props.getTrainerDetailReport(userId, '', searchvalue)
                .then(t => this.hideLoading());
    }

    render() {
        const { trainerReportDetailData, t } = this.props;
        const { fields, changeTeam, filteredreport, searchvalue } = this.state;

        const completed = trainerReportDetailData == null ? null : trainerReportDetailData.filter(f => f.isCompleted);
        const unCompleted = trainerReportDetailData == null ? null : trainerReportDetailData.filter(f => !f.isCompleted);
        const hasPicture = fields.get('imgTemp') == null || fields.get('imgTemp') === undefined ? false : true;
        const sharedData = fields.get('sharedData');
        if (completed) {
            completed.forEach((report,index) => {                                
                if (report) {
                    report.self.forEach((reportDetail, index) => {
                        reportDetail.hasFeedback = reportDetail.skillReports.find(skill => skill.comment != null && skill.comment != '') ? true : false;
                    });
                }
            });
        }
        if (unCompleted) {
            unCompleted.forEach((report, index) => {
                report.hasFeedback = report.skillReports.find(skill => skill.comment != null && skill.comment != '') ? true : false;
            });
        }
        return (
            <div>
                <div className="backto">
                    <a href="#" onClick={this.goBack}><MdChevronLeft className="backbtn" /></a>
                </div>
                <div className="shadow_container">
                    <div className="details_top">
                        {/*<div className="remove_details_pg">*/}
                        {/*    <a href="#" onClick={(e) => { e.preventDefault(); }} className="btn_remove" data-toggle="modal" data-target="#delete_modal"><img src={trashwhite} alt="" /></a>*/}
                        {/*</div>*/}
                        <div className="add_pic_section">
                            <div className="upload_btn">
                                <input type="file" onChange={this.uploadFileChange} className="upload-detail" />
                                {hasPicture && <ProfilePic imgUrl={fields.get('imgTemp')} alt="" cls="img-user-detail" />}
                                {!hasPicture && <ProfilePic cls='img-user-detail' imgUrl={fields.get('userpicture')} />}
                                <img src={`${webAppUrl}/hover_change_picture.png`} alt="" className="img-user-detail-hover rounded-circle" />
                                <img src={`${webAppUrl}/close_round.png`} alt="" className="remove-image" onClick={this.clear} />
                            </div>
                        </div>
                        <div className="details_box">
                            <div className="details_name">{fields.get('firstname') + ' ' + fields.get('lastname')}</div>
                            <div className="details_bottom">
                                <div className="row">
                                    <div className="col-md-5">
                                        <table className="trains_detab">
                                            <tbody>
                                                <tr>
                                                    <td>{t('general.open') + ' ' + t('general.lowerreports')}</td>
                                                    <td className="dt_gap">:</td>
                                                    <td className="right_cntb">{`${fields.get('openReports')}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t('overview.completed') + ' ' + t('general.lowerreports')}</td>
                                                    <td className="dt_gap">:</td>
                                                    <td className="right_cntb">{fields.get('totalReports')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom_details_holder">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="container-left">
                                <table className="details_table_left">
                                    <tbody>
                                        <tr>
                                            <td>{t('teams.singletitle')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField
                                                    disabled={true} field={'teamName'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('teamName')}
                                                    extraClassName={'disabled-field-bg'}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('general.role')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField
                                                    disabled={true} field={'positionName'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('role')}
                                                    extraClassName={'disabled-field-bg'}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('general.firstname')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField disabled={false} field={'firstname'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('firstname')} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('general.lastname')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField disabled={false} field={'lastname'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('lastname')} />
                                            </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Nationality</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField disabled={true} field={'nationality'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('nationality')} />
                                            </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Birth place</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={false} field={'birthplace'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('birthplace')} /></td>
                                        </tr>
                                        <tr>
                                            <td>{t('generaldetail.birthdate')}</td>
                                            <td className="dt_gap"></td>

                                            <td>
                                                <DateInputField
                                                    field="birthdate"
                                                    value={formatDateOverviewPicker(fields.get('birthdate')) ? new Date(formatDateOverviewPicker(fields.get('birthdate'))) : ''}

                                                    onChange={(field, date) => this.handleDateChange(field, new Date(date))}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('general.e-mail')}</td>
                                            <td className="dt_gap"></td>
                                            <td>
                                                <TextInputField disabled={true} field={'email'} onChange={(field, value) => this.handleChange(field, value)} 
                                                    value={fields.get('email')}
                                                    extraClassName={'disabled-field-bg'}
                                                />
                                            </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Telephone</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={false} field={'telephone'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('telephone')} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Address</td>
                                            <td className="dt_gap"></td>
                                            <td><TextInputField disabled={true} field={'address'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('address')} /></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                {sharedData ?
                                                    <div className="checkbox">
                                                        <input id="sharing_data" type="checkbox" checked={true} disabled="true" />
                                                        <label for="sharing_data" />
                                                        <div className="vertical-center left50" style={{ fontFamily: "Avenir LT Std" }}>
                                                            Share my data for scouting purposes
                                                    </div>
                                                    </div> :
                                                    <div className="checkbox">
                                                        <input id="sharing_data" type="checkbox" checked={false} disabled="true" />
                                                        <label for="sharing_data" />
                                                        <div className="vertical-center left50" style={{ fontFamily: "Avenir LT Std" }}>
                                                            Share my data for scouting purposes
                                                        </div>
                                                    </div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div style={{ margin: "auto", width: "140px" }}>
                                                    <a className="btn_update" href="#" onClick={(e) => { e.preventDefault(); this.handleUpdate(); }} style={{ float: "none" }}>{t('general.update')}</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="container-right">
                                <div className="toolbar">
                                    {t('general.reports')}
                                    <div className="badge badge-danger badge-danger-extend">
                                        {filteredreport == 'completed' ? (completed ? completed.reduce((a, b) => a + b.self.length, 0) : 0) :
                                            (unCompleted ? unCompleted.length : 0)}
                                    </div>
                                    <span style={{margin:"auto"}}/>
                                    <div className="search_payer_tr">
                                        <input type="text" value={searchvalue}
                                            onChange={(e) => { this.handleSearchChange(e); }}
                                            onKeyPress={this.onKeyUp}
                                            placeholder={t('general.searchplayers')} />
                                        <button type="submit" onClick={(e) => { e.preventDefault(); this.handleSearch(); }}><img src={iconSearch} alt="" /></button>
                                    </div>
                                    <div className={filteredreport == 'open' ? 'btn_open btn_grp_tr_active' : 'btn_open btn_grp_tr'}><a href="#" onClick={(e) => { e.preventDefault(); this.handleFilter('open'); }}>{t('general.open')}</a></div>
                                    <div className={filteredreport == 'completed' ? 'btn_completetd btn_grp_tr_active' : 'btn_completetd btn_grp_tr'}><a href="#" onClick={(e) => { e.preventDefault(); this.handleFilter('completed'); }}>{t('overview.completed')}</a></div>
                                </div>
                                <div className="accordion-box">
                                {filteredreport == 'completed' ?
                                    completed !== null && completed.map((reportData) =>
                                        <div className="box">
                                            <div className={reportData.isActive ? "header" : "header history_header"}>
                                                <div className="header-content">
                                                    <div className="accro_hd">
                                                        <div className="accro_img">
                                                            <ProfilePic imgUrl={reportData.userPicture ? reportData.userPicture : defaultUser} size={50} urlDetail={'/players/detail-player?userId=' + reportData.userId} />
                                                        </div>
                                                        <div className="accro_details accro_details-trainers" id={`toggler-${reportData.reportId}`} onClick={this.expandClick}>
                                                            {reportData.playerName} {reportData.isActive ? "" : "| " + reportData.teamName}<br />
                                                            <div className="std">Average score</div>
                                                        </div>
                                                        <div className="completed-side-report score" id={`toggler-${reportData.reportId}`} onClick={this.expandClick}>{reportData.totalScore.toPrecision(2)}</div>
                                                    </div>
                                                    {(<MdChevronRight className="skill-expand-arrow" id={`toggler-${reportData.reportId}`} onClick={this.expandClick}/>)}
                                                </div>
                                            </div>
                                            <div className="body-content">
                                                <div className="accordion-content" style={{ display: 'block' }}>
                                                    <UncontrolledCollapse toggler={`#toggler-${reportData.reportId}`}>
                                                        {reportData.self.map((obj) =>
                                                            <div className="accrd_detils_repet">
                                                                <table className="table-report-detail">
                                                                    <tr className="table-row-border-bottom">
                                                                        <td>
                                                                            <div className="accro_hd" style={{ margin: "20px 0" }}>
                                                                                <div className="accro_img">
                                                                                    <ProfilePic imgUrl={obj.userPicture ? obj.userPicture : defaultUser} size={50} urlDetail={'/players/detail-player?userId=' + obj.userId} />
                                                                                </div>
                                                                                <div className="accro_details accro_details-trainers">
                                                                                    {obj.playerName}<br />
                                                                                    <div className="std">Average score</div>
                                                                                </div>
                                                                                <div className="completed-side-report score std-b-51">
                                                                                    {obj.averageScore.toPrecision(2)}
                                                                                </div>
                                                                            </div>
                                                                            <div className="report-skill-header-container">
                                                                                <div className="report-skill-column-border-left">
                                                                                    <img src={trainerGrey} className="report-skill-small-thumb" style={{ marginRight: "15px" }} />
                                                                                    <div>
                                                                                        {t('general.reportstarted')}<br />
                                                                                        <div className="std-b-51">{obj.createdDate}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <span className="report-skill-header-divider" />
                                                                                <div className="report-skill-column-border-right">
                                                                                    <img src={checklistCompleted} className="report-skill-small-thumb" style={{ marginRight: "20px" }} />
                                                                                    <div>
                                                                                        {t('general.reportcompleted')}<br />
                                                                                        <div className="std-b-51">{obj.modifiedDate}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div style={{ marginTop: "20px" }}>
                                                                                {
                                                                                    obj.skillReports.map((sr,index, report) =>
                                                                                (<div>
                                                                                    <div className="accro_de">
                                                                                        <div className="rat_name">{sr.skillName}</div>
                                                                                        <div className="rat_number">
                                                                                            <span>{sr.score}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                        <div className={obj.hasFeedback ? "accro_comment" : "accro_comment no_display"} >
                                                                                        <h3>{t('general.feedback')}</h3>
                                                                                        <Linkify properties={{ target: '_blank', style: { color: '#16a3fc' } }}>{sr.comment}</Linkify>
                                                                                    </div>
                                                                                    </div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div style={{ background: "white", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", padding: "1px" }}>
                                                                    <div className="accro_feedback">
                                                                        <h3>{t('general.notes')}</h3>                                                                        
                                                                        <Linkify properties={{ target: '_blank', style: { color: '#16a3fc' } }}>{obj.feedback}</Linkify>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </UncontrolledCollapse>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                    unCompleted !== null && unCompleted.map((reportData) =>
                                        <div className="drawer" >
                                            <div className="accordion-item accordion-item-active">
                                                <div className="accordion-header accordion-header-active">
                                                    <div className="accrodian_header accordion-content">
                                                        <div className="accro_hd">
                                                            <div className="accro_img">
                                                                <ProfilePic imgUrl={`${reportData.userPicture ? reportData.userPicture : defaultUser}`} size={50} />
                                                            </div>
                                                            <div className="accro_details">
                                                                <div className="accro_name">{reportData.playerName}</div>
                                                                <div className="accro_progress_table">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{t('general.progress')}</td>
                                                                                <td className="gap_dot">:</td>
                                                                                <td>
                                                                                    <div style={{ display: 'inline' }}>
                                                                                        <div className="progress_bar_full" onClick={this.expandClick} id={`toggler-${reportData.reportId}`}>
                                                                                            <div className="progress">
                                                                                                <div className="progress-bar" role="progressbar" style={{ width: reportData.totalProgress + '%' }} aria-valuenow={reportData.totalProgress} aria-valuemin="0" aria-valuemax="100">
                                                                                                    <span>{reportData.totalProgress + '%'}</span>
                                                                                                </div>
                                                                                            </div>                                                                                            
                                                                                        </div>
                                                                                        <div style={{ display: 'inline' }}>                                                                                            
                                                                                            {(<MdChevronRight className="skill-expand-arrow" onClick={this.expandClick} id={`toggler-${reportData.reportId}`}/>)}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{t('general.status')}</td>
                                                                                <td className="gap_dot">:</td>
                                                                                <td>
                                                                                    <div className="status"><img src="assets/images/dot.png" alt="" /> {t('general.update')}</div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-header-icon accordion-header-icon-active">
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </div>
                                                <div className="accordion-content-wrapper">
                                                    <div className="accordion-content" style={{ display: 'block' }}>
                                                        <UncontrolledCollapse toggler={`#toggler-${reportData.reportId}`}>
                                                            <div className="accrd_detils_repet">
                                                                <div className="accrod_date" style={{ marginLeft: "23px" }}>{reportData.createdDate}
                                                                </div>
                                                                {
                                                                    reportData.skillReports.map(sr =>
                                                                        (<div className="accro_de" style={{ marginLeft: "23px", marginRight: "23px" }}>
                                                                            <div className="rat_name">{sr.skillName}</div>
                                                                            <div className="rat_number"><span>{sr.score}</span></div>
                                                                        </div>)
                                                                    )
                                                                }
                                                                <div className="accro_feedback">
                                                                    <h3>{t('general.feedback')}</h3>
                                                                    <p>{reportData.feedback}</p>
                                                                </div>
                                                            </div>
                                                        </UncontrolledCollapse>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.changeTeamModal(changeTeam)}
            </div>
        );
    }
}

export default connect((state) => {
    return {
        trainerData: state.trainer.trainer,
        updatedManager: state.manager.updatedManager,
        routingLocation: routingLocationSelector(state),
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        trainerReportDetailData: state.trainer.trainerReportDetailData,
        allTeams: state.masterData.allTeams
    }
},
    dispatch => bindActionCreators({
        getTrainer,
        uploadPicture,
        clearImage,
        updateManagerData,
        getHeaderDetail,
        getTrainerDetailReport,
        getTeams,
        changeTeamPlayers
    }, dispatch))(withTranslation()(DetailTrainer));