import { authUrl, addPlayer } from '../../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default (entity, t) => async (dispatch) => {

    const body = JSON.stringify({
        "email": entity.email,
        "role": "Player",
        "phoneNumber": entity.phoneNumber,
        "firstName": entity.firstName,
        "lastName": entity.lastName,
        "profilePicture": entity.profilePicture,
        "primaryPosition": "BE6277D2-B896-4BD6-BB94-BAB085DF34A3",
        "teamId": entity.teamId,
        "photo": entity.photo
    });

    const result = await fetchWithBearerToken(`${authUrl}/Account/RegisterUser`, 'POST', body);

    if (result.ok) {
        var response = await result.json();
        if (!response.isSucceeded)
            toast.error(`${t('messagesfailed.addplayer')} ${response.errorMessage}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        
        dispatch({ type: addPlayer, result: new Date().toString() });
    }
    else {
        var response = await result.json();
        toast.error(`${t('messagesfailed.addplayer')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });

    }
};