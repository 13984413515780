export const authUrl =  "https://auth.proseason.nl";

export const webAppUrl = "https://app.proseason.nl";

export const fetchUsersType = 'FETCH_USERS';

export const receiveUsersType = 'RECEIVE_USERS';

export const tokenKey = 'pro_season_bearer_token';

export const userId = 'pro_season_user_id';

export const userRole = 'pro_season_user_role';

export const userEmail = 'pro_season_user_email';

export const updateLoggedInUser = 'UPDATE_LOGGED_IN_USER';

export const addManager = "ADD_MANAGER";

export const updateUserActivationState = 'UPDATE_USER_ACTIVATION_STATE';

export const updatePasswordToken = 'UPDATE_PASSWORD_FROM_TOKEN';

export const addPlayer = "ADD_PLAYER";

export const addTrainer = "ADD_TRAINER";

export const getAllTeams = "GET_ALL_TEAM";

export const getManagerOverview = "GET_MANAGER_OVERVIEW";

export const getTermsAndPrivacy = 'GET_TERMS_AND_PRIVACY';

export const getPlayerOverview = "GET_PLAYER_OVERVIEW";

export const getTrainerOverview = "GET_TRAINER_OVERVIEW";

export const clearTrainerDataType = "CLEAR_TRAINER_DATA";

export const getPlayerOverviewReport = "GET_PLAYER_OVERVIEW_REPORT";

export const uploadProfilePicture = "UPLOAD_PROFILE_PICTURE";

export const clearAddPlayerData = "CLEAR_ADD_PLAYER_DATA";

export const getHeaderDetail = "GET_HEADER_USER_DETAIL";

export const getManagerById = "GET_MANAGER_BY_ID";

export const fetchTeamOverview = "FETCH_TEAM_OVERVIEW";

export const fetchTeamTrainerOverview = "FETCH_TEAM_TRAINER_OVERVIEW";

export const updateManager = "UPDATE_MANAGER";

export const fetchTrainerDetail = "FETCH_TRAINER_DETAIL";

export const fetchPlayerDetails = "FETCH_PLAYER_DETAILS";

export const fetchClubSeason = "FETCH_CLUB_SEASON";

export const getTrainerById = "GET_TRAINER_BY_ID";

export const updatePlayer = "UPDATE_PLAYER";

export const fetchByKeyword = "FETCH_BY_KEYWORD";

export const getAvailablePlayers = "GET_AVAILABLE_PLAYERS";

export const getAvailableTrainers = "GET_AVAILABLE_TRAINERS";

export const getTeamDetail = "GET_TEAM_DETAIL";

export const addPlayerTrainers = "ADD_PLAYER_TRAINER";

export const getAllPositions = "GET_ALL_POSITIONS";

export const getClubDetails = "GET_CLUB_DETAILS";

export const addSeason = "ADD_SEASON";

export const resetPassword = "RESET_PASSWORD";

export const getPlayerDevelopmentDashboard = "GET_PLAYER_DEVELOPMENT_DASHBOARD";

export const getClubHeader = "GET_CLUB_HEADER";

export const getTotalHeader = "GET_TOTAL_HEADER";

export const getTrainerActivity = "GET_TRAINER_ACTIVITY";

export const fetchTeamOverviewDashboard = "GET_TEAM_OVERVIEW_DASHBOARD";

export const fetchMainDashboard = "GET_MAIN_DASHBOARD";