import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from '@material-ui/core';

import { lighten, makeStyles } from '@material-ui/core/styles';
import { Input } from 'reactstrap';
import roundplus from '../images/round_plus_icon.svg';
import { MdChevronRight } from "react-icons/md";
import { FaCaretUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { fromJS } from 'immutable';
import ProfilePic from '../SharedComponents/ProfileThumImage';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <label className="chkholder">
                        <Input type="checkbox"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                        <span className="checkmark"></span>
                    </label>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align ?? 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label != 'View' &&
                            < TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={FaCaretUp}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                        {
                            headCell.label == 'View' &&
                            <span>{headCell.label}</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, rows, toggle, t } = props;

    return (
        <div className="datatable_top">
            <div className="datatable_top_left">{t('general.all') + ' ' + t('players.lowerplayers')}<div className="badge badge-danger badge-danger-extend">{rows.length}</div> </div>
            <div className="datatable_top_right">
                <a href="#" onClick={(e) => { e.preventDefault(); toggle('add'); }} className="btn_adnew" data-toggle="modal" data-target="#trainer_modal"><img src={roundplus} alt="" />Add player</a>
            </div>
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    buttonInvitation: {

        background: 'grey',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 3px 5px 2px ',
        color: 'white',
        height: 40,
        padding: '0 5px',
        textTransform: 'none'
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const { rows, handlePopulateUser, toggle, teamId } = props;

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.teamPlayerId);
            setSelected(newSelecteds);
            handlePopulateUser(newSelecteds, true);
            return;
        }
        setSelected([]);
        handlePopulateUser(fromJS([]), true);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        handlePopulateUser(newSelected, true);
    };

    const handleChangePage = (event, newPage) => {

        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className="shadow_container">
            <EnhancedTableToolbar numSelected={selected.length} toggle={props.toggle} rows={props.rows} t={props.t} />
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table">
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={props.headCells} />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}>
                                        <TableCell padding="checkbox" className="cell_5">
                                            <label className="chkholder">
                                                <Input type="checkbox"
                                                    onChange={(event) => handleClick(event, row.id)}
                                                    checked={isItemSelected}
                                                />
                                                <span className="checkmark"></span>
                                            </label>

                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none" className="cell_40">
                                            <div className="player_table_row"><div className="player_image">
                                                <ProfilePic
                                                    urlDetail={'/players/detail-player?userId=' + row.userId + '&teamId=' + teamId}
                                                    imgUrl={row.userPicture} cls="mCS_img_loaded" />
                                            </div>
                                                <div className="player_name">{row.name}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="left" className="cell_20">
                                            <div className="progress_bar">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{ width: row.overallProgress + "%" }} aria-valuenow={row.overallProgress} aria-valuemin="0" aria-valuemax="100"><span>{row.overallProgress}%</span></div>
                                                </div>
                                            </div>
                                        </TableCell>

                                        {row.totalTrainer > 1 &&
                                            (<TableCell align="left" className="cell_15">
                                                <div className="player_table_row">
                                                    <div className="multiple-trainer_lg">
                                                    <ProfilePic
                                                        urlDetail={'/trainers/detail-trainer?userId=' + row.trainerUserId + '&teamId=' + teamId}
                                                        imgUrl={row.trainerPicture} cls="mCS_img_loaded trainer-trailing-image" />
                                                        <div className="number-circle"><label className="label-circle-round">+{(row.totalTrainer - 1)}</label></div>
                                                    </div>
                                                    <div className="player_name text-left">{`${(row.totalTrainer - 1)} Trainers`}</div>
                                                </div></TableCell>)
                                        }
                                        {row.totalTrainer < 2 && (
                                            <TableCell align="left" className="cell_15">
                                                <div className="player_table_row">
                                                    <div className="trainer_lg">
                                                        <ProfilePic
                                                            urlDetail={'/trainers/detail-trainer?userId=' + row.trainerUserId + '&teamId=' + teamId}
                                                            imgUrl={row.trainerPicture} cls="mCS_img_loaded" /></div>
                                                    <div className="player_name text-left">{row.trainerName}</div>
                                                </div></TableCell>)
                                        }

                                        <TableCell align="left" className="cell_10">
                                            <div className="cn_top_tx nopadding row-score">
                                                {
                                                    //row.overallDiff !== 0 && (
                                                    //    <span class="up_ar up_ar_left">
                                                    //        <img src={row.overallDiff > 0 ? triangleUp : triangleDown} alt="" class="mCS_img_loaded" />
                                                    //        {row.overallDiff
                                                    //        }
                                                    //    </span>)
                                                }
                                                {` ${row.averageScore}`}
                                            </div>
                                        </TableCell>
                                        <TableCell className="cell_10" align="center">
                                            <Link to={'/players/detail-player?userId=' + row.userId + '&teamId=' + teamId} className="detail-manager-link">
                                                <MdChevronRight className="detail-player" />
                                            </Link>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        {
                            //emptyRows > 0 && (
                            //<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                            //    <TableCell colSpan={6} />
                            //</TableRow>
                            //)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                //<TablePagination
                //rowsPerPageOptions={[5, 10, 25]}
                //component="div"
                //count={rows.length}
                //rowsPerPage={rowsPerPage}
                //page={page}
                //onChangePage={handleChangePage}
                //onChangeRowsPerPage={handleChangeRowsPerPage}
                ///>
            }
        </div>
    );
}
