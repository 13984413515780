import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    routingLocationSelector,
    activationStateSelector,
    validTokenStateSelector
} from '../../../src/store/general/selectors/generalSelector';
import fetchTokenValidationAction from '../../../src/store/users/actions/fetchTokenValidation';
import LoginLogo from '../images/logo.svg';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Source from '../images/loading.gif';

class TokenConfirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRecoveryPassword: false
        };
    }

    componentDidMount() {
        const { routingLocation } = this.props;

        if (routingLocation && routingLocation.query) {
            this.setState.isRecoveryPassword = this.props.routingLocation.query.resetactivatetoken ? true : false;
            const token = this.setState.isRecoveryPassword ? routingLocation.query['resetactivatetoken'] : routingLocation.query['activatetoken'];
            const email = routingLocation.query['email'];
            if (token) {
                this.props.fetchTokenValidation(token, this.setState.isRecoveryPassword, email);
            }
        }
    }

    render() {
        const { activationState, validTokenState, t } = this.props;
        if (activationState === false) {
            return (
                <span>
                    {renderActivationFailed(this.props, t)}
                </span>
            );
        }
        else if (activationState === true && validTokenState === true) {
            return (
                <span>
                    {renderActivationOnprogress(this.props, t)}
                </span>
            );
        }
        else if (activationState === true && validTokenState === false) {
            return (
                <span>
                    {renderActivationTokenNotValid(this.props, t)}
                </span>
            );
        }
        else {
            return (
                <span>
                    {renderActivationOnprogress(this.props, t)}
                </span>
            );
        }
    }
}

function renderActivationOnprogress(props, t) {
    return (
        <div>
            <div
                style={{
                    backgroundColor: "#9b9b9b0d",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 998
                }}>
                <img
                    src={Source}
                    style={{
                        position: "absolute",
                        top: "calc(50% - 4em)",
                        left: "calc(50% - 4em)",
                        width: "8em",
                        height: "8em",
                        zIndex: 999
                    }}>
                </img>
            </div>
            <div className="login_container">
                <div className="login_left login_background_image">
                    <img src={LoginLogo} />
                </div>
                <div className="login_right">
                </div>
                <div style={{ backgroundColor: "#000000", padding: "20px 0", textAlign: "center", bottom: "0", position: "fixed", width: "100%" }}>
                    <a style={{ fontSize: "14px", color: "lightgrey" }}>© 2021 Pro Season b.v All Rights Reserved</a>
                </div>
            </div>
        </div>
    );
}

function renderResetPasswordTokenValidationSuccess(props, t) {
    return (
        <div className="login_container">
            <div className="login_left login_background_image">
                <img src={LoginLogo} />
            </div>
            <div className="login_right">
                <div className="login_box text-center">
                    <h3>{t('tokenvalidation.validatednl')}</h3>
                    <p>{t('tokenvalidation.validateden')}</p>
                    <hr className="style14" />
                </div>
            </div>
        </div>
    );
}

function renderActivationFailed(props, t) {
    return (
        <div className="login_container">
            <div className="login_left login_background_image">
                <img src={LoginLogo} />
            </div>
            <div className="login_right">
                <div className="login_box text-center">
                    <h3>{t('tokenvalidation.somethingwrongnl')}</h3>
                    <p>{t('tokenvalidation.somethingwrongen')}</p>
                    <hr className="style14" />
                </div>
            </div>
        </div>
    );
}

function renderActivationTokenNotValid(props, t) {
    return (
        <div className="login_container">
            <div className="login_left login_background_image">
                <img src={LoginLogo} />
            </div>
            <div className="login_right">
                <div className="validation_box">
                    <h1>De activatielink is niet meer geldig.</h1>
                    <p>Te laat met activeren? Geen probleem, vraag je clubmanager om opnieuw een
uitnodiging te versturen en je kunt binnen no-time aan de slag!</p>
                </div>
            </div>
            <div style={{ backgroundColor: "#000000", padding: "20px 0", textAlign: "center", bottom: "0", position: "fixed", width: "100%" }}>
                <a style={{ fontSize: "14px", color: "lightgrey" }}>© 2021 Pro Season b.v All Rights Reserved</a>
            </div>
        </div>
    );
}

export default connect(
    (state) => {
        return {
            routingLocation: routingLocationSelector(state),
            activationState: activationStateSelector(state),
            validTokenState: validTokenStateSelector(state),
        }
    },
    dispatch => bindActionCreators({
        fetchTokenValidation: fetchTokenValidationAction,
    }, dispatch)
)(withTranslation()(TokenConfirmation));