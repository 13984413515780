import { toast } from 'react-toastify';
import { webAppUrl } from '../../constants';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import fetchTeamOverview from '../Action/fetchTeamOverview';

export default (entity, t) => async (dispatch) => {
    const body = JSON.stringify({
        "Id": entity.id,
        "TeamName": entity.teamname,
        "Division": entity.division,
    });

    const result = await fetchWithBearerToken(`${webAppUrl}/api/team/addteam`, 'POST', body);
    if (result.ok) {
        toast.success(t('messagessuccess.saveteam'), {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(fetchTeamOverview());
    }
    else {
        toast.success(t('messagesfailed.saveteam'), {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};