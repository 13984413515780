import { webAppUrl, getAllPositions} from '../constants';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default () => async (dispatch) => {

    const result = await fetchWithBearerToken(`${webAppUrl}/api/MasterData/GetPositions`, 'GET');

    if (result.ok)
    {
        const resultData = await result.json()
        dispatch({ type: getAllPositions, result: resultData });
    }
};