import { webAppUrl, getTotalHeader } from '../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (seasonId) => async (dispatch) => {
    //player development
    const totalHeaderResult = await fetchWithBearerToken(`${webAppUrl}/api/Dashboard/GetDashboardTotalHeader?clubSeasonId=${seasonId}`);

    if (totalHeaderResult.ok) {
        const playerResult = await totalHeaderResult.json();

        dispatch({ type: getTotalHeader, result: playerResult });
    }
}