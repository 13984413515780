import { getTrainerOverview, clearTrainerDataType, fetchTrainerDetail, getTrainerById, addTrainer } from '../constants';
import { Map } from 'immutable';

const initialState = {
    //addManagerResult: null,
    trainers: Map(),
    trainer: null,
    trainerReportDetailData: null
}
export default (state, action) => {
    state = state || initialState;

    //if (action.type === addManager) {
    //    return { ...state, addManagerResult: action.result };
    //}

    if (action.type === getTrainerOverview) {
        return { ...state, trainers: action.trainer };
    }

    if (action.type === clearTrainerDataType) {
        return { ...state, trainers: Map() };
    }

    if (action.type == fetchTrainerDetail) {
        return { ...state, trainerReportDetailData: action.trainer };
    }

    if (action.type === getTrainerById) {
        
        return { ...state, trainer: action.trainer };
    }

    if (action.type === addTrainer) {
        
        return { ...state, addTrainerResult: action.result };
    }
    return state;
}