import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { webAppUrl, fetchPlayerDetails } from '../../constants';

export default (playerId, clubSeasonId) => async (dispatch) => {
    
    const url = `${webAppUrl}/api/Player/GetPlayerDetailByIdBySeason?userId=${playerId}${clubSeasonId !== null ? (clubSeasonId !== undefined ? "&&clubSeasonId=" + clubSeasonId : '') : ''}`;

    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const playerDetails = await response.json();
        dispatch({
            type: fetchPlayerDetails,
            player: playerDetails
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
