import { webAppUrl, tokenKey } from '../../constants';
import { toast } from 'react-toastify';
import fetchClubDetails from '../../../store/users/actions/fetchClubDetails';

export default (club) => async (dispatch) => {
    const url = `${webAppUrl}/api/user/UpdateClubDetails`;

    var body = JSON.stringify({
        "Id": club.clubid,
        "ClubName": club.clubname,
        "LogoUrl": club.logotemp && club.logotemp != '' ? club.logotemp : club.logourl,
        "Country": club.country
    });

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        },
        body: body
    });

    if (response.ok) {
        dispatch(fetchClubDetails());
        toast.success("Club details updated", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    else {
        toast.error("Failed to update club details", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};