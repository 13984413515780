import { webAppUrl, addSeason } from '../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default (entity,t) => async (dispatch) => {

    var sd = new Date(Date.UTC(entity.startdate.getFullYear(), entity.startdate.getMonth(), entity.startdate.getDate()));
    var ed = new Date(Date.UTC(entity.enddate.getFullYear(), entity.enddate.getMonth(), entity.enddate.getDate()));

    const body = JSON.stringify({
        "Id": entity.id,
        "SeasonName": entity.seasonname,
        "ReportFrequency": entity.reportfreq,
        "StartDate": sd,
        "EndDate": ed,
    });

    const result = await fetchWithBearerToken(`${webAppUrl}/api/MasterData/AddOrUpdateSeason`, 'POST', body);

    if (result.ok) {
        var response = await result.json();
        if (response.isSucceeded)
            toast.success(`${t('messagessuccess.saveseason')}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        else
            toast.success(`${t('messagesfailed.seasonexist')}`, {
                position: toast.POSITION.TOP_RIGHT
            });

        dispatch({ type: addSeason, result: new Date().toString() });
    }
    else {
        toast.error(`${t('messagesfailed.saveseason')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

};