import DatePicker, { registerLocale } from 'react-datepicker';
import React, { Component } from 'react';
import { FaRegCalendarAlt } from "react-icons/fa";
import nl from 'date-fns/locale/nl'

registerLocale("nl", nl);


class DateInputField extends Component {

    DateCustomInput = ({ value, onClick }) => (
        <div className="input-group">
            <input type="text"
                className={`form-control ${this.props.hasError !== undefined && this.props.hasError ? 'is-invalid' : ''} ${this.props.extraClassName}`}
                onClick={onClick} onChange={onClick} value={value} disabled={this.props.disabled} />
        </div>
    );

    render() {

        const {
            value,
            field,
            dateFormat,
            disabled,
            extraClassName,
        } = this.props;


        const extraClass = this.props.hasError !== undefined && this.props.hasError ? 'is-invalid' : '';

        return (
            <DatePicker className={`form-control ${extraClass}`}
                disabled={disabled}
                //locale="nl"
                selected={value}
                onChange={(date) => {
                    var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
                    this.props.onChange(this.props.field, utcDate);
                }}
                dateFormat="dd/MM/yyyy"                
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={<this.DateCustomInput></this.DateCustomInput>}
            />
        );
    }
}

export default (DateInputField);
