import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
// const customStyles = {
//     control: styles => ({ width: '200px', height:'50px' }),
//     dropdownIndicator: styles =>({background-image:'blue'})

// }

const brandColor = '#cccccc';
const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: '0 !important',
        '&:hover': {
            borderColor: state.isFocused ? brandColor : base.borderColor,
        },
        borderColor: state.isFocused ? brandColor : base.borderColor,
        borderRadius: '10px !important',
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) =>
        console.log(state.isFocused) || {
            ...styles,
            backgroundColor: state.isSelected ? "none" : null,
            ':active': {
                backgroundColor: state.isSelected ? 'none' : 'none',
            },
            color: state.isSelected ? '#515151' : '#515151',
        }
};

const MySelect = props => {
    if (props.allowSelectAll) {
        let selectall = props.customoptionsall && props.customoptionsall != '' ? { label: props.customoptionsall, value: "*" } : props.allOption;
        return (
            <ReactSelect
                {...props}
                options={[selectall, ...props.options]}
                classNamePrefix='react-select-drop'
                styles={customStyles}
                onChange={(selected, event) => {
                    if (selected !== null && selected.length > 0) {
                        if (selected[selected.length - 1].value === props.allOption.value) {
                            return props.onChange([props.allOption, ...props.options]);
                        }
                        let result = [];
                        if (selected.length === props.options.length) {
                            if (selected.includes(props.allOption)) {
                                result = selected.filter(
                                    option => option.value !== props.allOption.value
                                );
                            } else if (event.action === "select-option") {
                                result = [props.allOption, ...props.options];
                            }
                            return props.onChange(result);
                        }
                    }

                    return props.onChange(selected);
                }}
            />
        );
    }

    return <ReactSelect {...props} />;
};

MySelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

MySelect.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    }
};

export default MySelect;
