import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { webAppUrl, fetchTrainerDetail } from '../../constants';

export default (userId, filter, searchname) => async (dispatch) => {
    const url = `${webAppUrl}/api/trainer/GetTrainerPersonalDetail?userId=${userId}&filter=${filter}&searchname=${searchname}`;

    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const trainers = await response.json();
        dispatch({
            type: fetchTrainerDetail,
            trainer: trainers
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
