import {
    updateLoggedInUser, tokenKey, userId, userRole
} from '../constants';

const initialState = {
    loggedInUser: {
        id: sessionStorage.getItem(userId),
        accessToken: sessionStorage.getItem(tokenKey),
        role: sessionStorage.getItem(userRole)
    }
};

export default (state, action) => {
    state = state || initialState;
    if (action.type === updateLoggedInUser) {
        return {
            ...state,
            loggedInUser: action.loggedInUser,
        };
    }

    return state;
};
