import { Map } from 'immutable';
import HomeIcon from './Images/home_icon.svg';
import PlayersIcon from './Images/players_icon.svg';
import SettingsIcon from './Images/settings_icon.svg';
import TeamIcon from './Images/team_icon.svg';
import SeasonIcon from './Images/season_icon.svg';
import TrainersIcon from './Images/trainers_icon.svg';
import ManagerWhite from './Images/Manager_white.svg';
import { MANAGER } from './NavConstant';

export const sidebarUrls = Map({
    '/': Map({
        to: '/',
        label: 'overview.title',
        matchExact: true,
        iconUrl: HomeIcon,
        disabled: false,
        shouldBeLoggedIn: false,
        allowedRoles: [MANAGER]
    }),
    'Managers': Map({
        to: '/manager',
        label: 'managers.title',
        matchExact: false,
        iconUrl: ManagerWhite,
        disabled: false,
        shouldBeLoggedIn: false,
        allowedRoles: [MANAGER]
    }),
    'Seasons': Map({
        to: '/season',
        label: 'seasons.title',
        matchExact: false,
        iconUrl: SeasonIcon,
        disabled: false,
        shouldBeLoggedIn: false,
        allowedRoles: [MANAGER]
    }),
    'teams': Map({
        to: '/teams',
        label: 'teams.title',
        matchExact: false,
        iconUrl: TeamIcon,
        disabled: false,
        shouldBeLoggedIn: false,
        allowedRoles: [MANAGER]
    }),
    'trainers': Map({
        to: '/trainers',
        label: 'trainers.title',
        matchExact: false,
        iconUrl: TrainersIcon,
        disabled: false,
        shouldBeLoggedIn: false,
        allowedRoles: [MANAGER]
    }),
    'players': Map({
        to: '/players',
        label: 'players.title',
        matchExact: false,
        iconUrl: PlayersIcon,
        disabled: false,
        shouldBeLoggedIn: false,
        allowedRoles: [MANAGER]
    }),
    'settings': Map({
        to: '/general-settings',
        label: 'settings.title',
        matchExact: false,
        iconUrl: SettingsIcon,
        disabled: false,
        shouldBeLoggedIn: false,
        allowedRoles: [MANAGER]
    })
});

export const allUrls = sidebarUrls;