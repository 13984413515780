import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { Input, Modal } from 'reactstrap';
import { FaCaretUp } from 'react-icons/fa';
import { fromJS } from 'immutable';
import { makeStyles } from '@material-ui/core/styles';
import trash from '../images/Trash.svg';
import trashwhite from '../images/trash_white.svg';
import roundplus from '../images/round_plus_icon.svg';
import editicon from '../images/edit_ic.svg';
import fetchClubDetails from '../../store/users/actions/fetchClubDetails';
import updateClubDetails from '../../store/users/actions/updateClubDetails';
import uploadPicture from '../../store/profilePicture/uploadPicture';
import addSeason from '../../store/MasterData/addSeason';
import updateSeason from '../../store/MasterData/updateSeasons';
import TextInputField from '../SharedComponents/TextInputField';
import DateInputField from '../SharedComponents/DateInputField';
import { formatDateStandart } from '../../helpers/dateHelper';
import { formatDateOverviewPicker } from '../../helpers/dateHelper';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

class Season extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRendering: false,
            isVisible: false,
            order: 'asc',
            orderBy: '',
            rows: [],
            activeRemove: false,
            errorsfieldSeason: fromJS({}),
            componentUpdated: false,
            errors: fromJS({}),
            displayed: false,
            modal: false,
            allowminreport: true,
            actionseason: false,
            minReportFreq: 0,
            fieldSeason: fromJS({
                id: "00000000-0000-0000-0000-000000000000",
                seasonname: "",
                reportfreq: 1,
                startdate: "",
                enddate: "",
                amountOfCompleted: 0
            })
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSeason = this.handleChangeSeason.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent() {
        this.setState({ isVisible: true });
    }

    onClick = (eve, obj) => {
        this.setState({
            rows: this.state.rows.map(item => {
                if (typeof (obj) === 'undefined')
                    return { ...item, isSelected: eve.target.checked }
                return item.id == obj ? { ...item, isSelected: eve.target.checked } : item
            }),
            activeRemove: eve.target.checked
        });
    }
    handleRemove = (e) => {
        //TO-DO: the API is not exist yet and flow for delete season is not clear
    }
    handleRequestSort = (event, property) => {
        const { orderBy, order } = this.state;
        const isAsc = orderBy === property && order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property,
        });
    }
    handleDateChange = (field, d) => {
        this.setState({
            fieldSeason: this.state.fieldSeason.set(field, d),
        });
    }
    handleChange(field, value) {
        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }
    handleChangeSeason(field, value) {
        this.setState({
            fieldSeason: this.state.fieldSeason.set(field, value),
            errorsfieldSeason: this.state.errorsfieldSeason.delete(field),
        });
    }
    save = () => {
        const { fieldSeason, modal } = this.state;
        const { t } = this.props;

        if (fieldSeason.get('seasonname') == '' || fieldSeason.get('reportfreq') == '') {
            toast.success(t('validationerror.mandatoryfield'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            if (fieldSeason.get('startdate') > fieldSeason.get('enddate')) {
                toast.success(t('validationerror.overlapdate'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                this.props.addSeason(fieldSeason.toJS(),t)
                    .then(t => {
                        const loading = document.querySelector('.loading');
                        if (loading)
                            loading.style.visibility = "hidden";
                    });
                this.setState({
                    modal: !modal,
                    fieldSeason: fromJS({
                        id: "00000000-0000-0000-0000-000000000000",
                        seasonname: "",
                        reportfreq: "1",
                        startdate: "",
                        enddate: "",
                    }),
                });
            }
        }
    }
    addOrMin = (e) => {
        const { fieldSeason } = this.state;
        var value = parseInt(fieldSeason.get('reportfreq'));

        if (e.target.outerText == '+')
            value = value + 1;
        else
            value = value - 1;

        this.setState({
            fieldSeason: this.state.fieldSeason.set('reportfreq', value < this.state.minReportFreq ? this.state.minReportFreq : value),
        });
    }
    toggle = (e) => {
        const { modal } = this.state;
        this.setState({
            modal: !modal,
            allowminreport: true,
            fieldSeason: fromJS({
                id: "00000000-0000-0000-0000-000000000000",
                seasonname: "",
                reportfreq: 1,
                startdate: "",
                enddate: "",
            }),
            minReportFreq: 0
        });
    }
    handleEdit = (e) => {
        const { modal, rows, actionseason, allowminreport } = this.state;
        var seasonId = e.currentTarget.id;
        if (seasonId) {
            const seasonFilter = rows.filter(function (i, n) {
                return i.id === seasonId;
            });
            this.setState({
                fieldSeason: fromJS({
                    id: seasonFilter[0].id,
                    seasonname: seasonFilter[0].seasonName,
                    reportfreq: seasonFilter[0].reportFrequency,
                    startdate: formatDateOverviewPicker(seasonFilter[0].startDate),
                    enddate: formatDateOverviewPicker(seasonFilter[0].endDate),
                    amountOfCompleted: seasonFilter[0].amountOfCompleted
                }),
                actionseason: !actionseason,
                allowminreport: true,
                minReportFreq: seasonFilter[0].amountOfCompleted,
                modal: !modal
            });
        }
    }

    componentDidMount() {
        this.props.fetchClubDetails()
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
                this.hideComponent();
            });

        

        this.setState({ isRendering: true }, () => {
            this.setState({ isRendering: false });
        });
    }
    componentDidUpdate(prevProps) {
        const { clubDetails, profilePictureUpload, addSeasonResult } = this.props;
        if (clubDetails && clubDetails != prevProps.clubDetails) {
            this.setState({
                fields: fromJS({
                    clubid: clubDetails.id,
                    clubname: clubDetails.clubName,
                    country: clubDetails.country,
                    logourl: clubDetails.logoUrl,
                    clubSeasons: clubDetails.clubSeasons,
                }),
                componentUpdated: true,
                rows: clubDetails.clubSeasons.map(obj => ({ ...obj, isSelected: false }))
            })
        }

        if (addSeasonResult && prevProps.addSeasonResult !== addSeasonResult) {
            this.props.fetchClubDetails()
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
        }
    }
    render() {
        const { modal, actionseason, order, orderBy, rows, activeRemove, fieldSeason, errors, allowminreport, isVisible } = this.state;
        const { t } = this.props
        const headCells = [
            { id: 'seasonName', numeric: false, disablePadding: false, label: t('seasons.title') },
            { id: 'reportFrequency', numeric: false, disablePadding: false, label: t('general.reports') },
            { id: 'startDate', numeric: false, disablePadding: false, label: t('general.start') },
            { id: 'endDate', numeric: false, disablePadding: false, label: t('general.end') },
            { id: 'action', numeric: false, disablePadding: false, align: 'right', label: t('general.action') },
        ];

        return (
            <div>
                {isVisible && (
                    <TContent rows={rows}
                        isVisible={isVisible}
                        allowminreport={allowminreport}
                        addOrMin={this.addOrMin}
                        actionseason={actionseason}
                        errors={errors}
                        fieldSeason={fieldSeason}
                        handleChangeSeason={this.handleChangeSeason}
                        handleDateChange={this.handleDateChange}
                        handleEdit={this.handleEdit}
                        handleRemove={this.handleRemove}
                        headCells={headCells}
                        isActiveRemove={activeRemove}
                        isRendering={this.isRendering}
                        isSelected={this.isSelected}
                        modal={modal}
                        onClick={this.onClick}
                        onSort={this.handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        save={this.save}
                        toggle={this.toggle}
                        t={t} />
                )}
            </div>
        );
    }
}

function TContent(props) {
    const { rows, isVisible, handleRemove, isActiveRemove, toggle, errors, modal, handleEdit, addOrMin, handleDateChange,
        order, orderBy, onClick, onSort, headCells, fieldSeason, save, handleChangeSeason, allowminreport,
        actionseason, t } = props;
    const styles = useStyles();

    return (
        <div className="shadow_container MSeason">
                <TBar toggle={toggle}
                    rows={rows}
                    handleRemove={handleRemove}
                    isActiveRemove={isActiveRemove} t={t} />
                <TableContainer>
                    <Table aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <THead numSelected={rows.filter(f => { return f.isSelected }).length}
                            order={order}
                            orderBy={orderBy}
                            onClick={onClick}
                            onSort={onSort}
                            rowCount={rows.length}
                            headCells={headCells} />
                    {(props.isRendering) ? null : <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                            return (
                                <TableRow hover
                                    className={row.isSelected ? styles.selected : ""}
                                    role="checkbox"
                                    aria-checked={row.isSelected}
                                    tabIndex={index}
                                    key={index}>
                                    <TableCell padding="checkbox">
                                        <label className="chkholder">
                                            <Input type="checkbox"
                                                onChange={(event) => onClick(event, row.id)}
                                                checked={row.isSelected} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </TableCell>
                                    <TableCell align="left">
                                        <span className="th-settings">{row.seasonName}</span>
                                    </TableCell>
                                    <TableCell align="left">
                                        <span className="th-settings">
                                            {`${row.reportFrequency ? row.reportFrequency : 0} per player`}
                                        </span>
                                    </TableCell>
                                    <TableCell align="left">
                                        <span className="th-settings">{formatDateStandart(row.startDate)}</span>
                                    </TableCell>
                                    <TableCell align="left">
                                        <span className="th-settings">{formatDateStandart(row.endDate)}</span>
                                    </TableCell>
                                    <TableCell align="right">
                                        <a href="#" id={row.id} onClick={(e) => { e.preventDefault(); handleEdit(e); }}>
                                            <img id={row.id} src={editicon} />
                                        </a>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>}
                    </Table>
                    </TableContainer>
            <SeasonModal handleChangeSeason={handleChangeSeason} handleDateChange={handleDateChange} errors={errors}
                allowminreport={allowminreport} fieldSeason={fieldSeason} addOrMin={addOrMin} isOpen={modal}
                actionseason={actionseason} toggle={toggle} save={save} t={t} />
        </div>
    );
}

function TBar(props) {
    const { rows, handleRemove, isActiveRemove, toggle, t } = props;
    const styles = useStyles();
    return (
        <div className="datatable_top">
            <div className="datatable_top_left">
                {t('general.all') + ' ' + t('seasons.titlelower')}
                <div className="badge badge-danger badge-danger-extend">{rows.length}</div>
            </div>
            <div className="datatable_top_right">
                {/*<button className={isActiveRemove ? "button-normal on" : "button-normal"} data-toggle="modal" onClick={handleRemove}>*/}
                {/*    <img src={trash} className={isActiveRemove ? styles.off : styles.on} />*/}
                {/*    <img src={trashwhite} className={isActiveRemove ? styles.on : styles.off} />*/}
                {/*</button>*/}
                <button className="button-normal add" data-target="#trainer_modal" data-toggle="modal" onClick={toggle}>
                    <img src={roundplus} />
                </button>
            </div>
        </div >
    );
}

function THead(props) {
    const { onClick, order, orderBy, numSelected, rowCount, onSort, headCells } = props;
    const classes = useStyles();
    const sortBy = (property) => (event) => {
        onSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <label className="chkholder">
                        <Input type="checkbox"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onClick} />
                        <span className="checkmark"></span>
                    </label>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}
                        align={headCell.align ?? 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        {headCell.label != 'Action' &&
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={sortBy(headCell.id)}
                                IconComponent={FaCaretUp}>
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                        {
                            headCell.label == 'Action' &&
                            <span>{headCell.label}</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function SeasonModal(props) {
    const { fieldSeason, errors, allowminreport, actionseason, toggle, handleChangeSeason,
        handleDateChange, addOrMin, save, isOpen, t } = props;
    return (
        <Modal isOpen={isOpen} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
            toggle={toggle} className="trainermodal">
            <h2>{actionseason ? t('general.edit') : t('general.add')} {t('seasons.singlelowerseason')}</h2>
            <table className="details_table_add adtezm">
                <tbody>
                    <tr>
                        <td>{t('seasons.seasonname')}</td>
                        <td className="dt_gap">:</td>
                        <td>
                            <TextInputField
                                disabled={false}
                                field={'seasonname'}
                                value={fieldSeason.get('seasonname')}
                                onChange={(field, value) => handleChangeSeason(field, value)}
                                hasError={errors.has('seasonname')}
                                placeholder={''} />
                        </td>
                    </tr>
                    <tr>
                        <td>{t('seasons.reportperplayer')}</td>
                        <td className="dt_gap">:</td>
                        <td>
                            <div className="spi_holder">
                                <div className="input-group-settings number-spinner">
                                    <button onClick={addOrMin} className="btn add_or_min" data-dir="dwn" value='-'>-</button>
                                    <TextInputField
                                        disabled={true}
                                        field={'reportfreq'}
                                        value={fieldSeason.get('reportfreq')}
                                        onChange={(field, value) => handleChangeSeason(field, value)}
                                        hasError={errors.has('reportfreq')}
                                        extraClassName={'text-center'}
                                        placeholder={"Report Frequency"} />
                                    <button onClick={addOrMin} className="btn add_or_min" data-dir="up" value='+'>+</button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{t('general.startdate')}</td>
                        <td className="dt_gap">:</td>
                        <td>
                            <DateInputField
                                field="startdate"
                                value={fieldSeason.get('startdate')}
                                dateFormat="MMMM d, yyyy"
                                onChange={(field, date) => handleDateChange(field, date)} />
                        </td>
                    </tr>
                    <tr>
                        <td>{t('general.enddate')}</td>
                        <td className="dt_gap">:</td>
                        <td>
                            <DateInputField
                                field="enddate"
                                value={fieldSeason.get('enddate')}
                                dateFormat="MMMM d, yyyy"
                                onChange={(field, date) => handleDateChange(field, date)} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="delete_btn_holder">
                <a href="#" onClick={(e) => { e.preventDefault(); toggle(); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                <a href="#" onClick={(e) => { e.preventDefault(); save(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">{t('general.save')}</a>
            </div>
        </Modal>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    box: {
        alignItems: 'center',
        backgroundColor: '#f6f6f6',
        borderRadius: '5px',
        display: 'inline-flex',
        fontWeight: '900',
        height: '40px',
        justifyContent: 'center',
        margin: '10px 10px 10px 0',
        width: '40px'
    },
    selected: {
        backgroundColor: '#0000000a'
    },
    off: {
        display: 'none'
    },
    on:
    {
        display: 'initial'
    }
}));

export default connect((state) => {
    return {
        clubDetails: state.users.clubDetails,
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        addSeasonResult: state.users.addSeasonResult,
    }
}, dispatch => bindActionCreators({
    fetchClubDetails,
    uploadPicture,
    updateClubDetails,
    addSeason,
    updateSeason
}, dispatch))(withTranslation()(Season));