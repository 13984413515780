import { fromJS } from 'immutable';
import '../../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import TextInputField from '../../SharedComponents/TextInputField';
import DateInputField from '../../SharedComponents/DateInputField';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import imageUpload from '../../images/upload.jpg';
import fetchClubDetails from '../../../store/users/actions/fetchClubDetails';
import updateClubDetails from '../../../store/users/actions/updateClubDetails';
import uploadPicture from '../../../store/profilePicture/uploadPicture';
import { Modal } from 'reactstrap';
import addSeason from '../../../store/MasterData/addSeason';
import updateSeason from '../../../store/MasterData/updateSeasons';
import EmptyTeamsImage from '../../images/Empty_logo_Big.png';
import './GeneralSettings.css';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { formatDateOverviewPicker } from '../../../helpers/dateHelper';
import { FaCaretUp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: fromJS({
                clubid: "",
                clubname: "",
                country: "",
                logourl: "",
                logotemp: "",
                clubSeasons: fromJS([]),
            }),
            fieldSeason: fromJS({
                id: "00000000-0000-0000-0000-000000000000",
                seasonname: "",
                reportfreq: 1,
                startdate: "",
                enddate: "",
            }),
            errorsfieldSeason: fromJS({}),
            componentUpdated: false,
            errors: fromJS({}),
            displayed: false,
            modal: false,
            allowminreport: true,
            actionseason: 'Add',
            order: 'asc',
            orderBy: 'season',

        };
        this.handleChange = this.handleChange.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.setInvisible = this.setInvisible.bind(this);
        this.saveClubDetails = this.saveClubDetails.bind(this);
        this.updateSeasons = this.updateSeasons.bind(this);
        this.handleChangeSeason = this.handleChangeSeason.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
    }

    hideLoading() {
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "hidden";
    }

    toggle = (e) => {
        const { modal } = this.state;
        this.setState({
            modal: !modal,
            allowminreport: true,
            fieldSeason: fromJS({
                id: "00000000-0000-0000-0000-000000000000",
                seasonname: "",
                reportfreq: 1,
                startdate: "",
                enddate: "",
            }),
            actionseason: 'Add',
        });
    }

    handleEdit = (e) => {
        const { modal, fields } = this.state;
        var seasonId = e.currentTarget.id;
        if (seasonId) {
            const clubseason = fields.get('clubSeasons').toJS();
            const seasonFilter = clubseason.filter(function (i, n) {
                return i.id === seasonId;
            });
            this.setState({
                fieldSeason: fromJS({
                    id: seasonFilter[0].id,
                    seasonname: seasonFilter[0].seasonName,
                    reportfreq: seasonFilter[0].reportFrequency,
                    startdate: formatDateOverviewPicker(seasonFilter[0].startDate),
                    enddate: formatDateOverviewPicker(seasonFilter[0].endDate),
                }),
                actionseason: 'Edit',
            });
        }
        this.setState({
            modal: !modal,
            allowminreport: false
        });
    }

    componentDidMount() {
        this.props.fetchClubDetails()
            .then(t => this.hideLoading());
    }

    componentDidUpdate(prevProps) {
        const { clubDetails, profilePictureUpload, addSeasonResult } = this.props;
        if (clubDetails && clubDetails != prevProps.clubDetails) {
            this.setState({
                fields: fromJS({
                    clubid: clubDetails.id,
                    clubname: clubDetails.clubName,
                    country: clubDetails.country,
                    logourl: clubDetails.logoUrl,
                    clubSeasons: clubDetails.clubSeasons,
                }),
                componentUpdated: true,
            })
        }

        if (profilePictureUpload && profilePictureUpload !== prevProps.profilePictureUpload) {
            this.saveUploadedPhoto(profilePictureUpload);
        }
        if (addSeasonResult && prevProps.addSeasonResult !== addSeasonResult) {
            this.props.fetchClubDetails()
                .then(t => this.hideLoading());
        }
    }

    saveUploadedPhoto(e) {
        this.setState({
            fields: this.state.fields.set('logotemp', e.id),
            errors: this.state.errors.delete('logotemp')
        });
    }

    handleChange(field, value) {
        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    handleChangeSeason(field, value) {
        this.setState({
            fieldSeason: this.state.fieldSeason.set(field, value),
            errorsfieldSeason: this.state.errorsfieldSeason.delete(field),
        });
    }

    changeUpdateSeason(id, newvalue, field) {
        const { fields } = this.state;
        var fieldsSeason = fields.get('clubSeasons').map((row, f) => {
            var newrow = row;
            if (row.get('id') == id) {
                var newrow = row.map((value, fieldrow) => {
                    if (fieldrow == field)
                        return newvalue;
                    else
                        return value;
                });
            }
            return newrow;
        });
        this.setState({
            fields: this.state.fields.set('clubSeasons', fieldsSeason)
        });
    }

    addOrMin = (e) => {
        const { fieldSeason } = this.state;
        var value = parseInt(fieldSeason.get('reportfreq'));

        if (e.target.outerText == '+')
            value = value + 1;
        else
            value = value - 1;

        this.setState({
            fieldSeason: this.state.fieldSeason.set('reportfreq', value < 0 ? 0 : value),
        });
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { t } = this.props;
        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '6000000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('logourl', URL.createObjectURL(files[0]));

        this.setState({
            fields: newFields
        });

        this.props.uploadPicture(formData, dateTag, null, t)
            .then(t => this.hideLoading());
    }

    setInvisible(e) {
        const { displayed } = this.state;

        this.setState({ displayed: !displayed });
    }

    saveClubDetails() {
        const { fields } = this.state;
        this.props.updateClubDetails(fields.toJS())
            .then(t => this.hideLoading());
    }

    updateSeasons() {
        const { fields } = this.state;
        this.props.updateSeason(fields.get('clubSeasons').toJS())
            .then(t => this.hideLoading());
    }

    saveNewSeason() {
        const { fieldSeason, modal } = this.state;
        if (fieldSeason.get('seasonname') == '' || fieldSeason.get('reportfreq') == '') {
            toast.success("Please filled all the input", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            if (fieldSeason.get('startdate') > fieldSeason.get('enddate')) {
                toast.success("Start date can not more than end date", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                this.props.addSeason(fieldSeason.toJS())
                    .then(t => this.hideLoading());
                this.setState({
                    modal: !modal,
                    fieldSeason: fromJS({
                        id: "00000000-0000-0000-0000-000000000000",
                        seasonname: "",
                        reportfreq: "1",
                        startdate: "",
                        enddate: "",
                    }),
                });
            }
        }
    }

    handleDateChange = (field, d) => {
        this.setState({
            fieldSeason: this.state.fieldSeason.set(field, d),
        });
    }

    addSeasonModal = (modal) => {
        const { fieldSeason, errors, allowminreport, actionseason } = this.state;
        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={this.toggle} className="trainermodal">
                <h2>{actionseason} season</h2>
                <table className="details_table_add adtezm">
                    <tbody>
                        <tr>
                            <td>Season name</td>
                            <td className="dt_gap">:</td>
                            <td>
                                <TextInputField
                                    disabled={false}
                                    field={'seasonname'}
                                    value={fieldSeason.get('seasonname')}
                                    onChange={(field, value) => this.handleChangeSeason(field, value)}
                                    hasError={errors.has('seasonname')}
                                    placeholder={''}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Reports per player</td>
                            <td className="dt_gap">:</td>
                            <td>
                                <div className="spi_holder">
                                    <div className="input-group-settings number-spinner">
                                        <span className="input-group-btn">
                                            <button onClick={allowminreport ? this.addOrMin : undefined} className="btn" data-dir="dwn" value='-'>-</button>
                                        </span>
                                        <TextInputField
                                            disabled={true}
                                            field={'reportfreq'}
                                            value={fieldSeason.get('reportfreq')}
                                            onChange={(field, value) => this.handleChangeSeason(field, value)}
                                            hasError={errors.has('reportfreq')}
                                            extraClassName={'text-center'}
                                            placeholder={"Report Frequency"}
                                        />
                                        <span className="input-group-btn">
                                            <button onClick={this.addOrMin} className="btn" data-dir="up" value='+'>+</button>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Start date</td>
                            <td className="dt_gap">:</td>
                            <td>
                                <DateInputField
                                    field="startdate"
                                    value={fieldSeason.get('startdate')}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={(field, date) => this.handleDateChange(field, date)}
                                />

                            </td>
                        </tr>
                        <tr>
                            <td>End date</td>
                            <td className="dt_gap">:</td>
                            <td>
                                <DateInputField
                                    field="enddate"
                                    value={fieldSeason.get('enddate')}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={(field, date) => this.handleDateChange(field, date)}
                                />

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="delete_btn_holder">
                    <a href="#" onClick={(e) => { e.preventDefault(); this.toggle(); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">Cancel</a>
                    <a href="#" onClick={(e) => { e.preventDefault(); this.saveNewSeason(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">Save</a>
                </div>
            </Modal>
        );
    }

    handleRequestSort = (event, property) => {
        const { orderBy, order } = this.state;
        const isAsc = orderBy === property && order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property,
        });
    }

    render() {
        const { fields, modal, } = this.state;
        const { t } = this.props;
        const hasPicture = fields.get('logourl') == null || fields.get('logourl') === "" ? false : true;

        return (
            <span>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="shadow_container nav_same">
                            <div className="setting_nav">
                                <ul>
                                    <li className="active">
                                        <a href="#" onClick={e => e.preventDefault()}>{t('general.generalsettings')}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="shadow_container">
                            <div className="datatable_top">
                                <div className="datatable_top_left_settings">{t('general.clubsettings')}</div>
                            </div>
                            <div className="gen_settings_bottom team_setting_table">
                                <div className="add_pay_section">
                                    <div className="add_pic_section add_pic_section_setting">
                                        <div className="upload_btn" onMouseEnter={this.setInvisible} onMouseLeave={this.setInvisible}>
                                            <input type="file" onChange={this.uploadFileChange} className="upload-detail-settings" />
                                            {hasPicture && <img src={fields.get('logourl') !== '' ? fields.get('logourl') : EmptyTeamsImage} style={{ width: '200px' }} />}
                                            {!hasPicture && <img src={imageUpload} alt="" />}

                                        </div>
                                        <p>
                                            <small>{t('general.photonote')}</small>
                                            <br />
                                            <small>{t('general.photonoteresolution')}</small>
                                        </p>
                                    </div>
                                    <div className="add_details">
                                        <table className="details_table_add">
                                            <tbody>
                                                <tr>
                                                    <td>{t('general.clubname')}</td>
                                                    <td className="dt_gap"></td>
                                                    <td>
                                                        <TextInputField
                                                            disabled={false}
                                                            field={'clubname'}
                                                            onChange={(field, value) => this.handleChange(field, value)}
                                                            value={fields.get('clubname')} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t('general.country')}</td>
                                                    <td className="dt_gap"></td>
                                                    <td>
                                                        <TextInputField
                                                            disabled={false}
                                                            field={'country'}
                                                            onChange={(field, value) => this.handleChange(field, value)}
                                                            value={fields.get('country')} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="update_btn_holder">
                                            <div className="button-normal grey right" onClick={this.saveClubDetails}>{t('general.update')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.addSeasonModal(modal)}
                </div>
            </span>
        );
    }
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align ?? 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >

                        {headCell.label != 'Action' &&
                            < TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={FaCaretUp}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                        {
                            headCell.label == 'Action' &&
                            <span>{headCell.label}</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    buttonInvitation: {

        background: 'grey',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 3px 5px 2px ',
        color: 'white',
        height: 40,
        padding: '0 5px',
        textTransform: 'none'
    }
}));

export default connect((state) => {
    return {
        clubDetails: state.users.clubDetails,
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        addSeasonResult: state.users.addSeasonResult,
    }
},
    dispatch => bindActionCreators({
        fetchClubDetails,
        uploadPicture,
        updateClubDetails,
        addSeason,
        updateSeason,
    }, dispatch))(withTranslation()(GeneralSettings));