import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { webAppUrl, fetchTeamTrainerOverview } from '../../constants';

export default (t, clubSeasonId) => async (dispatch) => {
    var tes = clubSeasonId !== null ? (clubSeasonId !== undefined ? "&&clubSeasonId=" + clubSeasonId : '') : '';
    const url = `${webAppUrl}/api/trainer/GetTrainerOverviewByClubId?teamId=${t}${clubSeasonId !== null ? (clubSeasonId !== undefined ? "&&clubSeasonId=" + clubSeasonId : '') : ''}`;    
    const response = await fetchWithBearerToken(url);
    
    if (response.ok) {
        const teamDetails = await response.json();
        dispatch({
            type: fetchTeamTrainerOverview,
            teamDetails
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
