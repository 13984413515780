import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

import { getAvailablePlayers, getAvailableTrainers, webAppUrl } from '../../constants';

export default (e) => async (dispatch) => {
    const url = `${webAppUrl}/api/Manager/getAvailablePlayersOrTrainers?key=${e}`;
    
    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const searchResult = await response.json();
        e == 'player' &&
            dispatch({
                type: getAvailablePlayers,
                result: searchResult
            });

        e == 'trainer' && 
            dispatch({
                type: getAvailableTrainers,
                result: searchResult
            });
    }
}
