import React, { Component } from 'react';
import defaultUser from '../images/default_user.png';
import { Link } from 'react-router-dom';

class ProfileThumbnailPicture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgSrc: defaultUser
        }

        this.setError = this.setError.bind(this);
    }

    static defaultProps = {
        cls: '',
        size: 35,
        imgUrl: '',
        urlDetail: ''
    }

    setError() {
        this.setState({
            imgSrc: defaultUser
        });
    }

    render() {
        const { imgUrl, cls, size, urlDetail } = this.props;
        return (imgUrl == '' ?
            <img src={defaultUser} className={`rounded-circle ${cls}`} /> :
            (urlDetail == '' ?
                <div style={{
                    backgroundImage: `url(${imgUrl})`,
                    backgroundSize: "cover",
                    borderRadius: "50%",
                    height: `${size}px`,
                    width: `${size}px`
                }} className={cls} onError={this.setError}>
                    <span style={{ color: "transparent" }}></span>
                </div>
                : <Link to={'' + urlDetail} >
                <div style={{
                    backgroundImage: `url(${imgUrl})`,
                    backgroundSize: "cover",
                    borderRadius: "50%",
                    height: `${size}px`,
                    width: `${size}px`
                }} className={cls} onError={this.setError}>
                    <span style={{ color: "transparent" }}></span>
                </div>
            </Link>)
        )
    }
}

export default (ProfileThumbnailPicture)