import { toast } from 'react-toastify';
import { webAppUrl, tokenKey } from '../../constants';
import fetchPlayerOverview from '../../../store/TeamDetail/Action/fetchPlayerOverview';
import fetchTrainerOverview from '../../../store/TeamDetail/Action/fetchTrainerOverview';

export default (teamPlayers, teamId, type, source, t) => async (dispatch) => {
    let url = `${webAppUrl}/api/Player/ChangeTeam`;
    const requestBody = JSON.stringify({ userId: teamPlayers, newTeamId: teamId, type });

    let response = await fetch(url, {
        method: `POST`,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        }
        , body: requestBody
    });

    if (response.ok) {
        toast.success(t('messagessuccess.changeteam'), {
            position: toast.POSITION.TOP_RIGHT
        });
        if (source == 'detail') {
            window.location.reload();
        }
        if (source == 'team') {
            dispatch(fetchPlayerOverview(teamId));
            dispatch(fetchTrainerOverview(teamId));
        }

    }
    else {
        toast.success(`${t('messagesfailed.changeteam')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
