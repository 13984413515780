import { uploadProfilePicture, tokenKey, authUrl } from '../constants';
import { toast } from 'react-toastify';


export default (formData, dtTag, userId, t) => async (dispatch) => {
    var url;
    if (userId)
        url = `${authUrl}/Account/UploadPhoto?dateTimeTag=${dtTag}&userId=${userId}`;
    else
        url = `${authUrl}/Account/UploadPhoto?dateTimeTag=${dtTag}`;

    const tokenVar = sessionStorage.getItem(tokenKey);

    const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization': 'Bearer ' + tokenVar
        }
    }).catch(error => { toast.error(error); });

    //const response = await fetchWithBearerToken(url, 'POST', formData);

    if (response.ok) {

        const resultContent = await response.json();
        if (resultContent == null) {
            toast.error(`${t('messagesfailed.uploadpic')} ${response.errorMessage}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        toast.success(t('messagessuccess.uploadpic'), {
            position: toast.POSITION.TOP_RIGHT
        });

        dispatch({ type: uploadProfilePicture, userProfilePicture: resultContent })
     
    }
};

function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
};