import {
    fetchUsersType,
    receiveUsersType,
    updateUserActivationState,
    updatePasswordToken,
    getTermsAndPrivacy,
    getHeaderDetail,
    getClubDetails,
    addSeason,
    resetPassword,
    getClubHeader
} from '../../constants';
import { fromJS } from 'immutable';

const initialState = {
    users: [], userLoginDashboard: {}, isLoading: false, resetPassword: null, headerDetail: null
};

export default (state, action) => {
    state = state || initialState;

    if (action.type === fetchUsersType) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === receiveUsersType) {
        return {
            ...state,
            users: action.users,
            isLoading: false
        };
    }

    if (action.type === updateUserActivationState) {
        return {
            ...state,
            userActivated: action.userActivated,
            tokenValid: action.tokenValid,
        }
    }

    if (action.type === updatePasswordToken) {

        return {
            ...state,
            resetPassword: action.resetPassword
        }
    }

    if (action.type == getTermsAndPrivacy) {
        return {
            ...state,
            termsandprivacy: action.termsAndPrivacy
        }
    }

    if (action.type == getHeaderDetail) {
        return {
            ...state,
            headerDetail: action.headerDetail
        }
    }

    if (action.type == getClubDetails) {
        return {
            ...state,
            clubDetails: action.clubDetails
        }
    }

    if (action.type === addSeason) {
        return { ...state, addSeasonResult: action.result };
    }

    if (action.type === resetPassword) {
        return { ...state, resetPasswordResult: action.result };
    }

    if (action.type == getClubHeader) {
        return {
            ...state,
            clubHeader: action.result
        }
    }

    return state;
};
