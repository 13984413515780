import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

import { getHeaderDetail, webAppUrl } from '../../constants';

export default () =>async (dispatch) => {
    const url = `${webAppUrl}/api/Manager/GetUserDetailHeader`;

    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const headerDetailResult = await response.json();

        dispatch({
            type: getHeaderDetail,
            headerDetail: headerDetailResult
        });
    }
}
