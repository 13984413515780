import { toast } from 'react-toastify';
import { authUrl, resetPassword } from '../../constants'
export default (entity) => async (dispatch) => {
    const url = `${authUrl}/Account/ResetPassword?email=${encodeURIComponent(entity.email)}`;        
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    if (response.ok) {
        toast.success("Reset password success", {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({ type: resetPassword, result: true });
    }    
};