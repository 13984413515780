import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";

import './Overview.css';
import getTermsAndPrivacyAction from '../../../src/store/users/actions/fetchTermsAndPolicy';
import { termsAndPrivacyStateSelector } from '../../../src/store/users/selectors/userSelectors';
import TermsAndPolicy from '../../components/TermsAndPrivacy/TermsAndPrivacy';
import { userId } from '../../store/constants';
import TeamsImage from '../images/Team_logo.png';
import TrainerLogo from '../images/Trainer_Grey_Big.png';
import PlayerLogo from '../images/Player_Grey_Big.png';
import getSeason from '../../store/MasterData/getSeason';
import CircularProgressbar from '../SharedComponents/CircularProgressBar.js';
import CheckboxMultiselect from '../SharedComponents/Checkbox/CheckboxMultiSelect';
import defaultUser from '../images/default_user.png';
import fetchPlayerDevelopment from '../../store/Overview/fetchPlayerDevelopment';
import fetchTotalHeader from '../../store/Overview/fetchTotalHeader';
import fetchTrainerActivity from '../../store/Overview/fetchTrainerActivity';
import fetchTeamOverview from '../../store/Overview/fetchTeamOverview';
import fetchMainDashboard from '../../store/Overview/fetchMainDashboard';
import ProfilePic from '../SharedComponents/ProfileThumImage';
import getTeams from '../../store/MasterData/getTeams';
import SoccerIcon from '../images/Team_Grey.svg';

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRendering: true,
            isinitload: true,
            seasonDropDownIsOpen: false,
            selectedTeamsFilter: [],
            filteredSeason: '',
            filteredSeasonId: null,
            isVisible: false,
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent() {
        this.setState({ isVisible: true });
    }

    toggleItem = () => {
        const { seasonDropDownIsOpen } = this.state;
        this.setState({ seasonDropDownIsOpen: !seasonDropDownIsOpen });
    }

    populateSelectedTeam = (teams) => {
        Promise.all([
            this.props.fetchPlayerDevelopment(this.state.filteredSeasonId, teams),
            this.props.fetchTrainerActivity(this.state.filteredSeasonId, teams),
            this.props.fetchTeamOverview(this.state.filteredSeasonId, teams),
            this.props.fetchMainDashboard(this.state.filteredSeasonId, teams)])
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });

        this.setState({
            selectedTeamsFilter: teams,
            isRendering: true
        });
    }

    componentDidMount() {
        Promise.all([
            this.props.getSeason(),
            this.props.getTeams(),
            this.props.fetchPlayerDevelopment(),
            this.props.fetchTotalHeader(),
            this.props.fetchTrainerActivity(),
            this.props.fetchTeamOverview(),
            this.props.fetchMainDashboard()
        ])
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
                this.hideComponent();
            });

        this.setState({ isRendering: true }, () => {
            this.setState({ isRendering: false });
        });
    }

    toggleSeason = () => {
        this.props.history.push(`/general-settings`);
    }

    componentDidUpdate(prevProps) {
        const { seasons } = this.props;
        const { isinitload } = this.state;

        var initfilteredSeason = '';
        var initfilteredSeasonId = '';
        if (isinitload && seasons.length > 0) {
            seasons.map((row, index) => {
                if (row.isCurrent) {
                    initfilteredSeason = row.seasonName;
                    initfilteredSeasonId = row.id;
                }
            });
            this.setState({
                isinitload: false,
                filteredSeason: initfilteredSeason,
                filteredSeasonId: initfilteredSeasonId
            });
        }

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps))
            this.setState({ isRendering: false });
    }

    handleOnSelectSeason = (id, seasonName) => {

        const { selectedTeamsFilter } = this.state;

        if (id) {
            Promise.all([
                this.props.fetchPlayerDevelopment(id, selectedTeamsFilter),
                this.props.fetchTotalHeader(id, selectedTeamsFilter),
                this.props.fetchTrainerActivity(id, selectedTeamsFilter),
                this.props.fetchTeamOverview(id, selectedTeamsFilter),
                this.props.fetchMainDashboard(id, selectedTeamsFilter),
                this.props.getTeams(id)])
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
        }

        this.setState({
            filteredSeason: seasonName,
            filteredSeasonId: id,
            isRendering: true
        });
    }

    render() {
        const { playerDevelopmentData, totalHeader, trainerActivity, teamOverview, mainDashboard, seasons, allTeams, t } = this.props;
        const { seasonDropDownIsOpen, filteredSeason, selectedTeamsFilter, populateSelectedTeam, isVisible } = this.state;
        const teamImage = sessionStorage.getItem('clubImage');

        return (
            <div>
                {isVisible && (
            <div className="home-container">
                <div className="row overview_body_top">
                    <div className="overview_body_top_left">
                        <div className="total_box">
                            <div className="total">{t('overview.teams')}</div>
                            <div className="total_num">{totalHeader && totalHeader.length > 0 ? totalHeader[0].team : 0}</div>
                        </div>
                        <div className="total_box">
                            <div className="total">{t('overview.trainers')}</div>
                            <div className="total_num">{totalHeader && totalHeader.length > 0 ? totalHeader[0].trainer : 0}</div>
                        </div>
                        <div className="total_box">
                            <div className="total">{t('overview.players')}</div>
                            <div className="total_num">{totalHeader && totalHeader.length > 0 ? totalHeader[0].player : 0}</div>
                        </div>
                    </div>
                    <div className="overview_filter_section" data-children-count="1">
                        {
                            allTeams !== undefined && allTeams !== null &&
                            <CheckboxMultiselect
                                displayedText={t('overview.allteams')}
                                singleTeam={t('overview.singleteam')}
                                pluralTeams={t('overview.pluralteam')}
                                className="filter-overview"
                                allTeamMember={allTeams}
                                selectedTeam={selectedTeamsFilter}
                                populateSelectedTeam={this.populateSelectedTeam}
                            />
                        }
                        <div className="psr">
                            <div className="season-btn">
                                <Dropdown isOpen={seasonDropDownIsOpen} toggle={this.toggleItem} className="dropdown-caret btn-group">
                                    <DropdownToggle caret>
                                        {filteredSeason}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {seasons.map((row, index) => {
                                            //let current = '';
                                            //current = row.isCurrent ? (
                                            //    <img src={checklistCompleted} style={{ marginLeft: '10px' }} class="report-skill-small-thumb" />
                                            //) : (<img />);
                                            return (
                                                <DropdownItem key={row.id} onClick={(e) => { e.preventDefault(); this.handleOnSelectSeason(row.id, row.seasonName); }}>
                                                    <div className="season-inline" id={row.id} >
                                                        <span>{row.seasonName}
                                                            {/*current*/}
                                                        </span>
                                                    </div>
                                                </DropdownItem>)
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overview_body_bottom">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="overview_panel_adjustment overview_content_box" style={{ overflow: "hidden" }}>
                                <h2 className="padding-bottom-club">{t('overview.club')}
                                </h2>

                                <div className="first_box_top">
                                    <div className="first_box_top_cn">
                                        <div className="over_top_icon" style={{ height: '60px' }}>
                                            <img src={SoccerIcon} alt="" style={{ marginTop: '2px', marginBottom: '5px', width: '55px' }} />
                                        </div>
                                        <h4>{t('teams.title')}</h4>
                                        <h6>{mainDashboard ? mainDashboard.team : 0}</h6>
                                    </div>
                                    <div className="first_box_top_cn">
                                        <div className="over_top_icon">
                                            <img src={TrainerLogo} alt="" />
                                        </div>
                                        <h4>{t('trainers.title')}</h4>
                                        <h6>{mainDashboard ? mainDashboard.trainer : 0}</h6>
                                    </div>
                                    <div className="first_box_top_cn">
                                        <div className="over_top_icon">
                                            <img src={PlayerLogo} alt="" />
                                        </div>
                                        <h4>{t('players.title')}</h4>
                                        <h6>{mainDashboard ? mainDashboard.player : 0}</h6>
                                    </div>
                                </div>
                                <div className="first_box_bottom">
                                    <div className="first_box_top_cn">
                                        <div className="cn_top_tx">
                                            {mainDashboard ? mainDashboard.overallPlayerScore : 0}
                                        </div>
                                        <span className="para_span">{t('overview.overall')}<br />{t('overview.playerscore')}</span>
                                    </div>
                                    <div className="first_box_top_cn">
                                        <div className="cn_top_tx can_top_dif">
                                            {mainDashboard ? `${mainDashboard.completedTeam}` : 0}
                                        </div>
                                        <span className="para_span">{t('overview.completed')}<br />{t('teams.lowerteams')}</span>
                                    </div>
                                    <div className="first_box_top_cn">
                                        <div className="cn_top_tx can_top_dif">
                                            {mainDashboard ? `${mainDashboard.completedPlayer}` : 0}
                                        </div>
                                        <span className="para_span">{t('overview.completed')}<br />{t('players.lowerplayers')}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="overview_panel_adjustment overview_content_box">
                                <h2>{t('overview.player')}
                                    <span>
                                        <Link to={'/players'} className="detail-manager-link">
                                            <MdChevronRight className="detail-manager title-detail-arrow" />
                                        </Link>
                                    </span>
                                </h2>
                                <div className="player_stat_part dashbord-min-height">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="round_progress">
                                                <CircularProgressbar
                                                    strokeWidth="6"
                                                    sqSize="140"
                                                    percentage={mainDashboard ? mainDashboard.playerProgress : 0}
                                                    extraText={t('overview.completed') + ' ' + t('players.lowerplayers')} />
                                                <div className="player_drop_up">
                                                    <div className="report_cn">
                                                        <div className="report_left">Completed reports :</div>
                                                        <div className="report_right">{mainDashboard ? mainDashboard.completedReport : 0}</div>
                                                    </div>
                                                    <div className="report_cn">
                                                        <div className="report_left">Total reports :</div>
                                                        <div className="report_right">{mainDashboard ? mainDashboard.totalReport : 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                                <div className="col-lg-9" style={{top:"-10px"}}>
                                            <div className="player_stat_table_holder dash_player_stat_table_holder">
                                                {
                                                    playerDevelopmentData && playerDevelopmentData.topPlayers && playerDevelopmentData.topPlayers.map(x => (
                                                        <div key={x.playerName} className="player-row">
                                                            <div className="progress_bar player_dev_dashboard">
                                                                <div className="trainer_drop_up player_dev_popup">
                                                                    <div className="drop_up_top">
                                                                        <div className="trainer_pic">
                                                                            <ProfilePic
                                                                                imgUrl={x.profilePicture} cls="img-dashboard-thumb" alt="" />
                                                                        </div>
                                                                        <div className="trainer_cdand_name">
                                                                            <div className="trainer_name_drop_up">{x.playerName}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="drop_up_bottom">
                                                                        <div className="report_cn">
                                                                            <div className="report_left">Completed reports :</div>
                                                                            <div className="report_right">{x.completedReport}</div>
                                                                        </div>
                                                                        <div className="report_cn">
                                                                            <div className="report_left">Total reports :</div>
                                                                            <div className="report_right">{x.totalReport}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="manager_pic">
                                                                    <ProfilePic
                                                                        urlDetail={'/players/detail-player?userId=' + x.playerId}
                                                                        imgUrl={x.profilePicture !== null && x.profilePicture !== '' ? x.profilePicture : defaultUser}
                                                                        cls="img-dashboard-thumb" alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="player fill">{x.playerName}</div>
                                                            {
                                                                x.isCompleted ?
                                                                    //masking 0 with '-' or "en dash" or <span>&#8211; or space ' '</span>                                                                    
                                                                    <div className="player score">{x.averageScore == 0.0 ? ' ' : x.averageScore}</div>
                                                                    :
                                                                    <div className="player progress_bar">
                                                                        <div className="progress">
                                                                            <div className="progress-bar"
                                                                                role="progressbar"
                                                                                style={{ width: x.progress + '%' }}
                                                                                aria-valuenow={x.progress}
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100">
                                                                                <span>{x.progress}%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            < Link to={'/players/detail-player?userId=' + x.playerId} className="detail-manager-link" >
                                                                <MdChevronRight className="detail-manager" />
                                                            </Link>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="overview_panel_adjustment overview_content_box no_overflow">
                                <h2>{t('overview.trainer')}
                                    <span>
                                        <Link to={'/trainers'} className="detail-manager-link">
                                            <MdChevronRight className="detail-manager title-detail-arrow" />
                                        </Link>
                                    </span>
                                </h2>
                                <div className="player_stat_part dashbord-min-height">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="player_stat_table_holder dash_player_stat_table_holder">
                                                {trainerActivity && trainerActivity.map(x =>
                                                    (<div key={x.trainerName} className="player_table_row">
                                                        <div className="player_image manager_pic">
                                                            <ProfilePic
                                                                urlDetail={'/trainers/detail-trainer?userId=' + x.userId}
                                                                imgUrl={x.profilePicture !== '' ? x.profilePicture : defaultUser}
                                                                cls="img-dashboard-thumb" alt=""
                                                            />
                                                        </div>
                                                        <div className="player_name_dashboard">{x.trainerName}</div>
                                                        <div className="traincode_dashboard ">{x.teamName}</div>
                                                        <div className="progress_bar">
                                                            <div className="trainer_drop_up">
                                                                <div className="drop_up_top">
                                                                    <div className="trainer_pic">
                                                                        <ProfilePic
                                                                            imgUrl={x.profilePicture !== '' ? x.profilePicture : defaultUser}
                                                                            urlDetail={'/trainers/detail-trainer?userId=' + x.userId}
                                                                            cls="img-dashboard-thumb" alt="" />
                                                                    </div>
                                                                    <div className="trainer_cdand_name">
                                                                        <div className="trainer_name_drop_up">{x.trainerName}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="drop_up_bottom">
                                                                    <div className="report_cn">
                                                                        <div className="report_left">Completed reports :</div>
                                                                        <div className="report_right">{x.reportedPlayers}</div>
                                                                    </div>
                                                                    <div className="report_cn">
                                                                        <div className="report_left">Open reports :</div>
                                                                        <div className="report_right">{x.openReport}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="progress">
                                                                {parseInt(x.progress) >= 100 &&
                                                                    (<div className="progress-bar" role="progressbar" style={{ width: 100 + '%' }} aria-valuenow={100} aria-valuemin="0" aria-valuemax="100"><span>100%</span></div>)}
                                                                {parseInt(x.progress) < 100 &&
                                                                    (<div className="progress-bar" role="progressbar" style={{ width: x.progress + '%' }} aria-valuenow={x.progress} aria-valuemin="0" aria-valuemax="100"><span>{x.progress}%</span></div>)}
                                                            </div>
                                                        </div>
                                                    </div>))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="overview_panel_adjustment overview_content_box no_overflow">
                                <h2>{t('overview.team')}
                                    <span>
                                        <Link to={'/teams'} className="detail-manager-link">
                                            <MdChevronRight className="detail-manager title-detail-arrow" />
                                        </Link>
                                    </span>
                                </h2>
                                <div className="player_stat_part dashbord-min-height">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="round_progress">
                                                <CircularProgressbar
                                                    strokeWidth="6"
                                                    sqSize="140"
                                                    percentage={mainDashboard ? mainDashboard.teamProgress : 0}
                                                    extraText={t('overview.completed') + ' ' + t('teams.lowerteams')}
                                                />
                                                <div className="team_drop_up">
                                                    <div className="report_cn">
                                                        <div className="report_left">Completed teams :</div>
                                                        <div className="report_right">{mainDashboard ? mainDashboard.completedTeam : 0}</div>
                                                    </div>
                                                    <div className="report_cn">
                                                        <div className="report_left">Open teams :</div>
                                                        <div className="report_right">{mainDashboard ? mainDashboard.openTeam : 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="player_stat_table_holder dash_player_stat_table_holder">
                                                {teamOverview && teamOverview.map(x =>
                                                    (
                                                        <div key={x.teamName} className="team-row">
                                                            <div className="team image fill">
                                                                <img src={teamImage} alt="" />
                                                            </div>
                                                            <div className="team fill">{x.teamName}</div>
                                                            <div className="progress_bar">
                                                                <div className="trainer_drop_up">
                                                                    <div className="drop_up_top">
                                                                        <div className="trainer_pic">
                                                                            <img src={teamImage} alt="" />
                                                                        </div>
                                                                        <div className="trainer_cdand_name">
                                                                            <div className="trainer_name_drop_up">{x.teamName}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="drop_up_bottom">
                                                                        <div className="report_cn">
                                                                            <div className="report_left">Completed reports :</div>
                                                                            <div className="report_right">{x.reportedPlayers}</div>
                                                                        </div>
                                                                        <div className="report_cn">
                                                                            <div className="report_left">Open reports :</div>
                                                                            <div className="report_right">{x.openReport}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" style={{ width: x.progress + '%' }} aria-valuenow={x.progress} aria-valuemin="0" aria-valuemax="100"><span>{x.progress}%</span></div>
                                                                </div>
                                                            </div>
                                                        </div>))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
                    )}
            </div>
        );
    }
}
const mapToLIst = (e) => {
    const res = e ? e.map((x) => { return { value: x.id, label: x.teamName, color: "#00B8D9" } }) : null;
    return res;
}
export default connect((state) => {
    return {
        playerDevelopmentData: state.dashboard.playerDevelopment,
        totalHeader: state.dashboard.totalHeader,
        trainerActivity: state.dashboard.trainerActivity,
        teamOverview: state.dashboard.teamOverview,
        mainDashboard: state.dashboard.mainDashboard,
        seasons: state.masterData.seasons,
        allTeams: mapToLIst(state.masterData.allTeams)
    }
},
    dispatch => bindActionCreators({
        fetchPlayerDevelopment, fetchTotalHeader, fetchTrainerActivity, fetchTeamOverview, fetchMainDashboard, getSeason,
        getTeams
    }, dispatch))(withTranslation()(Overview));