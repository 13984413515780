import { webAppUrl, getPlayerOverview } from '../../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { fromJS } from 'immutable';

export default (t, clubSeasonId) => async (dispatch) => {

    const response = await fetchWithBearerToken(`${webAppUrl}/api/Player/GetTeamPlayerOverview?teamId=${t}${clubSeasonId !== null ? (clubSeasonId !== undefined ? "&&clubSeasonId=" + clubSeasonId : '') : ''}`);
    
    if (response.ok) {
        if (response.status === 204) {
            dispatch({
                type: getPlayerOverview,
                players: []
            });
        }
        else {
            const players = await response.json();
             
            dispatch({
                type: getPlayerOverview,
                players
            });
        }
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};