import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { webAppUrl, fetchTeamOverview } from '../../constants';

export default (clubSeasonId) => async (dispatch) => {
    const url = `${webAppUrl}/api/team/GetTeamOverviewByClubId?${clubSeasonId !== null ? (clubSeasonId !== undefined ? "clubSeasonId=" + clubSeasonId : '') : ''}`;
    const response = await fetchWithBearerToken(url);    

    if (response.ok) {
        const teams = await response.json();
        dispatch({
            type: fetchTeamOverview,
            teams
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
