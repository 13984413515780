import '../../bootstrap_css/style.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import defaultUser from '../../images/default_user.png';
import DetailReportItem from './DetailReportItem';
import { UncontrolledCollapse } from 'reactstrap';
import { MdChevronRight, MdKeyboardArrowDown } from "react-icons/md";
import ProfilePic from '../../SharedComponents/ProfileThumImage';

class ReportPlayerItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.expandClick = this.expandClick.bind(this);
    }

    expandClick(e) {
        let id = e.currentTarget.id;
        let el = document.querySelector(`#${id}.skill-expand-arrow`);

        el.style.transformOrigin = 'center center';
        let tr = el.style.transform;

        if (tr == '')
            el.style.transform = 'rotate(90deg)';
        else
            el.style.transform = '';
    }



    render() {
        const { playerReportResponse } = this.props;
        
        return (
            <div className="accordion-box">{playerReportResponse && playerReportResponse.map((obj) => { return (
                <div className="box">
                    <div className={obj.isActive ? "header" :"header history_header"}>
                        <div className="header-content">
                            <div className="accro_hd">
                                <div className="accro_img">
                                    <ProfilePic imgUrl={obj.trainerPic ? obj.trainerPic : defaultUser} size={50} urlDetail={'/trainers/detail-trainer?userId=' + obj.userId} />
                                </div>
                                <div className="accro_details accro_details-trainers" id={`toggler-${obj.trainerUserId}`} onClick={this.expandClick}>
                                    {obj.trainerName} {obj.isActive ? "" : "| " + obj.teamName}<br/>
                                    <div className="std">Average score</div>
                                </div>
                                
                                <div className="completed-side-report score" style={{ textAlign: "right" }} id={`toggler-${obj.trainerUserId}`} onClick={this.expandClick}>
                                    {obj.averageScore ? parseFloat(obj.averageScore).toFixed(1) : ' ' }
                                    
                                </div>
                                
                            </div>
                            {(<MdChevronRight className="skill-expand-arrow" id={`toggler-${obj.trainerUserId}`} onClick={this.expandClick}/>)}
                        </div>
                    </div>
                    <div className="body-content" style={{ display: 'block' }}>
                        <UncontrolledCollapse toggler={`#toggler-${obj.trainerUserId}`}>
                            <DetailReportItem playerReportReviews={obj.playerReportReview} />
                        </UncontrolledCollapse>
                    </div>
                </div>)})}
            </div>
        );
    }
}

export default connect((state) => {
    return {}
},
    dispatch => bindActionCreators({}, dispatch))(ReportPlayerItem);