import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { isOnPath } from '../../../helpers/routingHelper';
import { withTranslation } from 'react-i18next';
import './NavMenuItem.css';

class NavMenuItem extends React.Component {
    render() {
        const {
            pathname,
            navItem,
        } = this.props;
        const { t } = this.props;
        return (
            <NavItem
                key={`${navItem.get('to')} ${navItem.get('label')}`}
                active={isOnPath(pathname, navItem.get('to'), navItem.get('matchExact'))}
            >
                <NavLink
                    tag={Link}
                    to={navItem.get('to')}
                    disabled={navItem.get('disabled')}
                >
                    <span className="sidebar_icon_and_text">
                        <span className="nav_icon"><img src={navItem.get('iconUrl')} /></span><span>{t(navItem.get('label'))}</span>
                    </span>
                </NavLink>
            </NavItem>
        );
    }
}

export default (withTranslation()(NavMenuItem));