import { fromJS } from 'immutable';
import '../../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import getManager from '../../../store/Manager/actions/fetchManagerByUserId';
import { bindActionCreators } from 'redux';
import TextInputField from '../../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';
import trashwhite from '../../images/Trash.svg';
import { routingLocationSelector } from '../../../store/general/selectors/generalSelector';
import ProfilePic from '../../SharedComponents/ProfileThumImage';
import { webAppUrl } from '../../../store/constants';
import { timeSince } from '../../../helpers/dateHelper';
import { toast } from 'react-toastify';
import uploadPicture from '../../../store/profilePicture/uploadPicture';
import updateManagerData from '../../../store/Manager/actions/updateManagerData';
import getHeaderDetail from '../../../store/users/actions/getHeaderDetail';
import { MdChevronLeft } from "react-icons/md";
import { formatDateOverviewPicker } from '../../../helpers/dateHelper';
import DateInputField from '../../SharedComponents/DateInputField';
import clearImage from '../../../store/profilePicture/clearImage';

class DetailManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            fields: fromJS({
                id: '',
                firstname: '',
                lastname: '',
                nationality: '',
                birthplace: '',
                birthdate: '',
                email: '',
                telephone: '',
                address: '',
                role: '',
                datestarted: '',
                lastlogin: '',
                imgTemp: null,
                photoDate: '',
                photo: null,
                userPicture: '',
                clubname: '',
                clublogourl: '',
            }),
            errors: fromJS({}),
            userId: '',
            componentUpdated: false,
            displayed: false,
        };
        this.goBack = this.goBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.setInvisible = this.setInvisible.bind(this);
        this.clear = this.clear.bind(this);

        this.setAlert = this.setAlert.bind(this)
    }

    handleChange(field, value) {
        const { fields } = this.state;
        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    componentDidMount() {
        const { routingLocation } = this.props;

        if (routingLocation && routingLocation.query) {
            const userId = routingLocation.query['userId'];
            if (userId) {
                this.props.getManager(userId)
                    .then(t => this.hideLoading());
                this.setState({
                    userId: userId,
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { managerData, profilePictureUpload, updatedManager } = this.props;
        if (updatedManager && updatedManager != prevProps.updatedManager) {
            this.props.getHeaderDetail()
                .then(t => this.hideLoading());
        }

        if (profilePictureUpload && profilePictureUpload !== prevProps.profilePictureUpload) {
            this.saveUploadedPhoto(profilePictureUpload);
        }

        if (managerData && prevProps.managerData != managerData) {

            this.setState({
                fields: fromJS({
                    firstname: managerData.firstName,
                    lastname: managerData.lastName,
                    nationalityid: managerData.nationalityId,
                    nationality: managerData.nationality,
                    birthplace: managerData.birthPlace,
                    birthdate: managerData.birthDate,
                    email: managerData.email,
                    telephone: managerData.phoneNumber,
                    address: managerData.address,
                    role: managerData.role,
                    datestarted: managerData.dateStarted,
                    lastlogin: managerData.lastLogin,
                    userpicture: managerData.userPicture,
                    clubname: managerData.clubName,
                    clublogourl: managerData.clubLogoUrl,
                }),
                componentUpdated: false,
            })
        }
    }

    saveUploadedPhoto(e) {
        this.setState({
            fields: this.state.fields.set('photo', e.id),
            errors: this.state.errors.delete('photo')
        });
    }

    goBack(e) {
        this.props.history.goBack();
        e.preventDefault();
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { userId } = this.state;
        const { t, uploadPicture, getManager } = this.props;
        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '6000000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('imgTemp', URL.createObjectURL(files[0]));

        newFields = newFields.set('photoDate', dateTag);

        this.setState({
            fields: newFields
        });

        uploadPicture(formData, dateTag, userId, t)
            .then(t => {
                getManager(userId);
                this.hideLoading();
            });
    }

    clear() {
        const i = document.querySelector('.upload-detail');
        const { t, clearImage, getManager } = this.props;
        const { userId } = this.state;

        clearImage(userId, t)
            .then(t => {
                i.value = '';
                getManager(userId);
                this.hideLoading();
            });
    }

    handleUpdate() {
        const { fields } = this.state;

        const { routingLocation } = this.props;

        const entity = fields.toJS();

        entity.id = routingLocation.query['userId'];

        //validation

        const simpleEmailRegexp = new RegExp('[^@]+@[^\.]+\..+');

        const hasValidEmail = simpleEmailRegexp.test(fields.get('email'));
        if (!hasValidEmail) {
            toast.success("invalid email format", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        //end of validation

        this.props.updateManagerData(entity);
    }

    setInvisible(e) {
        const { displayed } = this.state;

        this.setState({ displayed: !displayed });
    }

    setAlert(e) {
        alert('throw');
    }

    hideLoading() {
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "hidden";
    }

    handleDateChange = (field, d) => {

        this.setState({
            fields: this.state.fields.set(field, d)
        });
    }

    render() {
        const { t } = this.props;
        const { fields } = this.state;

        const hasPicture = fields.get('imgTemp') == null || fields.get('imgTemp') === undefined ? false : true;

        return (<div>
            <div className="backto">
                <a href="#" onClick={this.goBack} ><MdChevronLeft className="backbtn" /></a>
            </div>
            <div className="shadow_container">
                <div className="details_top">
                    {/*<div className="remove_details_pg">*/}
                    {/*    <a href="#" className="btn_remove" data-toggle="modal" data-target="#delete_modal"><img src={trashwhite} alt="asdf" /></a>*/}
                    {/*</div>*/}
                    <div className="add_pic_section">
                        <div className="upload_btn">
                            <input type="file" onChange={this.uploadFileChange} className="upload-detail" />
                            {hasPicture && <img src={fields.get('imgTemp')} alt="" className="img-user-detail" />}
                            {!hasPicture && <ProfilePic cls='img-user-detail' imgUrl={fields.get('userpicture')} />}
                            <img src={`${webAppUrl}/hover_change_picture.png`} alt="" className="img-user-detail-hover rounded-circle" />
                            <img src={`${webAppUrl}/close_round.png`} alt="" className="remove-image" onClick={this.clear} />
                        </div>
                    </div>
                    <div className="details_box">
                        <div className="details_name">{fields.get('firstname') + ' ' + fields.get('lastname')}</div>
                        <div className="details_bottom">
                            <div className="row">
                                <div className="col-md-5">
                                    <table className="trains_detab">
                                        <tbody>
                                            <tr>
                                                <td>{t('general.activesince')}</td>
                                                <td className="dt_gap">:</td>
                                                <td className="right_cntb">{fields.get('datestarted')}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('general.lastlogin')}</td>
                                                <td className="dt_gap">:</td>
                                                <td className="right_cntb">{fields.get('lastlogin') ? fields.get('lastlogin') : ''}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom_details_holder">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="shadow_container">
                            <table className="details_table_left">
                                <tbody>
                                    <tr>
                                        <td>{t('general.role')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={true} field={'positionName'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('role')}
                                                extraClassName={'disabled-field-bg'}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.firstname')}</td>
                                        <td className="dt_gap"></td>
                                        <td><TextInputField disabled={false} field={'firstname'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('firstname')} /></td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.lastname')}</td>
                                        <td className="dt_gap"></td>
                                        <td><TextInputField disabled={false} field={'lastname'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('lastname')} /></td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>{t('generaldetail.nationality')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={true} field={'nationality'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('nationality')}
                                                extraClassName={'disabled-field-bg'}
                                            />
                                        </td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>{t('generaldetail.birthplace')}</td>
                                        <td className="dt_gap"></td>
                                        <td><TextInputField disabled={false} field={'birthplace'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('birthplace')} /></td>
                                    </tr>
                                    <tr>
                                        <td>{t('generaldetail.birthdate')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <DateInputField
                                                field="birthdate"
                                                value={formatDateOverviewPicker(fields.get('birthdate')) ? new Date(formatDateOverviewPicker(fields.get('birthdate'))) : ''}

                                                onChange={(field, date) => this.handleDateChange(field, new Date(date))}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.e-mail')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField disabled={true} field={'email'} onChange={(field, value) => this.handleChange(field, value)} 
                                                value={fields.get('email')}
                                                extraClassName={'disabled-field-bg'}
                                            />
                                        </td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>{t('generaldetail.telephone')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField disabled={false} field={'telephone'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('telephone')} />
                                        </td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>{t('generaldetail.address')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={true} field={'address'} onChange={(field, value) => this.handleChange(field, value)} value={fields.get('address')}
                                                extraClassName={'disabled-field-bg'}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">
                                            <div style={{ margin: "auto", width: "140px" }}>
                                                <a className="btn_update" href="#" onClick={(e) => { e.preventDefault(); this.handleUpdate(); }} style={{ float: "none" }}>{t('general.update')}</a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect((state) => {
    return {
        managerData: state.manager.manager,
        updatedManager: state.manager.updatedManager,
        routingLocation: routingLocationSelector(state),
        profilePictureUpload: state.profilePicture.profilePictureUpload
    }
},
    dispatch => bindActionCreators({
        getManager,
        uploadPicture,
        clearImage,
        updateManagerData,
        getHeaderDetail
    }, dispatch))(withTranslation()(DetailManager));