import { toast } from 'react-toastify';

import { tokenKey, addManager, authUrl } from '../../constants';

import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default (entity) => async (dispatch) => {
    
    const token = sessionStorage.getItem(tokenKey);
    const body = JSON.stringify({
        "email": entity.email,
        "role": entity.role,
        "phoneNumber": entity.phoneNumber,
        "firstName": entity.firstName,
        "lastName": entity.lastName,
        "profilePicture": entity.profilePicture,
        "photo": entity.photo,
        "teamId":'teamId',
    });

    const result = await fetchWithBearerToken(`${authUrl}/Account/RegisterUser`, 'POST', body);

    if (result.ok) {
        var response = await result.json();
        if (!response.isSucceeded)
            toast.error(`Failed adding manager. ${response.errorMessage}`, {
                position: toast.POSITION.TOP_RIGHT
            });

        dispatch({ type: addManager, result: new Date().toString() });
    }
    else {
        var response = await result.json();
        toast.error(`Failed adding manage. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

}