import { toast } from 'react-toastify';
import { webAppUrl, tokenKey } from '../../constants';
import getInvitedPlayers from '../InvitedPlayers/getInvitedPlayers';

export default (players, playerOverview, t) => async (dispatch) => {
    let url = `${webAppUrl}/api/Player/RemovePlayers?isActive=${playerOverview}`;

    let response = await fetch(url, {
        method: `POST`,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        }
        , body: JSON.stringify(players)
    });

    if (response.ok) {
        toast.success(t('messagessuccess.removedplayers'), {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(getInvitedPlayers(playerOverview));
    }
    else {
        toast.success(`${t('messagesfailed.failedremove')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
