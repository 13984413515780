import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';
import { webAppUrl, getTrainerOverview } from '../../constants';

export default (isActive, clubSeasonId) => async (dispatch) => {
    
    const url = `${webAppUrl}/api/trainer/GetTrainersOverview?isactive=${isActive}${clubSeasonId !== null ? (clubSeasonId !== undefined ? "&&clubSeasonId=" + clubSeasonId : '') : ''}`;

    const response = await fetchWithBearerToken(url);

    if (response.ok) {
        const trainers = await response.json();
        dispatch({
            type: getTrainerOverview,
            trainer: trainers
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
