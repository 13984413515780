import { fetchClubSeason, webAppUrl, tokenKey } from '../constants';
import fetchWithBearerToken from '../SharedActions/fetchWithBearerToken';

export default () => async (dispatch) => {

    const token = sessionStorage.getItem(tokenKey);
    let fetchArguments = {
        method: `GET`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };

    const result = await fetchWithBearerToken(`${webAppUrl}/api/MasterData/GetClubSeason`, 'GET');

    if (result.ok) {
        const resultData = await result.json()
        dispatch({ type: fetchClubSeason, seasons: resultData });
    }
};