import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import checklistCompleted from '../../images/Checklist_Small.png';
import checklistCompletedGray from '../../images/Checklist_Gray.png';
import trainerGrey from '../../images/Trainer_Grey.png';
import { withTranslation } from 'react-i18next';
import ProfilePic from '../../SharedComponents/ProfileThumImage';
import defaultUser from '../../images/default_user.png';
import Linkify from 'react-linkify';

class DetailReportItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        const { playerReportReviews, t } = this.props;
        if (playerReportReviews) {
            playerReportReviews.forEach((report, index) => {
                report.hasFeedback = report.playerReportDetailsSkill.find(skill => skill.comment != null && skill.comment != '') ? true : false;
                //report.hasFeedback = false;
            });
        }
        
        return (
            <div className="body-content">
                {playerReportReviews &&
                    playerReportReviews.map((data) => {
                        return (
                            <div className="accrd_detils_repet" >
                                <table className="table-report-detail">
                                    <tbody>
                                    <tr className="table-row-border-bottom">
                                        <td>
                                            <div className="accro_hd" style={{ margin: "20px 0" }}>
                                                <div className="accro_img">
                                                    <ProfilePic imgUrl={data.trainerPic ? data.trainerPic : defaultUser} size={50} urlDetail={'/trainers/detail-trainer?userId=' + data.userId} />
                                                </div>
                                                <div className="accro_details accro_details-trainers">
                                                    {data.trainerName}<br />
                                                    <div className="std">Average score</div>
                                                </div>
                                                <div className="completed-side-report score std-b-51">                                                    
                                                        {data.averageScore ? parseFloat(data.averageScore).toFixed(1) : ' '}
                                                </div>
                                            </div>
                                            <div className="report-skill-header-container">
                                                <div className="report-skill-column-border-left">
                                                    <img src={trainerGrey} className="report-skill-small-thumb" style={{marginRight:"15px"}} />
                                                    <div>
                                                        {t('general.reportstarted')}<br/>
                                                            <div className="std-b-51">{data.createdDate}</div>
                                                    </div>
                                                </div>
                                                <span className="report-skill-header-divider"/>
                                                <div className="report-skill-column-border-right">
                                                        <img src={data.status == 'Completed' ? checklistCompleted : checklistCompletedGray} className="report-skill-small-thumb" style={{ marginRight: "20px" }} />
                                                    <div>
                                                        {data.status == 'Completed' ? t('general.reportcompleted') : t('general.lastactivity')}<br/>
                                                            <div className="std-b-51">{data.modifiedDate}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ marginTop: "20px" }}>
                                                {
                                                    data.playerReportDetailsSkill &&
                                                    data.playerReportDetailsSkill.map((sr) => (
                                                        <div>
                                                            <div className="accro_de">
                                                                <div className="rat_name">{sr.skillName}</div>
                                                                <div className="rat_number"><span>{sr.score}</span></div>
                                                            </div>
                                                            
                                                            {/*<div className={sr.comment != null && sr.comment.trim() != "" ? "accro_comment" : "accro_comment no_display"} >*/}
                                                            <div className={data.hasFeedback ? "accro_comment" : "accro_comment no_display"} >
                                                                <h3>{t('general.feedback')}</h3>
                                                                <Linkify properties={{ target: '_blank', style: { color: '#16a3fc' } }}>{sr.comment}</Linkify>
                                                            </div>
                                                            
                                                        </div>))
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div style={{ background: "white", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", padding: "1px" }}>
                                    <div className="accro_feedback">
                                        <h3>{t('general.notes')}</h3>                                        
                                        <Linkify properties={{ target: '_blank', style: { color: '#16a3fc' } }}>{data.feedback}</Linkify>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default connect((state) => {
    return {
        //termsAndPrivacy: termsAndPrivacyStateSelector(state),
    }
},
    dispatch => bindActionCreators({
        //getTermsAndPrivacy: getTermsAndPrivacyAction,
    }, dispatch))(withTranslation()(DetailReportItem));