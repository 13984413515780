import { toast } from 'react-toastify';
import { webAppUrl, tokenKey } from '../../constants';
import fetchPlayerOverview from '../../../store/TeamDetail/Action/fetchPlayerOverview';

export default (teamPlayers,teamId) => async (dispatch) => {
    let url = `${webAppUrl}/api/Player/RemovePlayersFromTeam`;

    let response = await fetch(url, {
        method: `POST`,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        }
        , body: JSON.stringify(teamPlayers)
    });

    if (response.ok) {
        toast.success(`Succesfully removed players`, {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(fetchPlayerOverview(teamId));

    }
    else {
        toast.success(`Failed remove data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
