import { toast } from 'react-toastify';

import { tokenKey, updateManager, authUrl } from '../../constants';

import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default (entity) => async (dispatch) => {

    const token = sessionStorage.getItem(tokenKey);
    const body = JSON.stringify({
        "id": entity.id,
        "firstName": entity.firstname,
        "lastName": entity.lastname,
        "nationalityId": entity.nationalityid,
        "birthPlace": entity.birthplace,
        "birthDate": entity.birthdate,
        "email": entity.email,
        "phoneNumber": entity.telephone,
        "address": entity.address,
        "photo": entity.photo,
        "position": entity.position
    });

    const result = await fetchWithBearerToken(`${authUrl}/Account/UpdateUser`, 'POST', body);
    if (result.ok) {
        const updateResult = await result.json();
        if (updateResult.isSucceeded == false)

            toast.error("Account Updated", {
                position: toast.POSITION.TOP_RIGHT
            });
        if (updateResult.isSucceeded == true)
            toast.success("Account Updated", {
                position: toast.POSITION.TOP_RIGHT
            });
        window.location.reload();
        dispatch({ type: updateManager, result: new Date().toString() });
    }
    else
        toast.error("Update fail", {
            position: toast.POSITION.TOP_RIGHT
        });

};