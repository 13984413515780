import { tokenKey, webAppUrl, addPlayer, getPlayerOverview } from '../../constants';
import { toast } from 'react-toastify';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default () => async (dispatch) => {
    const response = await fetchWithBearerToken(`${webAppUrl}/api/Player/GetUserManagerOverviewByClubId`);

    if (response.ok) {
        const players = await response.json();
        dispatch({
            type: getPlayerOverview,
            players
        });
    }
    else {
        toast.error(`Failed fetching data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};