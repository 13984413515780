import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import usersReducer from './users/reducers/usersReducer';
import manager from './Manager/reducers';
import authReducer from './auth/authReducer';
import masterDataReducer from './MasterData/reducers';
import playerReducer from './Players/reducers';
import trainerReducer from './Trainers/reducers';
import pictureReducer from './profilePicture/reducer';
import teamReducer from './Teams/reducers';
import teamDetailReducer from './TeamDetail/reducers';
import dashboardReducer from './Overview/reducers';

export default function configureStore(history, initialState) {

    const reducers = {
        auth: authReducer,
        users: usersReducer,
        manager,
        masterData: masterDataReducer,
        player: playerReducer,
        trainer: trainerReducer,
        profilePicture: pictureReducer,
        team: teamReducer,
        teamDetail: teamDetailReducer,
        dashboard: dashboardReducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
