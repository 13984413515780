import React, { Component } from 'react';

export default class CircularProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Size of the enclosing square
        const sqSize = this.props.sqSize;
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - dashArray * this.props.percentage / 100;

        return (
            <svg
                width={this.props.sqSize}
                height={this.props.sqSize}
                viewBox={viewBox}>
                <circle
                    className="circle-background"
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`} />
                <circle
                    className="circle-progress"
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: -dashOffset // - anticlockwise. Remove the minus(-) to revert clockwise
                    }} />
                <text
                    className="circle-text-percentage"
                    x="50%"
                    y="40%"
                    dy=".3em"
                    textAnchor="middle">
                    {`${this.props.percentage} %`}
                </text>

                <switch><foreignObject x="25%" y="50%" width="65" height="40">
                    <p xmlns="http://www.w3.org/1999/xhtml" className="extra">
                        {`${this.props.extraText}`}
                        </p>
                </foreignObject>
                </switch>
            </svg>
        );
    }
}

CircularProgressBar.defaultProps = {
    sqSize: 200,
    percentage: 25,
    strokeWidth: 10
};

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            percentage: 25
        };

        this.handleChangeEvent = this.handleChangeEvent.bind(this);
    }

    handleChangeEvent(event) {
        this.setState({
            percentage: event.target.value
        });
    }

    render() {
        return (
            <div>
                <CircularProgressBar
                    strokeWidth="10"
                    sqSize="200"
                    percentage={this.state.percentage} />
            </div>
        );
    }
}