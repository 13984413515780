import { fromJS } from 'immutable';
import '../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import MaterialTable from './MaterialTable';
import { Modal } from 'reactstrap';
import imageUpload from '../images/upload.jpg';
import addTeam from '../../store/Teams/Action/addTeam';
import fetchTeamOverview from '../../store/Teams/Action/fetchTeamOverview';
import { bindActionCreators } from 'redux';
import TextInputField from '../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import removeTeams from '../../store/Teams/Action/removeTeams';
import getTeams from '../../store/MasterData/getTeams';
import clearTrainers from '../../store/Trainers/actions/clearTrainerData';
import uploadPicture from '../../store/profilePicture/uploadPicture';
import './Teams.css';
import getSeason from '../../store/MasterData/getSeason';
import ModalDelete from '../SharedComponents/ModalDelete';

class Teams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            seasonDropdownIsOpen: false,
            fields: fromJS({
                teamname: "",
                division: "",
            }),
            teamoverview: false,
            errors: fromJS({}),
            deleteModal: false,
            deleteTeamsRow: null,
            filteredSeason: '',
            isinitload: true,
            isVisible: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideLoading() {
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "hidden";
    }

    hideComponent() {
        this.setState({ isVisible: true });
    }

    toggle = (e) => {

        const { modal, fields } = this.state;

        let newFieldsState = fields.set('teamname', '');
        newFieldsState = newFieldsState.set('division', '');

        this.setState({
            modal: !modal,
            fields: newFieldsState
        });
    }

    toggleDeleteModal = () => {
        const { deleteModal } = this.state;
        this.setState({
            deleteModal: !deleteModal
        });
    }

    traineroverviewstate = (e) => {
        const { traineroverview } = this.state;
        this.setState({
            traineroverview: !traineroverview
        });
        this.props.fetchTeamOverview(!traineroverview)
            .then(t => this.hideLoading());
    }

    handleChange(field, value) {

        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    handleSubmit() {
        const { fields, modal } = this.state;
        const { t } = this.props;
        this.props.addTeam(fields.toJS(), t)
            .then(t => this.hideLoading());
        this.setState({
            modal: !modal
        });
    }

    saveUploadedPhoto(e) {

        this.setState({
            fields: this.state.fields.set('photo', e.id),
            errors: this.state.errors.delete('photo')
        });
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { t } = this.props;
        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '500000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('imgTemp', URL.createObjectURL(files[0]));

        newFields = newFields.set('photoDate', dateTag);

        this.setState({
            fields: newFields
        });

        this.props.uploadPicture(formData, dateTag, null, t)
            .then(t => this.hideLoading());
    }

    closeModal() {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    }

    AddTeamModal = (modal) => {

        const { fields, errors } = this.state;
        const { t } = this.props;
        const selectedPict = fields.get('imgTemp') !== null ? fields.get('imgTemp') : imageUpload;

        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={this.toggle} className="trainermodal">
                <div className="modal-body modal-user modal-extra-body">
                    <h2>{t('general.add') + ' ' + t('teams.singlelowerteams')}</h2>
                    <div className="add_pay_section">
                        <div className="add_details">
                            <table className="details_table_add">
                                <tbody>
                                    <tr>
                                        <td>{t('teams.teamname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'teamname'}
                                                value={fields.get('teamname')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('teamname')} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('generaldetail.division')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'division'}
                                                value={fields.get('division')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('division')} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="delete_btn_holder">
                                <a href="#" onClick={(e) => { e.preventDefault(); this.closeModal(); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleSubmit(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">{t('general.submit')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        const { traineroverview } = this.state;
        Promise.all([
            this.props.getTeams(),
            this.props.getSeason()])
            .then(t => {
                this.hideLoading();
                this.hideComponent();
            });
        //this.props.getTrainers(traineroverview);
    }

    handleRemoveTeams = (teams) => {
        const { deleteModal } = this.state;
        const { t } = this.props;
        if (teams.size == 0) {
            toast.success(t('validationerror.noselectedteams'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            this.setState({
                deleteModal: !deleteModal,
                deleteTeamsRow: teams.toJS()
            });
        }
    }

    okRemove = () => {
        const { deleteTeamsRow, deleteModal } = this.state;
        const { t } = this.props;
        this.props.removeTeams(deleteTeamsRow, t)
            .then(t => this.hideLoading());
        this.setState({
            deleteModal: !deleteModal,
            deleteTrainerRow: null
        });
    }

    handleTeamChange(e) {
        this.setState({
            fields: this.state.fields.set('teamId', e.target.value),
            errors: this.state.errors.delete('teamId'),
        });
    }

    componentDidUpdate(prevProps) {
        const { fields, traineroverview, isinitload } = this.state;

        const { addTrainerResult, profilePictureUpload, seasons } = this.props;

        if (profilePictureUpload && profilePictureUpload.dateTimeTag == fields.get('photoDate')) {
            if (prevProps.profilePictureUpload && profilePictureUpload.id !== prevProps.profilePictureUpload.id)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => this.hideLoading());

            //if prev profilepic is undefined
            if (profilePictureUpload && prevProps.profilePictureUpload === undefined)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => this.hideLoading());
        }

        if (addTrainerResult && prevProps.addTrainerResult !== addTrainerResult) {
            this.props.fetchTeamOverview(traineroverview)
                .then(t => this.hideLoading());
        }

        var initfilteredSeason = '';
        if (isinitload && seasons.length > 0) {
            seasons.map((row, index) => {
                if (row.isCurrent) {
                    initfilteredSeason = row.seasonName;
                    this.props.fetchTeamOverview(row.id)
                        .then(t => this.hideLoading());
                }
            });
            this.setState({
                isinitload: false,
                filteredSeason: initfilteredSeason
            });
        }
    }

    toggleItem = () => {
        const { seasonDropdownIsOpen } = this.state;

        this.setState({
            seasonDropdownIsOpen: !seasonDropdownIsOpen
        });
    }

    handleOnSelectSeason = (seasonId, seasonName) => {
        this.props.fetchTeamOverview(seasonId)
            .then(t => this.hideLoading());
        this.setState({
            filteredSeason: seasonName
        });
    }

    render() {
        const { modal, seasonDropdownIsOpen, deleteModal, filteredSeason, isVisible } = this.state;
        const { teamOverviewData, seasons, getTrainers, t } = this.props;
        const headCellsTrainerOverview = [
            { id: 'teamName', numeric: false, disablePadding: true, label: t('teams.title') },
            { id: 'topTrainerName', numeric: false, disablePadding: false, label: t('trainers.title') },
            { id: 'overallProgress', numeric: true, disablePadding: false, label: t('general.progress') },
            { id: 'totalPlayer', numeric: true, disablePadding: false, label: t('players.title') },
            { id: 'id', numeric: true, disablePadding: false, align: 'center', label: t('general.view') }];

        return (<div>
            {isVisible && (
            <MaterialTable
                toggle={this.toggle}
                manageroverviewstate={this.traineroverviewstate}
                headCells={headCellsTrainerOverview}
                rows={teamOverviewData}
                handleRemoveTeams={this.handleRemoveTeams}
                seasonDropDownIsOpen={seasonDropdownIsOpen}
                toggleItem={this.toggleItem}
                seasons={seasons}
                getTrainers={getTrainers}
                filteredSeason={filteredSeason}
                handleOnSelectSeasonFilterd={this.handleOnSelectSeason}
                t={t}
                />)}
            {this.AddTeamModal(modal)}
            <ModalDelete modal={deleteModal} closeModal={this.toggleDeleteModal} okRemove={this.okRemove} toggle={this.toggleDeleteModal} />
        </div>);
    }
}

export default connect((state) => {
    return {
        teamOverviewData: state.team.teams,
        addTrainerResult: state.manager.addManagerResult,
        allTeams: state.masterData.allTeams,
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        seasons: state.masterData.seasons
    }
},
    dispatch => bindActionCreators({
        getTeams,
        addTeam,
        fetchTeamOverview,
        clearTrainers,
        uploadPicture,
        removeTeams,
        getSeason
    }, dispatch))(withTranslation()(Teams));