import { fetchClubSeason,getAllTeams, getAllPositions } from '../constants';

const initialState = {
    getAllTeams: [],
    seasons: [],
    positions:[]
}
export default (state, action) => {
    state = state || initialState;
    
    if (action.type === getAllTeams) {
        return { ...state, allTeams: action.allTeams };
    }

    if (action.type === fetchClubSeason) {
        return { ...state, seasons: action.seasons };
    }

    if (action.type === getAllPositions) {
        return { ...state, positions: action.result};
    }

    return state;
}