import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import ProtectedRoute from './ProtectedRoute';
import Overview from '../components/Overview/Overview';
import Teams from '../components/Teams/Teams';
import Manager from '../components/Manager/Manager';
import Version from '../components/Version';
import Login from '../components/Login/Login';
import ForgotPassword from '../components/Users/ForgotPassword/ForgotPassword';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import ChangePassword from '../components/Users/ChangePassword/ChangePassword';
import TokenValidation from '../components/Users/TokenValidation';
import Players from '../components/Players/Players';
import TermsAndPrivacy from '../components/TermsAndPrivacy/TermsAndPrivacy';
import Trainers from '../components/Trainers/Trainers';
import DetailManager from '../components/Manager/DetailManager/DetailManager';
import TeamDetail from '../components/TeamDetail/TeamDetail';
import TrainerDetail from '../components/Trainers/TrainerDetail/TrainerDetail';
import DetailPlayer from '../components/Players/DetailPlayer/DetailPlayer';
import GeneralSettings from '../components/Settings/General/GeneralSettings';
import Season from '../components/Settings/Season';
import Congratulation from '../components/Settings/Congratulation';
import PasswordChanged from '../components/Settings/PasswordChanged';
import { AdminHome } from '../components/Administrator/AdminPages'


class AllRoutes extends Component {
    render() {
        return (
            <Switch>
                <Route exact path='/admin' component={AdminHome} />
                <Route exact path='/passwordchanged' component={PasswordChanged} />
                <Route exact path='/congratulation' component={Congratulation} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/forgotpassword' component={ForgotPassword} />                
                <Route exact path='/tokenvalidation' component={TokenValidation} />                
                <Route exact path='/account/tokenconfirmation' component={TokenValidation} />                
                <Route exact path='/version' component={Version} />
                <Route exact path='/changepassword' component={ChangePassword} />
                <Route exact path='/termandprivacy' component={TermsAndPrivacy} />
                <ProtectedRoute exact path='/' component={Overview} />
                <ProtectedRoute exact path='/teams' component={Teams} />
                <ProtectedRoute exact path='/manager' component={Manager} />  
                <ProtectedRoute exact path='/players' component={Players} />  
                <ProtectedRoute exact path='/trainers' component={Trainers} />
                <ProtectedRoute exact path='/manager/detail-manager' component={DetailManager} />
                <ProtectedRoute exact path='/teams/team-detail' component={TeamDetail} />
                <ProtectedRoute exact path='/trainers/detail-trainer' component={TrainerDetail} />
                <ProtectedRoute exact path='/players/detail-player' component={DetailPlayer} />
                <ProtectedRoute exact path='/general-settings' component={GeneralSettings} />
                <ProtectedRoute exact path='/season' component={Season} />
                <Route path='*' exact component={PageNotFound} />
            </Switch>
        )
    }
}

export default (AllRoutes);
