import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sidebarUrls } from '../constants/urls';
import './bootstrap_css/bootstrap.min.css';
import './bootstrap_css/style.css';
import './bootstrap_css/fonts/font.css';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import './Layout.css';
import SideNavMenu from './SideNavMenu/SideNavMenu';
import { loggedInUserSelector } from '../store/users/selectors/authSelector';
import Login from './Login/Login';
import { routingLocationSelector } from '../store/general/selectors/generalSelector';
import { Redirect } from 'react-router-dom';
import loginWithBearerToken from '../store/users/actions/loginUserWithBearerToken';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchIsOpen: false,
            profileboxopen: false,
        }

        this.toggleOpen = this.toggleOpen.bind(this);
        this.toogleProfileBoxOpen = this.toogleProfileBoxOpen.bind(this);
        this.toogleProfileBoxOutside = this.toogleProfileBoxOutside.bind(this);
    }

    componentDidMount() {
        const width = this.container.offsetWidth;
        // This is done to simulate the original javascript code, for the width value, see style.css:433
        if (width >= 1199) {
            this.toggleOpen(true);
        }

        window.addEventListener('resize', this.resize);
    }

    resize = () => {
        if (window.innerWidth > 1200) {
            this.toggleOpen(true);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    toggleSearch = (con) => {
        const { searchIsOpen, profileboxopen } = this.state;        
        this.setState({
            searchIsOpen: con,
            profileboxopen: profileboxopen ? !profileboxopen : profileboxopen,
        })
    }

    toggleOpen(setOpen) {
        const { profileboxopen } = this.state;
        this.setState({
            sideNavOpen: setOpen,
            //profileboxopen: !profileboxopen,
        })
    }

    toogleProfileBoxOutside() {
        const { profileboxopen } = this.state;        
        this.setState({
            profileboxopen: profileboxopen ? !profileboxopen : profileboxopen,
        })
    }

    toogleProfileBoxOpen() {
        const { profileboxopen } = this.state;
        this.setState({
            profileboxopen: !profileboxopen,
        })
    }

    render() {
        const { userLoggedIn, routingLocation } = this.props;
        const { searchIsOpen, profileboxopen } = this.state;
        const path = routingLocation.pathname;

        // If user is not logged in, Show page directly.
        if (userLoggedIn.accessToken) {
            return (
                <div ref={el => (this.container = el)}>
                    <div className="dashboard_holder">
                        <SideNavMenu
                            toggleOpen={this.toggleOpen}
                            isOpen={this.state.sideNavOpen}
                            urls={sidebarUrls}
                            toogleProfileBoxOutside={this.toogleProfileBoxOutside} />
                        <div className="dashboard_right">
                            <Header
                                toggleSearch={this.toggleSearch}
                                searchIsOpen={this.state.searchIsOpen}
                                profilBoxStatus={profileboxopen}
                                toogleProfileBoxOpen={this.toogleProfileBoxOpen}  
                                toogleProfileBoxOutside={this.toogleProfileBoxOutside} />
                            <div className="overview_body" onClick={() => { this.toggleSearch(false) }}>
                                <div className="mobile_menu">
                                    <span onClick={() => this.toggleOpen(true)}>&#x2630; </span>
                                </div>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    <Footer onClick={this.toogleProfileBoxOutside} />
                </div>
            );
        }
        if (path == '/') {
            <div ref={el => (this.container = el)}>
                {this.props.children}
            </div>
        }
        return (
            <div ref={el => (this.container = el)}>
                {this.props.children}
            </div>
        )

    }
}

export default connect(
    (state) => {
        return {
            routingLocation: routingLocationSelector(state),
            userLoggedIn: loggedInUserSelector(state)
        }
    },
    dispatch => bindActionCreators({
        loginUserWithBearerToken: loginWithBearerToken,
    }, dispatch),
)(Layout);
