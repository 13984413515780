import { fetchTeamOverview, clearTrainerDataType } from '../constants';
import { Map } from 'immutable';

const initialState = {
    teams: Map(),
}
export default (state, action) => {
    state = state || initialState;

    if (action.type === fetchTeamOverview) {        
        return { ...state, teams: action.teams };
    }

    return state;
}