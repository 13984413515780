import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                Powered by Pro Season
            </div>
         );
    }
}
