import { toast } from 'react-toastify';
import { webAppUrl, tokenKey } from '../../constants';
import fetchTrainerOverview from '../../../store/TeamDetail/Action/fetchTrainerOverview';

export default (teamTrainers,teamId) => async (dispatch) => {
    let url = `${webAppUrl}/api/Trainer/RemoveTrainersFromTeam`;

    let response = await fetch(url, {
        method: `POST`,
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        }
        , body: JSON.stringify(teamTrainers)
    });

    if (response.ok) {
        toast.success(`Succesfully removed trainers`, {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch(fetchTrainerOverview(teamId));
    }
    else {
        toast.success(`Failed remove data. ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
