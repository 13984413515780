import { getPlayerDevelopmentDashboard, getTotalHeader, getTrainerActivity, fetchTeamOverviewDashboard, fetchMainDashboard } from '../constants';

const initialState = {
    playerDevelopment: null,
    totalHeader: null,
    trainerActivity: null,
    teamOverview: null,
    mainDashboard:null
}
export default (state, action) => {
    state = state || initialState;

    if (action.type === getPlayerDevelopmentDashboard) {
        return { ...state, playerDevelopment: action.result};
    }

    if (action.type === getTotalHeader) {
        return { ...state, totalHeader: action.result };
    }

    if (action.type === getTrainerActivity) {
        return { ...state, trainerActivity: action.result };
    }

    if (action.type === fetchTeamOverviewDashboard) {
        return { ...state, teamOverview: action.result };
    }

    if (action.type === fetchMainDashboard) {
        return { ...state, mainDashboard: action.result };
    }

    return state;
}