import { fromJS } from 'immutable';
import '../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import MaterialTable from './MaterialTable';
import { Modal } from 'reactstrap';
import imageUpload from '../images/upload.jpg';
import addManager from '../../store/Manager/InvitedManager/addManager';
import getManagers from '../../store/Manager/actions/fetchManagerOverview';
import getSeason from '../../store/MasterData/getSeason';
import { bindActionCreators } from 'redux';
import TextInputField from '../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';
import { userId } from '../../store/constants';
import { toast } from 'react-toastify';
import removeManagers from '../../store/Manager/actions/removeManagers';
import uploadPicture from '../../store/profilePicture/uploadPicture';
import ModalDelete from '../SharedComponents/ModalDelete';
import reInviteManager from '../../store/Manager/actions/reInviteManager';
import getActiveManagersBySeason from '../../store/Manager/actions/fetchActiveManagersBySeason';

class Manager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRendering: true,
            seasonDropdownIsOpen: false,
            season: [
                {
                    id: 0,
                    title: 'Season 1',
                    selected: false,
                    key: 'season'
                },
                {
                    id: 1,
                    title: 'Season 2',
                    selected: false,
                    key: 'season'
                }],
            modal: false,
            deleteManagerRow: null,
            deletemodal: false,
            fields: fromJS({
                firstName: "",
                lastName: "",
                email: "",
                role: "Manager",
                phoneNumber: "",
                profilePicture: "file.png",
                imgTemp: null,
                photoDate: null,
                photo: null
            }),
            manageroverview: true,
            filtermanager: '',
            filteredSeasonId: '',
            errors: fromJS({}),
            isinitload: true,
            isVisible: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent() {
        this.setState({ isVisible: true });
    }

    toggle = (addModal) => {

        const { modal, deletemodal, fields } = this.state;

        if (addModal) {


            let newFieldsState = fields.set('firstName', '');
            newFieldsState = newFieldsState.set('lastName', '');
            newFieldsState = newFieldsState.set('email', '');
            newFieldsState = newFieldsState.set('phoneNumber', '');
            newFieldsState = newFieldsState.set('imgTemp', null);
            newFieldsState = newFieldsState.set('photoDate', null);
            newFieldsState = newFieldsState.set('photo', null);

            this.setState({
                modal: !modal,
                fields: newFieldsState,
                errors: fromJS({})
            });
        }
        else {

            this.setState({
                deletemodal: !deletemodal
            });
        }

    }

    manageroverviewstate = (e) => {
        const { manageroverview, filteredSeasonId } = this.state;
        this.setState({
            manageroverview: !manageroverview,
            isRendering: true
        });
        if (!manageroverview)
            this.props.getActiveManagersBySeason(filteredSeasonId)
                .then(t => this.hideLoading())
        else
            this.props.getManagers(!manageroverview)
                .then(t => this.hideLoading())
    }

    handleChange(field, value) {

        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    handleSubmit() {
        const { fields, modal, manageroverview } = this.state;
        let errorList = fromJS({});
        const requiredField = ['firstName', 'lastName', 'email']
        const allField = ['firstName', 'lastName', 'email', 'phoneNumber'];

        const simpleEmailRegexp = new RegExp('[^@]+@[^\.]+\..+');

        requiredField.map((x) => {
            if (fields.get(x) == undefined || fields.get(x) == '') errorList = errorList.set(x, true)
        });

        allField.map((x) => {
            if (fields.get(x).length > 50) errorList = errorList.set(x, true)
        });

        if (!simpleEmailRegexp.test(fields.get('email'))) {
            errorList = errorList.set('email', true);
        }

        if (errorList.size > 0) {
            this.setState({
                errors: errorList
            });

            return;
        }
        this.props.addManager(fields.toJS())
            .then(t => this.hideLoading());

        this.setState({
            modal: !modal
        });
    }

    closeModal = (addModal) => {
        const { modal, deletemodal } = this.state;
        if (addModal) {
            this.setState({
                modal: !modal
            });
        }
        else {
            this.setState({
                deletemodal: !deletemodal
            });
        }
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { t } = this.props;

        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '6000000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('imgTemp', URL.createObjectURL(files[0]));

        newFields = newFields.set('photoDate', dateTag);

        this.setState({
            fields: newFields
        });

        this.props.uploadPicture(formData, dateTag, null, t)
            .then(t => this.hideLoading());
    }

    ModalExample = (modal) => {

        const { fields, errors } = this.state;
        const { t } = this.props;
        const selectedPict = fields.get('imgTemp') !== null ? fields.get('imgTemp') : imageUpload;

        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={() => { this.toggle(true) }} className="trainermodal">
                <div className="modal-body">
                    <h2>{t('general.invite') + ' ' + t('managers.lowermanagersingle')}</h2>
                    <div className="add_pay_section">
                        <div className="add_pic_section">
                            <div className="upload_btn">
                                <label>
                                    <input type="file" onChange={this.uploadFileChange} style={{ display: "none" }} />
                                    {
                                        selectedPict != imageUpload ? (<img src={selectedPict} alt="" className="upload-picture-box" />) :
                                            (<img src={selectedPict} alt="" />)
                                    }
                                </label>
                            </div>
                            <p className="photo-upload-caption"><small>{t('general.photonote')}</small></p>
                        </div>
                        <div className="add_details">
                            <table className="details_table_add">
                                <tbody>
                                    <tr>
                                        <td>{t('general.role')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <select className="position_select">
                                                <option>{t('managers.titlesingle')}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.firstname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'firstName'}
                                                value={fields.get('firstName')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('firstName')} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.lastname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'lastName'}
                                                value={fields.get('lastName')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('lastName')} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.e-mail')}</td>
                                        <td className="dt_gap"></td>
                                        <td><TextInputField
                                            disabled={false}
                                            field={'email'}
                                            value={fields.get('email')}
                                            onChange={(field, value) => this.handleChange(field, value)}
                                            hasError={errors.has('email')} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="delete_btn_holder">
                                <a href="#" onClick={(e) => { e.preventDefault(); this.closeModal(true); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleSubmit(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">{t('general.add')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        const { manageroverview } = this.state;
        //this.props.getManagers(manageroverview);
        this.props.getSeason()
            .then(t => {
                this.hideLoading();
                this.hideComponent();
            });

        this.setState({ isRendering: true }, () => {
            this.setState({ isRendering: false });
        });
    }

    handleRemoveManagers = (managers) => {
        const { deletemodal } = this.state;
        const { t } = this.props;
        var isValidItems = true;
        if (managers.size == 0) {
            toast.success(t('validationerror.failednoselectedmgr'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            managers.map((row, index) => {
                var obj = row.toJS();
                if (obj.Id == sessionStorage.getItem(userId)) {
                    toast.success(t('validationerror.failedcontainsmgr'), {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    isValidItems = false;
                    return;
                }
            });
            if (isValidItems)
                this.setState({ deletemodal: !deletemodal, deleteManagerRow: managers });
        }
    }

    okRemove = () => {
        const { deleteManagerRow, manageroverview, deletemodal } = this.state;
        const { t } = this.props;
        this.props.removeManagers(deleteManagerRow.toJS(), sessionStorage.getItem(userId), manageroverview, t)
            .then(t => this.hideLoading());

        this.setState({
            deletemodal: !deletemodal,
            deleteManagerRow: null
        });
    }

    saveUploadedPhoto(e) {

        this.setState({
            fields: this.state.fields.set('photo', e.id),
            errors: this.state.errors.delete('photo')
        });
    }

    toggleItem = () => {
        const { seasonDropdownIsOpen } = this.state;
        this.setState({
            seasonDropdownIsOpen: !seasonDropdownIsOpen
        });
    }

    componentDidUpdate(prevProps) {
        const { addManagerResult, profilePictureUpload, seasons } = this.props;
        const { fields, manageroverview, seasonDropdownIsOpen, isinitload } = this.state;

        if (profilePictureUpload && profilePictureUpload.dateTimeTag == fields.get('photoDate')) {
            if (prevProps.profilePictureUpload && profilePictureUpload.id !== prevProps.profilePictureUpload.id)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => this.hideLoading());

            //if prev profilepic is undefined
            if (profilePictureUpload && prevProps.profilePictureUpload === undefined)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => this.hideLoading());
        }

        if (addManagerResult && prevProps.addManagerResult !== addManagerResult) {
            this.props.getManagers(manageroverview)
                .then(t => this.hideLoading());
        }

        var initfilteredSeason = '';
        var initfilteredSeasonId = '';
        if (isinitload && seasons.length > 0) {
            seasons.map((row, index) => {
                if (row.isCurrent) {
                    initfilteredSeason = row.seasonName;
                    initfilteredSeasonId = row.id;
                    this.props.getActiveManagersBySeason(row.id)
                        .then(t => this.hideLoading());
                }
            });
            this.setState({
                isinitload: false,
                filtermanager: initfilteredSeason,
                filteredSeasonId: initfilteredSeasonId
            });
        }

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps))
            this.setState({ isRendering: false });
    }

    handleReinvite = (userId) => {
        if (userId)
            this.props.reInviteManager(userId)
                .then(t => this.hideLoading());

    }

    handleOnSelectSeason = (seasonId, seasonName) => {
        const { manageroverview } = this.state;
        if (seasonId)
            this.props.getActiveManagersBySeason(seasonId)
                .then(t => this.hideLoading());
        else
            this.props.getManagers(manageroverview)
                .then(t => this.hideLoading());
        this.setState({
            filtermanager: seasonName,
            filteredSeasonId: seasonId
        });
    }

    hideLoading() {
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "hidden";
    }

    render() {
        const { modal, manageroverview, deletemodal, seasonDropdownIsOpen, filtermanager, isVisible } = this.state;
        const { managerOverviewData, seasons, t } = this.props;
        const headCellsManagerOverview = [
            { id: 'name', numeric: false, disablePadding: true, label: t('managers.title') },
            { id: 'datestarted', numeric: false, disablePadding: false, label: t('general.activesince') },
            { id: 'lastlogin', numeric: true, disablePadding: false, label: t('general.lastlogin') },
            { id: 'action', numeric: true, disablePadding: false, align: 'center', label: t('general.view') }];

        const headCellsManagerInvited = [
            { id: 'name', numeric: false, disablePadding: true, label: t('managers.title') },
            { id: 'emailAddress', numeric: false, disablePadding: true, label: t('general.e-mail') },
            { id: 'invitedby', numeric: true, disablePadding: false, label: t('general.invitedby') },
            { id: 'invitationDate', numeric: true, disablePadding: false, label: t('general.invitedate') },
            { id: 'status', numeric: true, disablePadding: false, label: t('general.status') },
            { id: 'action', numeric: true, disablePadding: false, align: 'center', label: t('general.action') }];

        return (<div>
            {isVisible && (
                <MaterialTable
                    isRendering={this.state.isRendering}
                    toggle={this.toggle}
                    manageroverviewstate={this.manageroverviewstate}
                    headCells={manageroverview ? headCellsManagerOverview : headCellsManagerInvited}
                    rows={managerOverviewData}
                    ismanageroverview={manageroverview}
                    handleRemoveManagers={this.handleRemoveManagers}
                    seasonDropDownIsOpen={seasonDropdownIsOpen}
                    toggleItem={this.toggleItem}
                    seasons={seasons}
                    handleReinvite={this.handleReinvite}
                    filterManagerSeason={filtermanager}
                    filterHandleOnSelectSeason={this.handleOnSelectSeason}
                    translation={t}
                />)}
            {this.ModalExample(modal)}
            <ModalDelete modal={deletemodal} closeModal={this.closeModal} okRemove={this.okRemove} toggle={this.toggle} />
        </div>);
    }
}

export default connect((state) => {
    return {
        managerOverviewData: state.manager.managers,
        addManagerResult: state.manager.addManagerResult,
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        seasons: state.masterData.seasons
    }
},
    dispatch => bindActionCreators({
        addManager,
        getManagers,
        removeManagers,
        uploadPicture,
        getSeason,
        reInviteManager,
        getActiveManagersBySeason
    }, dispatch))(withTranslation()(Manager));