import { fromJS } from 'immutable';
import '../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import MaterialTable from './MaterialTable';
import { Modal } from 'reactstrap';
import imageUpload from '../images/upload.jpg';
import addTrainer from '../../store/Trainers/actions/addTrainer';
import getTrainers from '../../store/Trainers/actions/fetchTrainerOverivew';
import { bindActionCreators } from 'redux';
import TextInputField from '../SharedComponents/TextInputField';
import { withTranslation } from 'react-i18next';
import { userId } from '../../store/constants';
import { toast } from 'react-toastify';
import removeTrainers from '../../store/Trainers/actions/removeTrainers';
import getTeams from '../../store/MasterData/getTeams';
import getSeason from '../../store/MasterData/getSeason';
import clearTrainers from '../../store/Trainers/actions/clearTrainerData';
import uploadPicture from '../../store/profilePicture/uploadPicture';
import reInviteTrainer from '../../store/Trainers/actions/reInviteTrainer';
import ModalDelete from '../SharedComponents/ModalDelete';

class Trainers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRendering: true,
            seasonDropdownIsOpen: false,
            deleteTrainerRow: null,
            deleteModal: false,
            modal: false,
            fields: fromJS({
                firstName: "",
                lastName: "",
                email: "",
                role: "Trainer",
                phoneNumber: "",
                profilePicture: "file.png",
                teamId: "",
                imgTemp: null,
                photoDate: null,
                photo: null
            }),
            traineroverview: true,
            errors: fromJS({}),
            filteredSeason: '',
            isinitload: true,
            isVisible: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFileChange = this.uploadFileChange.bind(this);
        this.saveUploadedPhoto = this.saveUploadedPhoto.bind(this);
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent() {
        this.setState({ isVisible: true });
    }

    toggle = (addModal) => {

        const { modal, deleteModal, fields } = this.state;
        if (addModal) {
            let newFieldsState = fields.set('firstName', '');
            newFieldsState = newFieldsState.set('lastName', '');
            newFieldsState = newFieldsState.set('email', '');
            newFieldsState = newFieldsState.set('phoneNumber', '');
            newFieldsState = newFieldsState.set('imgTemp', null);
            newFieldsState = newFieldsState.set('photoDate', null);
            newFieldsState = newFieldsState.set('photo', null);

            this.setState({
                modal: !modal,
                fields: newFieldsState,
                errors: fromJS({})
            });
        }

        else {

            this.setState({
                deleteModal: !deleteModal
            });
        }

    }

    toggleItem = () => {
        const { seasonDropdownIsOpen } = this.state;
        this.setState({
            seasonDropdownIsOpen: !seasonDropdownIsOpen
        });
    }

    traineroverviewstate = (e) => {
        const { traineroverview } = this.state;
        this.setState({
            traineroverview: !traineroverview,
            isRendering: true
        });
        Promise.all([
            this.props.clearTrainers(),
            this.props.getTrainers(!traineroverview)])
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });
    }

    handleChange(field, value) {

        this.setState({
            fields: this.state.fields.set(field, value),
            errors: this.state.errors.delete(field),
        });
    }

    handleSubmit() {
        const { fields, modal } = this.state;
        const { t } = this.props;
        let errorList = fromJS({});
        const requiredField = ['firstName', 'lastName', 'email']
        const allField = ['firstName', 'lastName', 'email', 'phoneNumber'];
        const simpleEmailRegexp = new RegExp('[^@]+@[^\.]+\..+');

        requiredField.map((x) => {
            if (fields.get(x) == undefined || fields.get(x) == '') errorList = errorList.set(x, true)
        });

        allField.map((x) => {
            if (fields.get(x).length > 50) errorList = errorList.set(x, true)
        });

        if (!simpleEmailRegexp.test(fields.get('email'))) {
            errorList = errorList.set('email', true);
        }

        if (errorList.size > 0) {
            this.setState({
                errors: errorList
            });

            return;
        }
        this.props.addTrainer(fields.toJS(), t)
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });

        this.setState({
            modal: !modal
        });
    }

    saveUploadedPhoto(e) {

        this.setState({
            fields: this.state.fields.set('photo', e.id),
            errors: this.state.errors.delete('photo')
        });
    }

    uploadFileChange(e) {
        const files = e.target.files;
        const { t } = this.props;
        if (files === undefined) return;

        let formData = new FormData();
        let fileType = '';

        if (files[0] !== null) {
            fileType = files[0].type;
        }

        if (files[0].size > '6000000') {
            toast.success("file size limit", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/jpg') {

            toast.success("format not allowed", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        formData.append('file', files[0]);

        const dateTag = new Date().toString().replace('+', ' ');

        let newFields = this.state.fields.set('imgTemp', URL.createObjectURL(files[0]));

        newFields = newFields.set('photoDate', dateTag);

        this.setState({
            fields: newFields
        });

        this.props.uploadPicture(formData, dateTag, null, t)
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });
    }

    closeModal = (addModal) => {
        const { modal, deleteModal } = this.state;
        if (addModal) {
            this.setState({
                modal: !modal
            });
        }
        else {
            this.setState({
                deleteModal: !deleteModal
            });
        }
    }

    okRemove = () => {
        const { deleteTrainerRow, traineroverview, deleteModal } = this.state;
        const { t } = this.props;
        this.props.removeTrainers(deleteTrainerRow.toJS(), sessionStorage.getItem(userId), traineroverview, t)
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
            });

        this.setState({
            deleteModal: !deleteModal,
            deleteTrainerRow: null
        });
    }

    AddTrainerModal = (modal) => {

        const { fields, errors } = this.state;
        const { allTeams, t } = this.props;
        const selectedPict = fields.get('imgTemp') !== null ? fields.get('imgTemp') : imageUpload;

        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                toggle={() => { this.toggle(true) }} className="trainermodal">
                <div className="modal-body modal-user">
                    <h2>{t('general.invite') + ' ' + t('trainers.singletitlelower')}</h2>
                    <div className="add_pay_section">
                        <div className="add_pic_section">
                            <div className="upload_btn">
                                <label>
                                    <input type="file" onChange={this.uploadFileChange} style={{ display: "none" }} />
                                    {selectedPict != imageUpload ? (<img src={selectedPict} alt="" className="upload-picture-box" />) : (<img src={selectedPict} alt="" />)}
                                </label>
                            </div>
                            <p className="photo-upload-caption"><small>{t('general.photonote')}</small></p>
                        </div>
                        <div className="add_details">
                            <table className="details_table_add">
                                <tbody>
                                    <tr>
                                        <td>{t('general.role')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <select className="position_select">
                                                <option>{t('trainers.singletitle')}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('teams.singletitle')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <select onChange={(value) => this.handleTeamChange(value)} className="position_select">
                                                <option key="0" value="">{t('general.select') + ' ' + t('teams.singlelowerteams')}</option>
                                                {allTeams && allTeams.map(x => <option key={x.teamName} value={x.id}>{x.teamName}</option>)}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.firstname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'firstName'}
                                                value={fields.get('firstName')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('firstName')} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.lastname')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'lastName'}
                                                value={fields.get('lastName')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('lastName')} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('general.e-mail')}</td>
                                        <td className="dt_gap"></td>
                                        <td>
                                            <TextInputField
                                                disabled={false}
                                                field={'email'}
                                                value={fields.get('email')}
                                                onChange={(field, value) => this.handleChange(field, value)}
                                                hasError={errors.has('email')} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="delete_btn_holder">
                                <a href="#" onClick={(e) => { e.preventDefault(); this.closeModal(true); }} className="btn_cancel" data-dismiss="modal" aria-label="Close">{t('general.cancel')}</a>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleSubmit(); }} className="btn_add_modal" data-dismiss="modal" aria-label="Close">{t('general.add')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        const { traineroverview } = this.state;
        Promise.all([
            this.props.getTeams(),
            this.props.getSeason()])
            .then(t => {
                const loading = document.querySelector('.loading');
                if (loading)
                    loading.style.visibility = "hidden";
                this.hideComponent();
            });
        //this.props.getTrainers(traineroverview);

        this.setState({ isRendering: true }, () => {
            this.setState({ isRendering: false });
        });
    }

    handleReinvite = (userId) => {
        const { t } = this.props;
        if (userId)
            this.props.reInviteTrainer(userId, t)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
    }

    handleRemoveTrainers = (trainers) => {
        const { deleteModal } = this.state;
        const { t } = this.props;
        if (trainers.size == 0) {
            toast.success(t('validationerror.noselectedtrainers'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            this.setState({
                deleteModal: !deleteModal,
                deleteTrainerRow: trainers
            });
        }
    }

    handleTeamChange(e) {
        this.setState({
            fields: this.state.fields.set('teamId', e.target.value),
            errors: this.state.errors.delete('teamId'),
        });
    }

    componentDidUpdate(prevProps) {
        const { fields, traineroverview, isinitload } = this.state;

        const { addTrainerResult, profilePictureUpload, seasons } = this.props;

        if (profilePictureUpload && profilePictureUpload.dateTimeTag == fields.get('photoDate')) {
            if (prevProps.profilePictureUpload && profilePictureUpload.id !== prevProps.profilePictureUpload.id)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => {
                        const loading = document.querySelector('.loading');
                        if (loading)
                            loading.style.visibility = "hidden";
                    });

            //if prev profilepic is undefined
            if (profilePictureUpload && prevProps.profilePictureUpload === undefined)
                this.saveUploadedPhoto(profilePictureUpload)
                    .then(t => {
                        const loading = document.querySelector('.loading');
                        if (loading)
                            loading.style.visibility = "hidden";
                    });
        }

        if (addTrainerResult && prevProps.addTrainerResult !== addTrainerResult) {
            this.props.getTrainers(traineroverview)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
        }

        var initfilteredSeason = '';
        if (isinitload && seasons.length > 0) {
            seasons.map((row, index) => {
                if (row.isCurrent) {
                    initfilteredSeason = row.seasonName;
                    this.props.getTrainers(true, row.id)
                        .then(t => {
                            const loading = document.querySelector('.loading');
                            if (loading)
                                loading.style.visibility = "hidden";
                        });
                }
            });
            this.setState({
                isinitload: false,
                filteredSeason: initfilteredSeason
            });
        }

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps))
            this.setState({ isRendering: false });
    }

    handleOnSelectSeason = (seasonId, seasonName) => {
        const { traineroverview } = this.state;
        if (seasonId)
            this.props.getTrainers(true, seasonId)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });
        else
            this.props.getTrainers(traineroverview)
                .then(t => {
                    const loading = document.querySelector('.loading');
                    if (loading)
                        loading.style.visibility = "hidden";
                });

        this.setState({
            filteredSeason: seasonName
        });
    }

    render() {
        const { modal, traineroverview, deleteModal, seasonDropdownIsOpen, toggleItem, filteredSeason, isVisible } = this.state;
        const { trainerOverviewData, seasons, t } = this.props;

        const headCellsTrainerInvited = [
            { id: 'name', numeric: false, disablePadding: true, label: t('trainers.title') },
            { id: 'emailAddress', numeric: false, disablePadding: true, label: t('general.e-mail') },
            { id: 'invitedby', numeric: true, disablePadding: false, label: t('general.invitedby') },
            { id: 'invitationdate', numeric: true, disablePadding: false, label: t('general.invitedate') },
            { id: 'status', numeric: true, disablePadding: false, label: t('general.status') },
            { id: 'action', numeric: true, disablePadding: false, align: 'center', label: t('general.action') }];

        const headCellsTrainerOverview = [
            { id: 'name', numeric: false, disablePadding: true, label: t('trainers.title') },
            { id: 'topTeamName', numeric: false, disablePadding: true, label: t('teams.title') },
            { id: 'totalPlayer', numeric: false, disablePadding: false, align: 'left', label: t('players.title') },
            { id: 'overallProgress', numeric: true, disablePadding: false, align: 'left', label: t('general.progress') },
            { id: 'lastUpdate', numeric: true, disablePadding: false, label: t('general.lastupdate') },
            { id: 'id', numeric: true, disablePadding: false, align: 'center', label: t('general.view') }];

        return (<div>
            {isVisible && (
                <MaterialTable
                    isRendering={this.state.isRendering}
                    toggle={this.toggle}
                    manageroverviewstate={this.traineroverviewstate}
                    headCells={traineroverview ? headCellsTrainerOverview : headCellsTrainerInvited}
                    rows={trainerOverviewData}
                    ismanageroverview={traineroverview}
                    handleRemoveManagers={this.handleRemoveTrainers}
                    handleReinvite={this.handleReinvite}
                    seasonDropDownIsOpen={seasonDropdownIsOpen}
                    toggleItem={this.toggleItem}
                    seasons={seasons}
                    handleOnSelectSeasonFilterd={this.handleOnSelectSeason}
                    filteredSeason={filteredSeason}
                    t={t}
                />)}
            {this.AddTrainerModal(modal)}
            <ModalDelete modal={deleteModal} closeModal={this.closeModal} okRemove={this.okRemove} toggle={this.toggle} />
        </div>);
    }
}

export default connect((state) => {
    return {
        trainerOverviewData: state.trainer.trainers,
        addTrainerResult: state.trainer.addTrainerResult,
        allTeams: state.masterData.allTeams,
        profilePictureUpload: state.profilePicture.profilePictureUpload,
        seasons: state.masterData.seasons
    }
},
    dispatch => bindActionCreators({
        getTeams,
        addTrainer,
        getTrainers,
        removeTrainers,
        clearTrainers,
        uploadPicture,
        reInviteTrainer,
        getSeason
    }, dispatch))(withTranslation()(Trainers));