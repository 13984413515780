import { webAppUrl, tokenKey } from '../../constants';
import { toast } from 'react-toastify';

export default (userid) => async (dispatch) => {
    const url = `${webAppUrl}/api/user/AcceptPolicy`;

    var body = JSON.stringify({
        "userId": userid,
    });

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem(tokenKey)}`,
            'Content-Type': 'application/json'
        },
        body: body
    });

    if (response.ok) {
        toast.success("Accepted the terms and privacy", {
            position: toast.POSITION.TOP_RIGHT
        });
        window.location.href = `/login`;
    }
    else {
        setTimeout(() => {
            toast.error("Failed to accept the terms and privacy", {
                position: toast.POSITION.TOP_RIGHT
            });
            window.location.href = `/termandprivacy`;
        }, 1000);
    }
};