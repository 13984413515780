import '../bootstrap_css/style.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal, ModalFooter } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './TermsAndPrivacy.css';
import acceptTermsAndPrivacyAction from '../../../src/store/users/actions/acceptTermAndCondition';
import { userId } from '../../store/constants';

class TermsAndPrivacy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: true,
            isagree: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    toggle = (e) => {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    }

    closeModal() {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    }

    handleCheckboxChange(e) {
        const { isagree } = this.state;
        this.setState({
            isagree: !isagree
        });
    }

    handleSubmit() {
        const { modal, isagree } = this.state;
        if (!isagree) {
            toast.error(`Please accept the terms and privacy`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            modal: !modal
        });
        this.props.acceptTermsAndPrivacy(sessionStorage.getItem(userId));
    }

    ModalTermsAndConditions = (modal, isagree, termsAndPrivacy) => {
        const { t } = this.props;
        return (
            <Modal isOpen={modal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 800 }}
                backdrop="static"
                toggle={this.toggle} className="trainermodal center-modal">
                <div className="modal-body">
                    <h2>TERMS AND PRIVACY</h2>
                    <b />
                    <p className="p-text">
                        {termsAndPrivacy.description}
                    </p>
                </div>
                <ModalFooter>
                    <form className="form-termsandprivacy">
                        <div>
                            <input
                                type="checkbox"
                                id="isagree"
                                className="checkbox-round"
                                checked={isagree}
                                onChange={this.handleCheckboxChange}
                            />
                            <label for="isagree" className="accept-text">I have read and accept the <a href="#">{`Terms & Privacy V.${termsAndPrivacy.version}`}</a></label>
                        </div>
                        <input type="button" className="loginform_privacy" onClick={this.handleSubmit} value="Accept and Continue" />
                    </form>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const { modal, isagree } = this.state;
        const { termsAndPrivacy } = this.props;
        return (<div>
            {this.ModalTermsAndConditions(modal, isagree, termsAndPrivacy)}
        </div>);
    }
}

export default connect((state) => {
    return {}
},
    dispatch => bindActionCreators({
        acceptTermsAndPrivacy: acceptTermsAndPrivacyAction,
    }, dispatch))(withTranslation()(TermsAndPrivacy));