import { toast } from 'react-toastify';
import { addTrainer, authUrl } from '../../constants';
import fetchWithBearerToken from '../../SharedActions/fetchWithBearerToken';

export default (entity,t) => async (dispatch) => {
    
    const body = JSON.stringify({
        "email": entity.email,
        "role": entity.role,
        "phoneNumber": entity.phoneNumber,
        "firstName": entity.firstName,
        "lastName": entity.lastName,
        "profilePicture": entity.profilePicture,
        "teamId": entity.teamId,
        "photo": entity.photo
    });
    const result = await fetchWithBearerToken(`${authUrl}/Account/RegisterUser`, 'POST', body);

    if (result.ok) {
        var response = await result.json();
        if (!response.isSucceeded)
            toast.error(`${t('messagesfailed.addtrainer')} ${response.errorMessage}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        else
            dispatch({ type: addTrainer, result: new Date().toString() });
    }
    else {
        var response = await result.json();
        toast.error(`${t('messagesfailed.addtrainer')} ${response.errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}