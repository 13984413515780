import { uploadProfilePicture } from '../constants';

const initialState = {
    uploadProfilePicture : null
}
export default (state, action) => {
    state = state || initialState;

    if (action.type == uploadProfilePicture) {
        return {
            ...state, profilePictureUpload: { id: action.userProfilePicture.id, dateTimeTag: action.userProfilePicture.dateTimeTag} };
    }
    return state;
}