import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Fade } from 'reactstrap';
import { bindActionCreators } from 'redux';
import logOutAction from '../../store/users/actions/logoutAction';
import { loggedInUserSelector } from '../../store/users/selectors/authSelector';
import getHeaderDetail from '../../store/users/actions/getHeaderDetail';
import ProfileGreyIcon from './Images/ProfileGreyIcon.png';
import arrowGreyIcon from './Images/Sort_Grey.png';
import LogoutIcon from './Images/Logout_Dark.svg';
import './Header.css';
import ProfilePic from '../SharedComponents/ProfileThumImage';
import { Link } from 'react-router-dom';
import searchByKeyword from '../../store/Manager/actions/searchByKeyword';
import defaultteam from '../images/Team_logo.png';
import fetchClubDetails from '../../store/users/actions/fetchClubDetails';
import { withTranslation } from 'react-i18next';
import { FaCaretDown } from 'react-icons/fa';

class Header extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);

        this.state = {
            searchKeyword: '',
            isOpen: false,
            dropdownOpen: false,
            fadeIn: true
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleDropDown(e) {
        e.preventDefault();
        this.setState({
            dropDownIsOpen: !this.state.dropDownIsOpen
        });
    }

    keywordChange = (e) => {

        //if (e.target.value !== null && e.target.value !== '') {

        //    //this.props.toggleSearch(true);
        //}
        //else this.props.toggleSearch(false);
        this.props.toggleSearch(true);
        this.props.searchByKeyword(e.target.value).then(t => {
            const loading = document.querySelector('.loading');
            if (loading)
                loading.style.visibility = "hidden";
        });
        this.setState({
            searchKeyword: e.target.value
        });
    }

    componentDidMount() {
        this.props.getHeaderDetail();
        this.props.fetchClubDetails();
    }

    render() {
        //populate the user detail
        const { dropDownIsOpen } = this.state;
        const {
            userLoggedIn, headerDetail, keywordResult, toggleSearch, profilBoxStatus, t
        } = this.props;
        const defaultPicture = sessionStorage.getItem('clubImage');

        return (
            <div className="overview_top">
                <div className="dasboad_header_right fullright_sec" onClick={this.props.toogleProfileBoxOutside}>
                    <div className="dasboad_header_search">
                        <input onClick={() => { this.props.toggleSearch(false); }} onChange={this.keywordChange} type="text" className="searchbox"
                            placeholder={t('header.search')} />
                        <input type="button" onClick={() => { toggleSearch(true); this.props.searchByKeyword(this.state.searchKeyword); }} className="search_submit" />
                    </div>
                    <Fade in={this.props.searchIsOpen} style={{ position: "absolute", top: "9%" }}>
                        <div className={`searchbox-container ${this.props.searchIsOpen ? 'showresult' : ''}`} >
                            {keywordResult == null && <div className="searchbox-sub-container"> 0 result </div>}
                            {keywordResult && keywordResult.map((row) =>
                                <div onClick={() => { this.props.toggleSearch(false); window.location.reload(); }} className="searchbox-sub-container">
                                    <Link to={`${row.role == 'Manager' ? `/manager/detail-manager?userId=${row.userId}` :
                                        (row.role == 'Player' ? `/players/detail-player?userId=${row.userId}` :
                                            (row.role == 'Team' ? `/teams/team-detail?teamId=${row.userId}` :
                                                `/trainers/detail-trainer?userId=${row.userId}`))}`} style={{ display: "flex" }}>
                                        {row.role == 'Team' ? <img src={defaultPicture} className="player_image" /> :
                                            <ProfilePic imgUrl={row.userPicture} cls="player_image" size="40" />}
                                        {`${row.firstName} ${row.lastName}`}
                                        <div style={{ margin: "auto" }}></div>
                                        {`${row.role}`}
                                    </Link>
                                </div>)}
                        </div>
                    </Fade>
                </div>
                <div className="profile_box_holder header_profile_part" onClick={this.props.toogleProfileBoxOpen}>
                    <div className="profile_img">
                        <ProfilePic cls='img-header-thumb' imgUrl={headerDetail && headerDetail.profilePicture} />
                    </div>
                    <div className="profile_name">
                        <div className={headerDetail ? "name" : "name hide"}>{headerDetail ? `${headerDetail.firstName} ${headerDetail.lastName}` : 'fetching'}</div>
                        <div className="designation">Manager</div>
                    </div>
                    <div className="profile_icon">
                        <FaCaretDown fill="#545b62" className="dropdown-arrow-dark"></FaCaretDown>
                    </div>
                    <div className="profile_dropdown" style={{ display: profilBoxStatus ? "block" : "none" }}>
                        <ul>
                            <li>
                                <Link onClick={this.props.toogleProfileBoxOpen} to={'/manager/detail-manager?userId=' + `${headerDetail != null ? headerDetail.id : ''}`} className="detail-manager-link">
                                    <span className="pronav_icon">
                                        <img src={ProfileGreyIcon} alt="" />
                                    </span>{t('header.editprofile')}
                                </Link>
                            </li>
                            <li>
                                <a href="#"
                                    onClick={() => this.props.logOut()}
                                    className="logout-text">
                                    <span className="pronav_icon">
                                        <img src={LogoutIcon} alt="" />
                                    </span>{t('header.logout')}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            userLoggedIn: loggedInUserSelector(state),
            headerDetail: state.users.headerDetail,
            clubDetails: state.users.clubDetails,
            keywordResult: state.manager.keywordResult
        }
    },
    dispatch => bindActionCreators({
        logOut: logOutAction,
        getHeaderDetail,
        fetchClubDetails,
        searchByKeyword
    }, dispatch),
)(withTranslation()(Header));