import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import loginUserAction from '../../store/users/actions/loginAction';
import { loggedInUserSelector } from '../../store/users/selectors/authSelector';
import LoginLogo from './Images/logo.svg';
import LoginBanner from './Images/login_banner.jpg';
import './Login.css';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [passDisplayed, setPassDisplay] = useState(false);
    const [password, setPassword] = useState('');
    const { userLoggedIn, loginUser } = props;
    const handleEmailChange = e => {
        setEmail(e.target.value);
    }
    const handlePasswordChange = e => {
        setPassword(e.target.value);
    }
    const handleSubmit = () => {
        const entity = { email, password };
        const loading = document.querySelector('.loading');
        if (loading)
            loading.style.visibility = "visible";
        loginUser(entity);
    }
    const togglePass = () => {
        setPassDisplay(!passDisplayed);
    }
    const keyPressed = (event) => {
        if (event.key === "Enter") {
            handleSubmit()
        }
    }  
    const { t } = useTranslation();

    if (userLoggedIn.accessToken) {
        return <Redirect to='/' />;
    }

    return (
        <div className="login_container">
            <div className="login_left login_background_image">
                <img src={LoginLogo} />
            </div>
            <div className="login_right">
                <div className="login_box">
                    <h1>{t('login.welcome')}</h1>
                    <p>{t('login.fillInDetails')}</p>
                    <div className="loginform_holder">
                        <form autocomplete="new-password">
                            <div className="form_field">
                                <input type="text" placeholder={t('general.e-mail')} value={email} onChange={handleEmailChange} className="email_fld" />
                            </div>
                            <div className="form_field">
                                <input type={passDisplayed ? "text" : "password"} placeholder={t('general.password')} value={password} onChange={handlePasswordChange} onKeyPress={keyPressed} id="password-field" className="password_fld" autocomplete="new-password" />
                                <span onClick={togglePass}>
                                    <FontAwesomeIcon icon={passDisplayed ? faEyeSlash : faEye} className="custom-input-icon" /> </span>
                            </div>
                            <input type="button" onClick={handleSubmit} value={t('login.signin')} />
                        </form>
                        <div className="forget_password">
                            <Link to='/forgotpassword'>{t('login.forgotPassword')}</Link>
                        </div>
                        <div className="newpro">{t('login.questionnew')} <a href="https://proseason.nl/#contact">{t('login.joinnow')}</a></div>
                    </div>
                </div>
            </div>
            <div className="trademark_bottombar">&#169; 2021 Pro Season b.v. All Rights Reserved</div>
        </div>
    );
}

export default connect(
    (state) => {
        return {
            userLoggedIn: loggedInUserSelector(state),
        }
    },
    dispatch => bindActionCreators({
        loginUser: loginUserAction
    }, dispatch),
)(Login);
