import { tokenKey, updateLoggedInUser, userId,authUrl } from '../../constants';

export default () => async (dispatch) => {
    const bearerToken = sessionStorage.getItem(tokenKey);
    if (!bearerToken) {
        sessionStorage.removeItem(tokenKey);
        sessionStorage.removeItem(userId);
        return;
    }
    const url = `${authUrl}/Account/AuthenticateWithBearerToken?`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`
        },
    });

    if (response.status == 401) {
        sessionStorage.removeItem(tokenKey);
        sessionStorage.removeItem(userId);
    }
    else {
        const result = await response.json();
        if (!response.ok) {
            sessionStorage.removeItem(tokenKey);
            sessionStorage.removeItem(userId);
        }
    }

    
};