export const formatDateOverview = (date) => {
    if (date == null) return null;
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minute = d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    var newDate = [day, month, year].join('-');
    var newHour = [hour, minute].join(':');
    return [newDate, newHour].join(' ');
}

export const formatDateOverviewPicker = (date) => {
    if (date == null || date == "") return null;
    var d = new Date(date);

    return d;
}

export const timeSince = (date) => {
    if (date == null) return null;
    var mydate = new Date(date);
    var seconds = Math.floor((new Date() - mydate) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
}

export const formatDateStandart = (date) => {
    if (date == null) return null;
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;

    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}